<template>
  <component v-bind:is="pagedata"></component>
</template>

<script>
import Pagelogin from "./components/login/LoginPage.vue";
import Pageindex from "./components/index/IndexPage.vue";
import Pagekpuser   from './components/kpuser/KpuserCg.vue';


export default {
  name: "App",
  components: { Pageindex, Pagelogin ,Pagekpuser},
  data: function () {
    return {};
  },
  beforeCreate() {
    document.querySelector("html").setAttribute("style", "height: 100%;position:relative;")
    document.querySelector("body").setAttribute("style", "margin:0 auto; padding:0; text-align:center;width:100%;height:100%;position:relative;")
    document.querySelector("#app").setAttribute("style", "height: 100%;width:100%;text-align:left;")
  },
  computed: {
    islogin: function () {
      var url = window.location.href; //获取访问的url 用于判断访问来源，添加微信登录
      
      

      if(url.indexOf("code=") >= 0 && url.indexOf("state=KPC")>=0){
        return "Pagekpuser";
      }
      if (localStorage.getItem('Islogin')=='true')  {
        return "Pageindex";
      } else {
        return "Pagelogin";
      }
    },
    pagedata: function () {
      return this.islogin;
    },
  },
  methods:{

  },
};
</script>

<style scoped>
</style>
<style >
</style>

