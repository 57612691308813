<template>
  <el-select
    v-model="localcode"
    :filterable="true"
    :style="'width:' + width + ';'"
    collapse-tags
    reserve-keyword
    remote
    :remote-method="getdata"
    :multiple="ismultiple"
    :clearable="iscleanable"
    @change="datacg"
    default-first-option
    :disabled="isdisabled"
  >
    <el-option
      v-for="item in localdatas"
      :key="item.row_code"
      :label="item.v_name+'('+datetimeformart(item.begintime)+'至'+datetimeformart(item.endtime)+')['+item.remark+']'"
      :value="item.row_code"
      :disabled="item.disabled == 1"
    >
    </el-option>
  </el-select>
</template>
  <script>
import axios from "axios";

export default {
  emits: ["datachange"],
  props: {
    code: { required: true },
    status: { default: "1" },
    contaccodes: { default: [] },
    atype: { default: "" },
    btype: { default: "" },
    iscleanable: { default: false },
    ismultiple: { default: false },
    isdisabled: { default: false },
    width: { default: "320px" },
    placeholder: { default: "选择折扣" },
  },

  data() {
    return {
      localcode: this.code,
      localdatas: [],
      serchdata: {
        codename: "",
        status: this.status,
        code: this.code,
        contaccodes: this.contaccodes,
        atype: this.atype,
        btype: this.btype,
      },
    };
  },
  watch: {
    code: function () {
      if (this.code != this.localcode) {
        this.getdata("");
        this.localcode = this.code;
      }
    },
  },
  mounted() {},
  methods: {
    datetimeformart(Value) {
      console.log("datetimeformart", Value);
      if (Value == 0) {
        return "";
      }
      const date = new Date(parseInt(Value));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate())
      ];
      var rtdate = `${Y}-${M}-${D} `;
      return rtdate;
    },
    getdata(val) {
      this.serchdata.codename = val;
      clearTimeout(this._getgoods);
      this._getgoods = setTimeout(() => {
        axios
          .post("/getdicc/contactsdiscount", { data: this.serchdata })
          .then((response) => {
            this.localdatas = response;
          });
      }, 300);
    },
    datacg(val) {
      if (this.ismultiple) {
        let tpdata = Array();
        val.map((valmx) => {
          for (var i = 0; i < this.localdatas.length; i++) {
            if (this.localdatas[i].row_code == valmx) {
              tpdata.push(this.localdatas[i]);
              break;
            }
          }
        });
        this.$emit("datachange", tpdata);
        return;
      } else {
        for (var i = 0; i < this.localdatas.length; i++) {
          if (this.localdatas[i].row_code == val) {
            this.$emit("datachange", this.localdatas[i]);
            return;
          }
        }
      }
    },
  },
};
</script>
  <style scoped>
.el-select-dropdown__list {
  text-align: left;
}
</style>