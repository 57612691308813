//import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
//import installElementPlus from './plugins/element'

//重写日志记录，引入console插件后，所有log在生产环境不输出
//import './plugins/console'

const app = createApp(App)
import axios from "axios"
app.config.globalProperties.$axios = axios

import ElementPlus, { ElMessage, ElLoading } from "element-plus"
import "element-plus/lib/theme-chalk/index.css"




import locale from 'element-plus/lib/locale/lang/zh-cn'
import mitt from 'mitt'
const bus = {}
const emitter = mitt()
bus.$on = emitter.on
bus.$off = emitter.off
bus.$emit = emitter.emit
app.config.globalProperties.$bus = bus
axios.defaults.baseURL = '/api_v3/'   //设置后端接口基础地址


//配置页面宽度


//localStorage.clear()



//全局loading
//请求loading操作剩余数量，
let loadingremaining = 0;
//loading控件
let loadingInt;

//loading加载参数，每次loading+1，操作剩余数0时载入loading并+1
function showloading() {
    if (loadingremaining === 0) {
        loadingInt = ElLoading.service({
            lock: true,
            text: "加载中……",
            background: 'rgba(0, 0, 0, 0.4)'
        });
    }
    loadingremaining++;
}
function hideloading() {
    if (loadingremaining <= 0) {
        return;
    }
    loadingremaining--;
    if (loadingremaining === 0) {
        loadingInt.close();
    }
}

bus.$on('showloading', () => {
    showloading();


})

bus.$on('hideloading', () => {
    setTimeout(() => {
        hideloading();
    }, 800);

})



//axios封装返回处理
axios.interceptors.response.use(function (response) {
    if (response.config.url.indexOf('getdicc') < 0) {
        setTimeout(() => {
            hideloading();
        }, 1000);
    }


    console.log('respons data', response)

    if (response.data.rescode !== '0') {
        switch (response.data.rescode) {
            case '90001':
                //90001错误不做提示，统一由页面处理
                break
            default:
                ElMessage.error({ message: response.data.resmsg, duration: 2000, dangerouslyUseHTMLString: true, center: false })

                break
        }
        return Promise.reject(response.data)
    } else {
        return response.data.data
    }

}, function (error) {
    if (error.message != 'timeout of 500ms exceeded') {

        ElMessage.error({ message: '后台接口访问失败，请联系管理员！！！', duration: 2000 })
        console.log('arios error ', error);

        setTimeout(() => {
            hideloading();
        }, 1000);

        return Promise.reject(error)
    }
})


//axios封装请求处理
axios.interceptors.request.use(function (config) {
    if (config.url.indexOf('getdicc') < 0 && config.url.indexOf('updatechecktime') < 0) {
        showloading();
    }


    if (localStorage.getItem('Islogin') == 'true' && config.url.indexOf('uploadFile') < 0 && config.url.indexOf('getopuid') < 0) {
        config.data['token'] = localStorage.getItem('Token')
        config.data['userid'] = localStorage.getItem('UserId')
        config.data['username'] = localStorage.getItem('UserName')
        config.data['data']['userid'] = localStorage.getItem('UserId')
        config.data['data']['username'] = localStorage.getItem('UserName')
    }
    console.log('send data', config)

    return config


}, function (error) {

    return Promise.reject(error)
}
)

// app.prototype.postback=function(url,data,callback){
//     console.log('bbc',callback)
//     callback({url:url,data:data})
// }


//installElementPlus(app)
app
    // .use(postback)
    .use(ElementPlus, { locale: locale })
    // .use(postback())
    .mount('#app')