<template>
  <el-container
    ref="homePage"
    class="index-main-area"
    style="
      position: absolute;
      left: 50%;
      width: 1440px;
      margin-left: -720px;
      height: 100%;
    "
  >
    <el-header height="100px" style="text-align: left">
      <span style="font-size: 50px">上海六和勤强食品有限公司</span><br /><span
        style="float: right"
        >【{{ Usercode }}】{{ Username }}
        <el-button type="text" @click="changpassvisible = true"
          >修改密码</el-button
        >
        <el-button type="text" @click="logout()">注销</el-button>
      </span>
    </el-header>
    <el-container style="height: 100%; width: 100%">
      <el-aside
        width="200px"
        style="
          text-align: left;
          border-style: solid;
          border-width: 1px;
          height: 100%;
        "
      >
        <div
          style="text-align: left; width: 100%; height: 100%; overflow: auto"
        >
          <el-tree
            ref="mainmenulisttree"
            :props="{ label: 'title' }"
            :data="menulist"
            empty-text="无可用权限，请联系管理员添加！！"
            @node-click="menutreeclick"
            style="height: 100%"
          ></el-tree>
        </div>
      </el-aside>
      <el-main
        style="
          border-style: solid;
          border-width: 1px;
          height: 100%;
          width: 100%;
          padding: 5px;
        "
      >
        <el-tabs
          type="card"
          v-model="activeTab"
          @tab-remove="removeTab"
          @before-leave="changetabs"
          ref="maintabs"
          tab-position="top"
          style="height: 100%"
        >
          <el-tab-pane
            style="height: 100%"
            v-for="item in tabsItem"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            :closable="item.closable"
            :ref="item.ref"
          >
            <keep-alive>
              <component :is="item.content"></component>
            </keep-alive>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
    <HtmlFoot></HtmlFoot>
  </el-container>
  <el-dialog
    title="修改密码"
    v-model="changpassvisible"
    width="420px"
    center
    @close="cleandatas"
  >
    <span>
      <el-form
        :model="cpform"
        :rules="cpformrules"
        ref="cpformref"
        label-width="100px"
      >
        <el-form-item label="旧 密 码：" prop="passold">
          <el-input
            type="password"
            v-model="cpform.passold"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="新 密 码：" prop="pass1">
          <el-input type="password" v-model="cpform.pass1" autocomplete="off" />
        </el-form-item>

        <el-form-item label="重复密码：" prop="pass2">
          <el-input type="password" v-model="cpform.pass2" autocomplete="off" />
        </el-form-item>
      </el-form>
    </span>
    <template #footer>
      <el-button @click="changpassvisible = false">取消</el-button>
      <el-button type="primary" @click="changpass">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import WorkSpace from "./WorkSpace/WorkSpace";
import ModelConfig from "./sysconfig/modulemana/ModelConfig";
import ExtSys from "./sysconfig/extsys/ExtSys";
import SysDatafield from "./sysconfig/sysdatafield/SysDatafield";
import UserGroup from "./sysmanage/usergroup/UserGroup";
import UserManage from "./sysmanage/usermanage/UserManage";
import GoodsGroup from "./sales/business/goodsgroup/GoodsGroup";
import SalesContract from "./sales/business/salescontract/SalesContract";
import AccountingUnit from "./sysmanage/AccountingUnit/AccountingUnit";
import SalesAccounting from "./sales/business/salesaccounting/SalesAccounting";
import SalesReconciliation from "./sales/business/salesreconciliation/SalesReconciliation";
import SalesInvoice from "./sales/business/salesinvoice/SalesInvoice";

import HtmlFoot from "../../controls/HtmlFoot";

import { shallowRef } from "vue";

import axios from "axios";
export default {
  components: {
    HtmlFoot,
    WorkSpace,
    ModelConfig,
    ExtSys,
    SysDatafield,
    UserGroup,
    UserManage,
    GoodsGroup,
    SalesContract,
    AccountingUnit,
    SalesAccounting,
    SalesReconciliation,
    SalesInvoice,
  },
  data() {
    const passcheckr = "[^a-zA-Z0-9_!#@$%&()]+";
    const checkpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不可为空！！"));
      }
      if (value.match(passcheckr)) {
        callback(new Error("密码包含了非法字符！！"));
      }
      callback();
    };
    const checkpass2 = (rule, value, callback) => {
      if (value != this.cpform.pass1) {
        callback(new Error("两次输入的新密码不一致！！！"));
      }

      callback();
    };
    return {
      menulist: JSON.parse(localStorage.getItem("menulist")),
      Usercode: localStorage.getItem("UserCode"),
      Username: localStorage.getItem("UserName"),
      changpassvisible: false,
      cpform: {
        passold: "",
        pass1: "",
        pass2: "",
      },
      cpformrules: {
        passold: [{ required: true, validator: checkpass, trigger: "blur" }],
        pass1: [{ required: true, validator: checkpass, trigger: "blur" }],
        pass2: [{ required: true, validator: checkpass2, trigger: "blur" }],
      },
      activeTab: "WorkSpace",
      tabsItem: [
        {
          title: "首页",
          name: "WorkSpace",
          closable: false,
          ref: "tabs",
          content: shallowRef(WorkSpace),
        },
      ],
      clientHeight: "",
    };
  },
  methods: {
    changetabs() {
      console.log(this.activeTab);
    },
    removeTab(data) {
      let neact = "";
      if (this.activeTab == data) {
        this.tabsItem.map((val) => {
          if (val.name == data) {
            return;
          } else {
            neact = val.name;
          }
        });
      } else {
        neact = this.activeTab;
      }
      let tabs = this.tabsItem;
      this.tabsItem = tabs.filter((tab) => tab.name !== data);
      this.activeTab = neact;
    },
    menutreeclick(data) {
      console.log(data);
      const treenode = this.$refs.mainmenulisttree.store.nodesMap;

      if (data.type == "1") {
        let flag = true;
        this.tabsItem.map((val) => {
          if (val.name == data.controller) {
            this.activeTab = data.controller;
            flag = false;
            return;
          }
        });
        console.log(flag);
        if (flag) {
          this.tabsItem.push({
            title: data.title,
            name: data.controller,
            closable: true,
            ref: "tabs",
            content: shallowRef(data.controller),
          });
          this.activeTab = data.controller;
        }
      } else {
        let expandids = "|";
        for (let key in treenode) {
          if (key == data.$treeNodeId) {
            expandids += key + "|";
            let nowid = key;
            while (treenode[nowid].parent != null) {
              nowid = treenode[nowid].parent.id;
              expandids += nowid + "|";
            }
            console.log("nodedata", treenode[key]);
            break;
          }
        }
        console.log("needexpandids", expandids);
        for (let key in treenode) {
          if (expandids.indexOf("|" + key + "|") == -1) {
            treenode[key].expanded = false;
          }
        }
      }
    },
    logout() {
      this.$confirm("是否要注销用户并返回登录页面？", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "注销",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        location.reload();
      });
    },
    cleandatas() {
      this.cpform.passold = "";
      this.cpform.pass1 = "";
      this.cpform.pass2 = "";
    },
    changpass() {
      this.$bus.$emit("showloading");
      this.$refs.cpformref.validate((val) => {
        console.log("start changpass", val);
        if (val) {
          axios
            .post("login/cgpass", {
              data: {
                userid: localStorage.getItem("UserId"),
                ops: this.cpform.passold,
                nps: this.cpform.pass2,
              },
            })
            .then(() => {
              this.changpassvisible = false;
            });
        }
      });
      this.$bus.$emit("hideloading");
    },
    requehei() {
      this.$bus.$emit("heichange", { hei: this.clientHeight });
    },
    upoptim() {

      // case '10005':
      
      // ElMessageBox.alert(
      //   "登录状态已过期或用户已被踢出登录，即将跳转至登录页面！！！！！",
      //   "警告"
      // ).then(() => {
      //   localStorage.clear();
      //   location.reload();
      // });


      
    },
  },
  mounted() {
    this.clientHeight = `${document.documentElement.clientHeight}`;
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.clientHeight = window.fullHeight;
      })();
    };

    this.$bus.$on("requehei", this.requehei);

    this.$bus.$emit("heichange", { hei: this.clientHeight });
    console.log("indexhei", this.clientHeight);
  },
  watch: {
    clientHeight: function () {
      this.$bus.$emit("heichange", { hei: this.clientHeight });
    },
  },
};
</script>
<style scoped>
.el-tabs__header {
  margin: 0;
}
</style>
<style>
.el-select-dropdown__item {
  text-align: left;
}
</style>