<template>
  <DIV style="text-align: center; font-size: xx-large">操作员确认</DIV>
  <div style="width: 100%; height: 200px">{{ viewinfo }}</div>
  <br />
  <div style="text-align: center;">
   
  </div>
</template>
<script>
  import axios from "axios";
export default {
  data() {
    return {
      viewinfo: "操作员信息确认中……",
      disabledst: false,
      button_text: "等待中……",
    };
  },
  mounted() {
    var url = window.location.href;
    var state=url.substring(url.indexOf("state=") + 9);
    
    if (state.indexOf("&") >= 0) {
      state = state.substring(0, state.indexOf("&"));
    }

    var usercode=url.substring(url.indexOf("code=") + 5);
    if (usercode.indexOf("&") >= 0) {
      usercode = usercode.substring(0, usercode.indexOf("&"));
    }

    axios
        .post("/interface/sendusercgd", {
          data: state,
          code:usercode,
        })
        .then((response) => {
          this.viewinfo=response

          
        })
        .catch(() => {
          
        });


    


  },
  methods: {
    closeedwindow() {
      
    },
  },
};
</script>
<style scoped>
</style>