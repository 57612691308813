<template>
  <el-dialog
    :ref="'jasdhhkhehjkahsdkj'"
    :title="'发票申请'"
    v-model="visiable"
    width="1700px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span
      >购货客户选择：
      <InvobuyerSelectVue
        :code="thisdata.burercode"
        width="450"
        :clearable="true"
        @datachange="burercodecg"
      />&nbsp;&nbsp;购方名称：
      <el-input
        v-model="thisdata.burername"
        style="width: 300px"
        autocomplete="off"
        @blur="makeinvodata"
      />&nbsp;&nbsp;购方税号：
      <el-input
        v-model="thisdata.burertax"
        style="width: 250px"
        autocomplete="off"
        @blur="makeinvodata"
      />&nbsp;&nbsp;购方地址、电话：
      <el-input
        v-model="thisdata.bureradd"
        style="width: 480px"
        autocomplete="off"
        @blur="makeinvodata"
      /><br /><br />购方开户行及账号：
      <el-input
        v-model="thisdata.bureracc"
        style="width: 480px"
        autocomplete="off"
        @blur="makeinvodata"
      />

      &nbsp;&nbsp;开票抬头：
      <TaxplateSelectVue
        :code="thisdata.taxplate"
        width="500"
        @datachange="taxplatecg"
      />

      &nbsp;&nbsp;纳税代码：
      <TaxcodeSelectVue
        :code="thisdata.taxcode"
        width="450"
        @datachange="taxcodecg"
      />
      &nbsp;&nbsp;发票类型：
      <el-select
        v-model="thisdata.invotype"
        style="width: 90px"
        @change="makeinvodata"
      >
        <el-option
          v-for="item in invotypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option> </el-select
      >&nbsp;&nbsp;税率：
      <el-select
        v-model="thisdata.involv"
        style="width: 110px"
        @change="makeinvodata"
      >
        <el-option
          v-for="item in involvs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option> </el-select
      ><br /><br />电子邮件：
      <el-input
        v-model="thisdata.invoemail"
        style="width: 200px"
        autocomplete="off"
        @blur="makeinvodata"
      />&nbsp;&nbsp;发票备注：
      <el-input
        type="textarea"
        height="460"
        v-model="thisdata.remark"
        style="width: 350px"
        autocomplete="off"
        @blur="makeinvodata"
      />

      <el-tabs v-model="activeName">
        <el-tab-pane label="账单明细" name="first">
          <el-table
            :data="thisdata.bills"
            style="width: 100%; margin-top: 5px"
            height="450px"
            :header-cell-style="tableheadClass"
            @selection-change="billsselechange"
            stripe
            border
            default-expand-all
            :tree-props="{}"
            show-summary
            :summary-method="billtablesum"
            @cell-click="billstabClick"
          >
            <el-table-column type="selection" width="40" align="center" />

            <el-table-column prop="inv_goods_name" label="商品名称" width="340">
              <template #header>
                商品名称 &nbsp;&nbsp;
                <el-button @click="editgoodsvisiable = true" size="small"
                  >编辑品名</el-button
                >
              </template>
              <template #default="scope">
                {{ scope.row.inv_goods_name }}
              </template>
            </el-table-column>
            <el-table-column prop="inv_goods_spec" width="100"
              ><template #header>
                规格 &nbsp;&nbsp;
                <el-button @click="editgoodsscpvisiable = true" size="small"
                  >编辑规格</el-button
                >
              </template>
              <template #default="scope">
                {{ scope.row.inv_goods_spec }}
              </template></el-table-column
            >
            <el-table-column
              prop="sale_unit"
              label="单位"
              width="55"
              align="center"
            />
            <el-table-column
              label="数量"
              prop="sale_num"
              width="100"
              align="right"
            >
              <template #default="scope">
                <span
                  v-if="
                    cellediterow == scope.row.row_code &&
                    celleditecomm == 'sale_num'
                  "
                >
                  <el-input
                    v-model="editnum"
                    style="width: 200px"
                    autocomplete="off"
                    @blur="editnumdatas"
                    @focus="editnumdatasfo"
                  />
                </span>
                <span v-else>{{
                  (scope.row.sale_num / 1000000).toFixed(2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="单价" width="80" align="right">
              <template #default="scope">
                {{
                  ((scope.row.amount / scope.row.sale_num) * 10000).toFixed(2)
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="含税金额"
              prop="amount"
              width="120"
              align="right"
            >
              <template #default="scope">
                <span
                  v-if="
                    cellediterow == scope.row.row_code &&
                    celleditecomm == 'amount'
                  "
                >
                  <el-input
                    v-model="editnum"
                    style="width: 200px"
                    autocomplete="off"
                    @blur="editnumdatas"
                    @focus="editnumdatasfo"
                  />
                </span>
                <span v-else> {{ (scope.row.amount / 100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="折扣金额"
              prop="discount_amount"
              width="120"
              align="right"
            >
              <template #default="scope"
                ><span
                  v-if="
                    cellediterow == scope.row.row_code &&
                    celleditecomm == 'discount_amount'
                  "
                >
                  <el-input
                    v-model="editnum"
                    style="width: 200px"
                    autocomplete="off"
                    @blur="editnumdatas"
                    @focus="editnumdatasfo"
                  />
                </span>
                <span v-else>
                  {{ (scope.row.discount_amount / 100).toFixed(2) }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="开票金额" width="120" align="right">
              <template #default="scope">
                {{
                  (
                    (scope.row.amount - scope.row.discount_amount) /
                    100
                  ).toFixed(2)
                }}
              </template>
            </el-table-column>
            <el-table-column width="220" align="center">
              <template #header>
                <el-button @click="addbillvisiable = true" size="small"
                  >添加明细</el-button
                >
                <el-button @click="editdiconamountvisiable = true" size="small"
                  >设置票折</el-button
                ></template
              >
              <template #default="scope">
                <el-button
                  type="text"
                  @click="dellbill(scope.row)"
                  :disabled="
                    dialoginfo.modle == 'view' ||
                    thisdata.fromway == 1 ||
                    thisdata.status >= 50
                  "
                  size="small"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="发票明细" name="sec">
          <el-table
            :data="thisdata.invos"
            style="width: 100%; margin-top: 5px"
            height="450px"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column width="120" align="left">
              <!--  -->
              <template #header>开票抬头 </template>
              <template #default="scope">
                {{ scope.row.platename }}
              </template>
            </el-table-column>
            <el-table-column width="680" align="left">
              <template #default="scope">
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>地 址、电 话：</b>{{ scope.row.buyeraddress }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>开户行、账号：</b>{{ scope.row.buyeraccount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="inv_type" width="260" align="left">
              <template #default="scope">
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>接收邮箱：</b>{{ scope.row.email }} </span
                ><br />

                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-if="scope.row.inv_type == '000'"
                  ><b>发票类型：</b>待定</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-if="scope.row.inv_type == ''"
                  ><b>发票类型：</b>未选择</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '007'"
                  ><b>发票类型：</b>纸普</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '026'"
                  ><b>发票类型：</b>电普</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '004'"
                  ><b>发票类型：</b>纸专</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '028'"
                  ><b>发票类型：</b>电专</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else
                  ><b>发票类型：</b>数据错误</span
                >
                <br />
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-if="scope.row.taxlv === ''"
                  ><b>发票税率：</b></span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
                  ><b>发票税率：</b>免税</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else
                  ><b>发票税率：</b
                  >{{
                    (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="发票金额" width="100" align="right">
              <template #default="scope">
                {{ (scope.row.total_amount / 100).toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注" width="280" />

            <el-table-column width="80" align="center">
              <template #header> 操作</template>
              <template #default="scope">
                <el-button
                  type="text"
                  @click="viewinvos(scope.row)"
                  size="small"
                >
                  查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view'"
          @click="savedata"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="添加账单明细"
    v-model="addbillvisiable"
    width="1400px"
    :show-close="false"
    @close="clearaddbilldata"
    center
    ><span>
      客户名称/编码：

      <!-- <BuyerInformationSeltctVue
        :code="buyerinfor"
        width="400"
        @datachange="ad -->

      <el-input
        v-model="buyerinfor"
        style="width: 280px"
        autocomplete="off"
        clearable
      />&nbsp;
      物料名称：      
      <el-input
        v-model="goodsinfor"
        style="width: 180px"
        autocomplete="off"
        clearable
      />&nbsp;
      <el-button @click="addbilldataserch" size="small">查询</el-button>
      &nbsp;勾选金额:<el-input-number
            step-strictly
            v-model="getamountdd"
            :step="0.01"
          />
      &nbsp;
      <el-button @click="amountget" size="small">自动勾选</el-button>
      &nbsp;&nbsp;&nbsp;&nbsp; <br/>选中单据金额：&emsp;{{
        (selectbilltotalam / 100).toFixed(2)
      }}
      &nbsp;&nbsp; 选中单据票折金额：&emsp;
      {{ (selectbilltotaldam / 100).toFixed(2) }}
      &nbsp;&nbsp;选中单据开票金额：&emsp;{{
        (selectbilltotaledam / 100).toFixed(2)
      }}

      <br />

      <el-table
        :data="billssearchresults.rows"
        style="width: 100%; margin-top: 5px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        ref="addbillstables"
        @selection-change="addbilltableselechange"
      >
        <el-table-column type="selection" width="40" align="center" />

        <el-table-column
          prop="doc_date"
          label="购货日期"
          width="100"
          align="center"
          ><template #default="scope">{{
            dateformart(scope.row.doc_date)
          }}</template>
        </el-table-column>
        <el-table-column label="购货客户" width="240" align="left">
          <template #default="scope">
            {{ "[" + scope.row.buyervcode + "]" + scope.row.buyervname }}
          </template>
        </el-table-column>
        <el-table-column prop="v_name" label="商品名称" width="240" column-key="v_name" />
        <el-table-column prop="v_spec" label="商品规格" width="100" />
        <el-table-column
          prop="sale_unit"
          label="单位"
          width="55"
          align="center"
        />
        <el-table-column label="开印数量" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.sale_num / 1000000).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column label="单价" width="80" align="right">
          <template #default="scope">
            <span v-if="scope.row.sec_num == 0">0</span>
            <span v-else>
              {{
                ((scope.row.amount / scope.row.sale_num) * 10000).toFixed(2)
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="账单金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="票折金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.discount_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="开票金额" width="100" align="right">
          <template #default="scope">
            {{
              ((scope.row.amount - scope.row.discount_amount) / 100).toFixed(2)
            }}
          </template>
        </el-table-column>
      </el-table>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addbillvisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveaddbilldata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="编辑物料"
    v-model="editgoodsvisiable"
    width="550px"
    :show-close="false"
    @close="cleareditgoodsdata"
    center
    ><span>
      开票物料名称:
      <el-input
        v-model="editstring"
        style="width: 300px"
        autocomplete="off"
      /><br />留空为不修改
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="editgoodsvisiable = false"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="编辑物料规格"
    v-model="editgoodsscpvisiable"
    width="550px"
    :show-close="false"
    @close="cleareditgoodsscpdata"
    center
    ><span>
      开票物料规格:
      <el-input
        v-model="editstring"
        style="width: 300px"
        autocomplete="off"
      /><br />留空为不修改
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="editgoodsscpvisiable = false"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="调整票折"
    v-model="editdiconamountvisiable"
    width="550px"
    :show-close="false"
    center
    ><span>
      票折调整金额:
      <el-input
        v-model="editstring"
        style="width: 300px"
        autocomplete="off"
      /><br />填写的金额将按照现有折扣金额比例分摊，正数增大，负数减小，零或留空不变；
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="saveeditdicamount">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="发票查看xx"
    v-model="viewinvovisable"
    width="1200px"
    :show-close="false"
    center
  >
    <span>
      <span class="invoviewtool"><b>购货客户名称：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyername }}</span>

      <span class="invoviewtool"><b>开票抬头：</b></span>
      <span class="invoviewdata">{{ viewinvodata.platename }}</span>

      <span class="invoviewtool"><b>购货客户税号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyertaxnum }}</span>

      <span class="invoviewtool"><b>发票编码：</b></span>
      <span class="invoviewdata">{{ viewinvodata.tax_code }}</span>

      <span class="invoviewtool"><b>购货客户地址、电话：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraddress }}</span>

      <span class="invoviewtool"><b>发票号码：</b></span>
      <span class="invoviewdata">{{ viewinvodata.tax_num }}</span>

      <span class="invoviewtool"><b>购货客户开户行、账号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraccount }}</span>

      <span class="invoviewtool"><b>开票日期：</b></span>
      <span class="invoviewdata">{{ dateformart(viewinvodata.inv_data) }}</span>

      <span class="invoviewtool"><b>发票类型：</b></span>
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction == 1"
        >待定</span
      >
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction != 1"
        >红字待定</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction == 1
        "
        >纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction != 1
        "
        >红字纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction == 1
        "
        >电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction != 1
        "
        >红字电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction == 1
        "
        >纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction != 1
        "
        >红字纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction == 1
        "
        >电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction != 1
        "
        >红字电专</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>发票金额：</b></span>
      <span class="invoviewdata">{{
        finallnum(viewinvodata.total_amount / 100)
      }}</span>

      <span class="invoviewtool"><b>状态：</b></span>
      <span class="invoviewdata" v-if="viewinvodata.status == '0'">待确认</span>
      <span class="invoviewdata" v-else-if="viewinvodata.status == '1'"
        >待开票</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '4'"
        >待作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '7'"
        >待红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '10'"
        >出现错误</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '20'"
        >发送中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '23'"
        >专红申请</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '27'"
        >专红发送</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '30'"
        >开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '32'"
        >专红审核中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '34'"
        >专红开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '40'"
        >开票已完成</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '90'"
        >已作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '95'"
        >已红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '99'"
        >未开删除</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>备注：</b></span>
      <span class="invoviewdata">{{ viewinvodata.remark }}</span>
      <el-table
        :data="viewinvodata.dtl"
        style="width: 100%; margin-top: 12px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        show-summary
        :summary-method="invoviewtablesum"
      >
        <el-table-column label="商品名称" width="320" align="left">
          <template #default="scope">
            {{ scope.row.goodsname }}
          </template>
        </el-table-column>
        <el-table-column label="商品规格" width="120" align="center">
          <template #default="scope">
            {{ scope.row.goodspec }}
          </template>
        </el-table-column>
        <el-table-column label="单位" width="80" align="center">
          <template #default="scope">
            {{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="数量" width="120" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null
              "
              >{{ (parseInt(scope.row.num) / 1000000).toFixed(2) }}</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税单价" width="80" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null
              "
              >{{
                (
                  ((parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                    parseInt(scope.row.num)) *
                  10000
                ).toFixed(2)
              }}</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税金额" width="120" align="center">
          <template #default="scope">
            {{
              (
                (parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                100
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
        <el-table-column label="税率" width="80" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxlv === ''"></span>
            <span v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
              >免税</span
            >
            <span v-else>{{
              (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税额" width="120" align="center">
          <template #default="scope">
            {{ (scope.row.tax / 100).toFixed(2) }}
          </template>
        </el-table-column>
      </el-table> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="viewinvovisable = false">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import InvobuyerSelectVue from "@/controls/InvobuyerSelect.vue";
import TaxplateSelectVue from "@/controls/TaxplateSelect.vue";
import TaxcodeSelectVue from "@/controls/TaxcodeSelect.vue";
//import BuyerInformationSeltctVue from "@/controls/BuyerInformationSeltct.vue";
export default {
  emits: ["changedata", "dialogclose"],
  components: {
    InvobuyerSelectVue,
    TaxplateSelectVue,
    TaxcodeSelectVue,
    //BuyerInformationSeltctVue,
  },
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    return {
      getamountdd:0,
      addinvodtlrowgoods: [],
      addinvodtlrowbuyer: [],
      viewinvodata: [],
      viewinvovisable: false,
      editdiconamountvisiable: false,
      cellediterow: "",
      celleditecomm: "",
      editnum: 0,
      editgoodsscpvisiable: false,
      editstring: "",
      editgoodsvisiable: false,
      selectbillsdata: [],
      taxop: [],
      buyerinfor: "",
      goodsinfor:'',
      addbillvisiable: false,
      visiable: false,
      billssearchresults: { rows: [] },
      thisdata: {
        burercode: "",
        burername: "",
        burertax: "",
        bureradd: "",
        bureracc: "",
        taxplate: "",
        taxplatecode: "",
        taxcode: "",
        taxcodevalu: "",
        bills: [],
        invodtl: [],
        invotype: "",
        remark: "",
        invoemail: "",
        idnum: 0,
        involv: "",
      },
      addbillsearchdata: {
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
      },
      involvs: [
        { label: "免税", value: -1 },
        { label: "0.00%", value: 0 },
        { label: "9.00%", value: 90000 },
        { label: "13.00%", value: 130000 },
      ],
      invotypes: [
        { label: "纸普", value: "007" },
        { label: "电普", value: "026" },
        { label: "纸专", value: "004" },
        { label: "电专", value: "028" },
      ],
      activeName: "first",
      selectbilldata: [],
      selectbilltotalam: 0,
      selectbilltotaldam: 0,
      selectbilltotaledam: 0,
    };
  },
  mounted() {},
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata.opcode = JSON.parse(JSON.stringify(this.dialogdata));
        this.visiable = true;
      }
    },
  },
  methods: {
    addbillfiltergoods(val,row){
      if(val==row.v_name){return true}else{return false}
    },
    finallnum(val) {
      if (val == "" || val == null || val == 0) {
        return "0.00";
      }
      var num = val;
      num = num.toFixed(2);
      var fh = "";
      if (num.substring(0, 1) == "-") {
        fh = "-";
        num = num.substring(1);
      }
      var cutnum = 0;
      while (num.length > 6) {
        if (num.length % 3 != 0) {
          cutnum = num.length % 3;
          fh += num.substring(0, cutnum);
          fh += ",";
          num = num.substring(cutnum);
        } else {
          fh += num.substring(0, 3);
          fh += ",";
          num = num.substring(3);
        }
      }
      fh += num;
      return fh;
    },
    viewinvos(val) {
      console.log("viwe invos", val);
      this.viewinvodata = val;
      this.viewinvovisable = true;
    },
    dellbill(val) {
      for (var i = 0; this.thisdata.bills.length; i++) {
        if (this.thisdata.bills[i].row_code == val.row_code) {
          this.thisdata.bills.splice(i, 1);
          break;
        }
      }
      this.makeinvodata();
    },
    saveeditdicamount() {
      if (this.editstring == "" || this.editstring == "0") {
        this.editdiconamountvisiable = false;
        return;
      }
      var numReg = /^[-]{0,1}[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.editstring)) {
        ElMessage.error({
          message: "输入的数据不合法，请检查后重试！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }

      this.$bus.$emit("showloading");
      let totaldic = 0;
      let totalam = 0;
      this.thisdata.bills.map((val) => {
        totaldic += Number(val.discount_amount);
        totalam += Number(val.amount);
      });
      let discamount = Number(this.editstring) * 100;
      console.log("完成准备：", { totalf: totaldic, edf: discamount });
      if (totaldic + discamount < 0) {
        ElMessage.error({
          message: "调整后折扣为负数，请检查后重试！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.$bus.$emit("hideloading");
        return;
      }
      if (totalam - discamount < 0) {
        ElMessage.error({
          message: "调整后开票金额为负数，请检查后重试！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.$bus.$emit("hideloading");
        return;
      }
      let cpdicc = 0;
      this.thisdata.bills.map((val) => {
        cpdicc = Math.round(
          Number(val.discount_amount) * (discamount / totaldic)
        );
        if (val.discount_amount + cpdicc < 0) {
          cpdicc = -val.discount_amount;
        }
        console.log("处理明细", {
          disn: val.discount_amount,
          disnlv: discamount,
          totaldiclv: totaldic,
          cpdicc: cpdicc,
        });
        totaldic -= Number(val.discount_amount);
        val.discount_amount = Number(val.discount_amount) + cpdicc;
        discamount -= cpdicc;
      });

      this.makeinvodata();

      this.editdiconamountvisiable = false;
      this.$bus.$emit("hideloading");
    },
    editnumdatasfo(val) {
      val.currentTarget.select();
    },
    editnumdatas() {
      console.log("editnumdatas", this.editnum);

      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(this.editnum)) {
        for (var i = 0; i < this.thisdata.bills.length; i++) {
          if (this.thisdata.bills[i].row_code == this.cellediterow) {
            if (this.celleditecomm == "sale_num") {
              if (
                Number(this.thisdata.bills[i].sale_num) >
                Number(this.editnum) * 1000000
              ) {
                this.thisdata.bills[i].sale_num =
                  Number(this.editnum) * 1000000;
              }
            } else {
              if (
                Number(this.thisdata.bills[i][this.celleditecomm]) >
                Number(this.editnum) * 100
              ) {
                this.thisdata.bills[i][this.celleditecomm] =
                  Number(this.editnum) * 100;
              }
            }
          }
        }
      }
      this.makeinvodata();
    },
    billstabClick(row, column) {
      this.cellediterow = row.row_code;
      this.celleditecomm = column.property;
      console.log("billstabClick", column);
      let eddata = 0;
      if (column.property == "sale_num") {
        eddata = (Number(row[column.property]) / 1000000).toFixed(2);

        this.editnum = eddata;
        console.log("dsdsdwasdasd", this.editnum);
      }
      if (column.property == "discount_amount") {
        this.editnum = (row[column.property] / 100).toFixed(2);
      }
      if (column.property == "amount") {
        this.editnum = (row["amount"] / 100).toFixed(2);
      }
    },
    invoviewtablesum(data) {
      const sums = [];
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "商品名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.num);
          });
          value = (value / 1000000).toFixed(2);
        }
        if (element.label == "含税金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.notaxamount) + Number(row.tax);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "税额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.tax);
          });
          value = (value / 100).toFixed(2);
        }

        sums[index] = value;
      });

      return sums;
    },
    billtablesum(data) {
      const sums = [];
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "商品名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sale_num);
          });
          value = (value / 1000000).toFixed(2);
        }
        if (element.label == "含税金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.amount);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "折扣金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.discount_amount);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "开票金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.amount) - Number(row.discount_amount);
          });
          value = (value / 100).toFixed(2);
        }

        sums[index] = value;
      });

      return sums;
    },
    cleareditgoodsdata() {
      if (this.editstring != "") {
        this.selectbillsdata.map((val) => {
          val.inv_goods_name = this.editstring;
        });
        this.editstring = "";
        this.makeinvodata();
      }
    },

    cleareditgoodsscpdata() {
      if (this.editstring != "") {
        this.selectbillsdata.map((val) => {
          val.inv_goods_spec = this.editstring;
        });
        this.editstring = "";
      }
    },
    billsselechange(val) {
      this.selectbillsdata = val;
    },
    addfrees() {},
    tableheadClass() {
      return "text-align:center";
    },
    clearaddbilldata() {
      console.log("clearaddbilldata");
      this.billssearchresults.rows = [];
      this.selectbilldata = [];
      this.selectbilltotalam = 0;
      this.selectbilltotaldam = 0;
      this.selectbilltotaledam = 0;
      this.buyerinfor = "";
      this.goodsinfor=""
      this.$refs['addbillstables'].clearSelection()
    },
    saveaddbilldata() {
      let hasdata = false;
      this.selectbilldata.map((val) => {
        hasdata = false;
        val.inv_goods_name = val.v_name;
        val.inv_goods_spec = val.v_spec;
        //console.log("sdqw;kd;lkas;dlkasd",this.thisdata)
        for (var i = 0; i < this.thisdata.bills.length; i++) {
          if (this.thisdata.bills[i].row_code == val.row_code) {
            hasdata = true;
            break;
          }
        }
        if (!hasdata) {
          this.thisdata.bills.push(val);
        }
      });
      this.addbillvisiable = false;
      this.makeinvodata();
    },
    makeinvodata() {
      
      console.log("start bill data",this.thisdata.bills)
      
      if (
        this.thisdata.involv === "" ||
        this.thisdata.involv == null ||
        this.thisdata.taxplatecode == "" ||
        this.thisdata.taxplatecode == null
      ) {
        console.log("ttmakeinvo", {
          involv: this.thisdata.involv,
          taxplc: this.thisdata.taxplatecode,
        });
        return;
      }
      let taxlv = parseInt(this.thisdata.involv);
      let taxlvstss = this.thisdata.involv;
      if (taxlv == -1) {
        taxlv = 0;
        this.thisdata.involv = -1;
      }
      //合计物料明细
      let datalist = Array();
      let hasdata = false;
      this.thisdata.bills.map((val) => {
        hasdata = false;
        for (var i = 0; i < datalist.length; i++) {
          if (
            datalist[i]["inv_goods_name"] == val.inv_goods_name &&
            datalist[i]["sale_unit"] == val.sale_unit
          ) {
            hasdata = true;
            datalist[i]["main_num"] =
              parseInt(datalist[i]["main_num"]) + parseInt(val.main_num);
            datalist[i]["sale_num"] =
              parseInt(datalist[i]["sale_num"]) + parseInt(val.sale_num);
            datalist[i]["amount"] =
              parseInt(datalist[i]["amount"]) + parseInt(val.amount);
            datalist[i]["discount_amount"] =
              parseInt(datalist[i]["discount_amount"]) +
              parseInt(val.discount_amount);
            break;
          }
        }
        if (!hasdata) {
          datalist.push(JSON.parse(JSON.stringify(val)));
        }
      });
      //合并数据完成，生成明细
      //let involist=Array()
      console.log("end sum",this.thisdata.bills)
      console.log("开始处理发票数据");

      var maxamount = 9999999999999;
      if (this.thisdata.invotype == "004") {
        maxamount = parseInt(this.taxop.invoiceinfo_zp);
      }
      if (this.thisdata.invotype == "007") {
        maxamount = parseInt(this.taxop.invoiceinfo_pp);
      }
      if (this.thisdata.invotype == "026") {
        maxamount = parseInt(this.taxop.invoiceinfo_dp);
      }
      if (this.thisdata.invotype == "028") {
        maxamount = parseInt(this.taxop.invoiceinfo_dz);
      }
      maxamount *= 100;

      var nowtotalamount = 0;
      var tpdtl = Array();
      var nowinvorowcode = "";
      var goodslist = Array();
      var unitlist = Array();
      var dtldata = Array();
      var invohead = Array();
      var tpdtled;
      datalist.map((val) => {
        dtldata = JSON.parse(JSON.stringify(val));
        console.log("开始处理发票明细", dtldata);
        var nowselect = -1;
        var gi = 0;
        var nowdtldata = dtldata;
        var tpntam = 0;
        var tptaam = 0;
        var bs = 1;
        var wilest = true;
        var tptax = 0;
        var tjnum = 0;
        var tjamount = 0;
        var tjdisamount = 0;

        while (parseInt(nowdtldata.amount) != 0) {
          console.log("处理明细中", nowdtldata);

          if (
            nowtotalamount +
              parseInt(nowdtldata.amount) -
              parseInt(nowdtldata.discount_amount) >
            maxamount
          ) {
            //超过发票限额，拆分
            bs = 1;
            wilest = true;
            while (wilest) {
              bs *= 2;
              if (
                Math.round(
                  (parseInt(nowdtldata.amount) -
                    parseInt(nowdtldata.discount_amount)) /
                    bs
                ) <
                maxamount - nowtotalamount
              ) {
                break;
              }
              if (
                (parseInt(nowdtldata.amount) -
                  parseInt(nowdtldata.discount_amount)) /
                  bs <
                40000
              ) {
                bs = 0;
                break;
              }
            }
            if (bs > 0) {
              //拆分添加明细后，结束当前发票
              console.log("需要拆分出" + bs + "分之一", nowdtldata);
              tjnum = 0;
              tjamount = 0;
              tjdisamount = 0;
              tjnum = Math.floor(parseInt(nowdtldata.sale_num) / bs);
              tjamount = Math.floor(parseInt(nowdtldata.amount) / bs);
              tjdisamount = Math.floor(
                parseInt(nowdtldata.discount_amount) / bs
              );
              nowdtldata.sale_num -= tjnum;
              nowdtldata.amount -= tjamount;
              nowdtldata.discount_amount -= tjdisamount;

              nowselect = -1;
              for (gi = 0; gi < goodslist.length; gi++) {
                if (
                  goodslist[gi] == nowdtldata.inv_goods_name &&
                  unitlist[gi] == nowdtldata.sale_unit
                ) {
                  nowselect = gi;
                  break;
                }
              }
              if (nowselect < 0) {
                //物料未存在，需添加
                tpdtl.push({
                  sid: "",
                  row_code: "",
                  h_row_code: nowinvorowcode,
                  goodsname: nowdtldata.inv_goods_name,
                  unit: nowdtldata.sale_unit,
                  num: 0,
                  taxlv: taxlvstss,
                  notaxamount: 0,
                  tax: 0,
                  tax_code: this.thisdata.taxcodevalu,
                  goodspec: nowdtldata.inv_goods_spec,
                  disamount: 0,
                  distax: 0,
                });
                console.log("new goodsadd", nowdtldata);
                nowselect = tpdtl.length - 1;
              }
              tpdtl[nowselect].num += tjnum;
              tpntam = 0;
              tptaam = 0;
              tptax = taxlv / 1000000;
              tptaam = Math.round((tjamount / (1 + tptax)) * tptax);
              tpntam = tjamount - tptaam;
              console.log("拆分含税计算内容1", {
                "税率：": tptax,
                "含税金额：": tjamount,
                "未税金额：": tpntam,
                "税额:": tptaam,
                合计金额: tpntam + tptaam,
                "计算税额：": tpntam * tptax,
              });
              tpdtl[nowselect].notaxamount += tpntam;
              tpdtl[nowselect].tax += tptaam;
              nowtotalamount += tptaam + tpntam;
              if (parseInt(tjdisamount) != 0) {
                tpntam = 0;
                tptaam = 0;
                tptax = taxlv / 1000000;
                tptaam = Math.round((tjdisamount / (1 + tptax)) * tptax);
                tpntam = tjdisamount - tptaam;
                console.log("拆分含税计算内容2", {
                  "税率：": tptax,
                  "含税金额：": tjdisamount,
                  "未税金额：": tpntam,
                  "税额:": tptaam,
                  合计金额: tpntam + tptaam,
                  "计算税额：": tpntam * tptax,
                });

                tpdtl[nowselect].disamount += tpntam;
                tpdtl[nowselect].distax += tptaam;
                nowtotalamount -= tptaam + tpntam;
              }

              tpdtled = Array();

              tpdtl.map((valtpdddd) => {
                console.log("处理", valtpdddd);
                tpdtled.push({
                  sid: valtpdddd.sid,
                  row_code: valtpdddd.row_code,
                  h_row_code: valtpdddd.h_row_code,
                  goodsname: valtpdddd.goodsname,
                  unit: valtpdddd.unit,
                  num: valtpdddd.num,
                  taxlv: valtpdddd.taxlv,
                  notaxamount: valtpdddd.notaxamount,
                  tax: valtpdddd.tax,
                  tax_code: valtpdddd.tax_code,
                  goodspec: valtpdddd.inv_goods_spec,
                });
                if (valtpdddd.disamount != 0) {
                  tpdtled.push({
                    sid: valtpdddd.sid,
                    row_code: "",
                    h_row_code: valtpdddd.h_row_code,
                    goodsname: valtpdddd.goodsname,
                    unit: "",
                    num: 0,
                    taxlv: valtpdddd.taxlv,
                    notaxamount: -valtpdddd.disamount,
                    tax: -valtpdddd.distax,
                    tax_code: valtpdddd.tax_code,
                    goodspec: "",
                  });
                }

                console.log("处理1-end", tpdtled);
              });

              //alltotalamount += nowtotalamount;
              invohead.push({
                sid: "",
                row_code: nowinvorowcode,
                interface_code: "",
                buyername: this.thisdata.burername,
                buyertaxnum: this.thisdata.burertax,
                buyeraccount: this.thisdata.bureracc,
                buyeraddress: this.thisdata.bureradd,
                doc_date: 0,
                inv_data: 0,
                status: 0,
                spid: this.thisdata.taxplatecode,
                request: "",
                tax_code: "",
                tax_num: "",
                tax_info: "",
                email: this.thisdata.invoemail,
                url: "",
                taxlv: tpdtled[0].taxlv,
                old_codenum: "",
                red_infotable: "",
                red_radcode: "",
                total_amount: nowtotalamount,
                inv_type: this.thisdata.invotype,
                inv_direction: 1,
                old_inv_code: "",
                reconciliation_code: "",
                reconciliation_invo_code: "",
                platename: this.taxop.platename,
                dtl: tpdtled,
                remark: this.thisdata.remark,
              });
              console.log("处理1-xed", invohead);
              tpdtl = Array();
              nowtotalamount = 0;
              nowinvorowcode = "";
              goodslist = Array();
              unitlist = Array();
            } else {
              //结束当前发票，不拆分明细

              console.log(
                "不拆分，完结当前发票，当前明细另起一张发票",
                nowdtldata
              );
              tpdtled = Array();
              tpdtl.map((valtpdddd) => {
                console.log("处理2", valtpdddd);
                tpdtled.push({
                  sid: valtpdddd.sid,
                  row_code: valtpdddd.row_code,
                  h_row_code: valtpdddd.h_row_code,
                  goodsname: valtpdddd.goodsname,
                  unit: valtpdddd.unit,
                  num: valtpdddd.num,
                  taxlv: valtpdddd.taxlv,
                  notaxamount: valtpdddd.notaxamount,
                  tax: valtpdddd.tax,
                  tax_code: valtpdddd.tax_code,
                  goodspec: valtpdddd.goodspec,
                });
                if (valtpdddd.disamount != 0) {
                  tpdtled.push({
                    sid: valtpdddd.sid,
                    row_code: "",
                    h_row_code: valtpdddd.h_row_code,
                    goodsname: valtpdddd.goodsname,
                    unit: "",
                    num: 0,
                    taxlv: valtpdddd.taxlv,
                    notaxamount: -valtpdddd.disamount,
                    tax: -valtpdddd.distax,
                    tax_code: valtpdddd.tax_code,
                    goodspec: "",
                  });
                }

                console.log("处理2-end", tpdtled);
              });
              //alltotalamount += nowtotalamount;
              invohead.push({
                sid: "",
                row_code: nowinvorowcode,
                interface_code: "",
                buyername: this.thisdata.burername,
                buyertaxnum: this.thisdata.burertax,
                buyeraccount: this.thisdata.bureracc,
                buyeraddress: this.thisdata.bureradd,
                doc_date: 0,
                inv_data: 0,
                status: 0,
                spid: this.thisdata.taxplatecode,
                request: "",
                tax_code: "",
                tax_num: "",
                tax_info: "",
                email: this.thisdata.invoemail,
                url: "",
                taxlv: tpdtled[0].taxlv,
                old_codenum: "",
                red_infotable: "",
                red_radcode: "",
                total_amount: nowtotalamount,
                inv_type: this.thisdata.invotype,
                inv_direction: 1,
                old_inv_code: "",
                reconciliation_code: "",
                reconciliation_invo_code: "",
                platename: this.taxop.platename,
                dtl: tpdtled,
                remark: this.thisdata.remark,
              });
              console.log("处理2-xed", invohead);
              tpdtl = Array();
              nowtotalamount = 0;
              nowinvorowcode = "";
              goodslist = Array();
              unitlist = Array();
            }
          } else {
            console.log("明细加入当前发票", nowdtldata);
            //未超过发票限额，直接开票
            //检查是否存在相同物料
            nowselect = -1;
            for (gi = 0; gi < goodslist.length; gi++) {
              if (
                goodslist[gi] == nowdtldata.inv_goods_name &&
                unitlist[gi] == nowdtldata.sale_unit
              ) {
                nowselect = gi;
                break;
              }
            }
            if (nowselect < 0) {
              //物料未存在，需添加
              tpdtl.push({
                sid: "",
                row_code: "",
                h_row_code: nowinvorowcode,
                goodsname: nowdtldata.inv_goods_name,
                unit: nowdtldata.sale_unit,
                num: 0,
                taxlv: taxlvstss,
                notaxamount: 0,
                tax: 0,
                tax_code: this.thisdata.taxcodevalu,
                goodspec: nowdtldata.inv_goods_spec,
                disamount: 0,
                distax: 0,
              });
              console.log("new goodsadd", nowdtldata);
              nowselect = tpdtl.length - 1;
            }
            tpdtl[nowselect].num += parseInt(nowdtldata.sale_num);
            tpntam = 0;
            tptaam = 0;
            tptax = taxlv / 1000000;
            tptaam = Math.round(
              (parseInt(nowdtldata.amount) / (1 + tptax)) * tptax
            );
            tpntam = parseInt(nowdtldata.amount) - tptaam;

            console.log("拆分含税计算内容3", {
              "税率：": tptax,
              "含税金额：": parseInt(nowdtldata.amount),
              "未税金额：": tpntam,
              "税额:": tptaam,
              合计金额: tpntam + tptaam,
              "计算税额：": tpntam * tptax,
            });

            tpdtl[nowselect].notaxamount += parseInt(tpntam);
            tpdtl[nowselect].tax += parseInt(tptaam);
            nowtotalamount += parseInt(tptaam) + parseInt(tpntam);
            if (parseInt(nowdtldata.discount_amount) != 0) {
              tpntam = 0;
              tptaam = 0;
              tptax = taxlv / 1000000;
              tptaam = Math.round(
                (parseInt(nowdtldata.discount_amount) / (1 + tptax)) * tptax
              );
              tpntam = parseInt(nowdtldata.discount_amount) - parseInt(tptaam);
              console.log("拆分含税计算内容4", {
                "税率：": tptax,
                "含税金额：": parseInt(nowdtldata.discount_amount),
                "未税金额：": tpntam,
                "税额:": tptaam,
                合计金额: tpntam + tptaam,
                "计算税额：": tpntam * tptax,
              });

              tpdtl[nowselect].disamount += parseInt(tpntam);
              tpdtl[nowselect].distax += parseInt(tptaam);
              nowtotalamount -= parseInt(tptaam) + parseInt(tpntam);
            }
            nowdtldata.amount = 0;
          }
        }
      });

      if (nowtotalamount > 0) {
        tpdtled = Array();
        tpdtl.map((valtpdddd) => {
          console.log("处理3", valtpdddd);
          tpdtled.push({
            sid: valtpdddd.sid,
            row_code: valtpdddd.row_code,
            h_row_code: valtpdddd.h_row_code,
            goodsname: valtpdddd.goodsname,
            unit: valtpdddd.unit,
            num: valtpdddd.num,
            taxlv: valtpdddd.taxlv,
            notaxamount: valtpdddd.notaxamount,
            tax: valtpdddd.tax,
            tax_code: valtpdddd.tax_code,
            goodspec: valtpdddd.goodspec,
          });
          if (valtpdddd.disamount != 0) {
            tpdtled.push({
              sid: valtpdddd.sid,
              row_code: "",
              h_row_code: valtpdddd.h_row_code,
              goodsname: valtpdddd.goodsname,
              unit: "",
              num: 0,
              taxlv: valtpdddd.taxlv,
              notaxamount: -valtpdddd.disamount,
              tax: -valtpdddd.distax,
              tax_code: valtpdddd.tax_code,
              goodspec: "",
            });
          }

          console.log("处理3-end", tpdtled);
        });
        //alltotalamount += nowtotalamount;
        invohead.push({
          sid: "",
          row_code: nowinvorowcode,
          interface_code: "",
          buyername: this.thisdata.burername,
          buyertaxnum: this.thisdata.burertax,
          buyeraccount: this.thisdata.bureracc,
          buyeraddress: this.thisdata.bureradd,
          doc_date: 0,
          inv_data: 0,
          status: 0,
          spid: this.taxop.plateid,
          request: "",
          tax_code: "",
          tax_num: "",
          tax_info: "",
          email: this.thisdata.invoemail,
          url: "",
          taxlv: tpdtled[0].taxlv,
          old_codenum: "",
          red_infotable: "",
          red_radcode: "",
          total_amount: nowtotalamount,
          inv_type: this.thisdata.invotype,
          inv_direction: 1,
          old_inv_code: "",
          reconciliation_code: "",
          reconciliation_invo_code: "",
          platename: this.taxop.platename,
          dtl: tpdtled,
          remark: this.thisdata.remark,
        });

        console.log("处理3-xed", invohead);
      }
      console.log("发票信息", invohead);
      this.thisdata.invos = invohead;
    },
    amountget(){
      var isselct=false
      for(var i=0;i<this.billssearchresults.rows.length;i++){
        if(this.selectbilltotaledam>=this.getamountdd*100){
          break;
        }
        isselct=false
        for(var j=0;j<this.selectbilldata.length;j++){
          if(this.selectbilldata[j].row_code==this.billssearchresults.rows[i].row_code){
            isselct=true
            break
          }
        }
        if(!isselct){
          this.$refs.addbillstables.toggleRowSelection(this.billssearchresults.rows[i],true)
          //this.selectbilltotalam+=parseInt(this.billssearchresults.rows[i].amount)-parseInt(this.billssearchresults.rows[i].discount_amount)
        }

      }
    },
    addbilltableselechange(val) {
      this.selectbilldata = JSON.parse(JSON.stringify(val));
      this.selectbilltotalam = 0;
      this.selectbilltotaldam = 0;
      this.selectbilltotaledam = 0;
      this.selectbilldata.map((val) => {
        this.selectbilltotalam += parseInt(val.amount);
        this.selectbilltotaldam += parseInt(val.discount_amount);
      });
      this.selectbilltotaledam =
        this.selectbilltotalam - this.selectbilltotaldam;
    },
    dateformart(Value) {
      console.log("datetimeformart", Value);
      let time = Value;
      if (time == 0 || time == "") {
        return "";
      }
      const date = new Date(parseInt(time));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
      ];
      var rtdate = `${Y}-${M}-${D}`;
      return rtdate;
    },
    addbilldataserch() {
      this.$refs['addbillstables'].clearSelection()
      console.log("addbillsbuyerinforcg2", this.dialogdata);
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getbills", {
          data: {
            opcode: this.dialogdata,
            buyercode: this.buyerinfor,
            goodscode:this.goodsinfor,
            havrows: this.thisdata.bills,
          },
        })
        .then((response) => {
          console.log("addbillsbuyerinforcg3", response);
          this.billssearchresults.rows = response.rows;

          this.addinvodtlrowgoods = [];
          this.addinvodtlrowbuyer = [];


          


          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    taxcodecg(val) {
      console.log(val);
      this.thisdata.taxcodevalu = val.v_num;
      this.makeinvodata();
    },
    cleardata() {
      this.activeName = "first";
      this.visiable = false;
      this.thisdata = {
        burercode: "",
        burername: "",
        burertax: "",
        bureradd: "",
        bureracc: "",
        involv: "",
        bills: [],
        invodtl: [],
        invotype: "",
        remark: "",
        invoemail: "",
      };

      this.$emit("dialogclose", {});
    },
    burercodecg(val) {
      console.log(val);
      this.thisdata.burername = val.v_name;
      this.thisdata.burertax = val.taxnum;
      this.thisdata.bureradd = val.address;
      this.thisdata.bureracc = val.account;

      this.makeinvodata();
    },
    taxplatecg(val) {
      console.log(val);
      this.thisdata.taxplatecode = val.plateid;
      this.taxop = val;

      this.makeinvodata();
    },
    savedata() {
      console.log(this.thisdata);
      let errst = "";
      if (this.thisdata.invos.length == 0) {
        errst += "开票抬头、发票税率未填写或发票数据为空\n";
        // ElMessage.error({
        //   message: "开票抬头、发票税率均未填写！！",
        //   duration: 2000,
        //   dangerouslyUseHTMLString: true,
        //   center: false,
        // });
      }
      if (
        (this.thisdata.invotype == "007" || this.thisdata.invotype == "026") &&
        this.thisdata.burername == ""
      ) {
        errst += "发票类型为普票，但未填写购方名称\n ";
      }
      if (
        (this.thisdata.invotype == "004" || this.thisdata.invotype == "028") &&
        (this.thisdata.burername == "" ||
          this.thisdata.burertax == "" ||
          this.thisdata.bureradd == "" ||
          this.thisdata.bureracc == "")
      ) {
        errst += "发票类型为专票，但未填写完整购方信息\n ";
      }
      if (this.thisdata.invotype == "") {
        errst += "发票类型未选择\n ";
      }
      if (this.thisdata.taxcodevalu == "") {
        errst += "纳税编码未选择\n ";
      }
      if (errst != "") {
        errst = "数据检查发现如下错误，请检查调整后重试：" + errst;
        ElMessage.error({
          message: errst,
          duration: 5000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }

      ElMessageBox.confirm(
        "注意：确认后将生成待开发票，所勾选出库数据将被核销且不可撤销。是否确认生成发票？",
        "信息"
      ).then(() => {
        this.$bus.$emit("showloading");
        axios
          .post("/" + this.dialoginfo.modlecode + "/save", {
            data: {
              opcode: this.dialogdata,
              thisdata: this.thisdata,
            },
          })
          .then(() => {
            this.$emit("changedata", {});
            this.visiable = false;
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      });
    },
  },
};
</script>
<style  scoped>
:deep(.ModelDialogcascader) {
  width: 360px;
}

:deep(.el-select-dropdown__list) {
  text-align: left;
}

:deep(.invoviewdata) {
  width: 340px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
}

:deep(.myadditem .el-form-item__label) {
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

:deep(.el-scrollbar__view){
  text-align: left;
}
:deep(.el-table-filter__checkbox-group label.el-checkbox) {
  text-align: left;
}

:deep(.invoviewtool) {
  width: 180px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
  text-align: right;
}
</style>