<template>

    <el-tabs v-model="activeTab" tab-position="bottom" style="height: 100%">
        <el-tab-pane style="height: 100%; display: none;" key="search" name="SearchTab" label="查询条件">
            <SearchModul :areaheight="areaheight" :searchtypedatas="searchtypedatas" :searchdata="searchdata"
                @updsearch="updsearch"></SearchModul>
        </el-tab-pane>

        <el-tab-pane style="height: 100%" key="results" name="ResultsTab" label="查询结果">

            <SearchResult :areaheight="areaheight" :searchdata="searchdata" :selfmodul="selfmodul"></SearchResult>

        </el-tab-pane>
    </el-tabs>
</template>
<script >
import SearchResult from "./comp/SearchResult.vue"
import SearchModul from "../../../../../controls/SearchModul.vue"


export default {
    components: { SearchResult,  SearchModul},
    data() {
        return {
            selfmodul: { code: 'SalesInvoice', name: '发票申请' },
            activeTab: "ResultsTab",
            searchdata: {serachmap:{doctime:'doc_date',posttime:'inv_data'} ,doctime:['',''],posttime:['',''],invoinfo:'',invobuyer:'',},

            searchtypedatas: { status: [{value:0,label: '待确认'},{value:50,label: '已确认'},{value:99,label: '作废'}],billtype:[{value:1,lable:'销售账单'},{value:2,lable:'退货账单'}]},
            areaheight: 0,
        };
    },
    methods: {
        updsearch(data) {
            this.activeTab = 'ResultsTab'

            this.searchdata = JSON.parse(JSON.stringify(data))

        },
        resultsbutton(data) {
            console.log(data)
        },
        changehei(data) {
            this.areaheight = data.hei
            console.log('modelchei', this.areaheight)
        },


    },
    mounted() {
        this.$bus.$on('heichange', this.changehei)

        this.$bus.$emit('requehei')

    }
};
</script>
<style scoped>
</style>