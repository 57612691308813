<template>
   <el-footer height="30px" style="text-align: center">
      <span style="font-size: 8px"
        >Copyright © 2021 arios. All Rights Reserved.</span
      >
    </el-footer>
</template>
<script>

</script>
<style scoped>

</style>
