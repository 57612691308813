<template>
    <el-dialog :ref="dialoginfo.modlecode + 'dialog'" :title="dialoginfo.name" v-model="visiable" width="1000px"
        :show-close="false" @close="cleardata" center>
        <span>
            <el-form :ref="dialoginfo.modlecode + 'dialogform'" inline :model="thisdata" :rules="thisrules"
                label-width="100px">
                <el-form-item label="用户组名称" prop="v_name">
                    <el-input :disabled="dialoginfo.modle == 'view'" v-model="thisdata.v_name" style="width: 360px"
                        autocomplete="off" />
                </el-form-item>

                <el-form-item label="摘要" prop="remark">
                    <el-input :disabled="dialoginfo.modle == 'view'" v-model="thisdata.remark" style="width: 360px"
                        autocomplete="off" />
                </el-form-item>
            </el-form>
            <el-table :data="thisdata.dtl" style="width: 100%; margin-top: 5px" height="350px"
                :header-cell-style="tableheadClass" stripe row-key="row_code" border default-expand-all :tree-props="{}"
                :default-sort="{ prop: 'order', order: 'descending' }">
                <el-table-column prop="title" label="模块名称" width="260">
                </el-table-column>

                <el-table-column label="权限" width="670">

                </el-table-column>
            </el-table>


        </span>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="visiable = false">取 消</el-button>
                <el-button type="warning" @click="openocc">数据权限</el-button>
                <el-button type="primary" v-show="dialoginfo.modle != 'view'" @click="savedata">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script >
export default {
    emits: ["changedata", "dialogclose"],
    props: {
        dialoginfo: { required: true },
        dialogdata: { required: true },
        isvisiable: {},
    },
    data() {
        
        return {

        };

    },

}
</script>
<style scoped>
</style>