<template>
  <el-dialog
    :ref="dialoginfo.modlecode + 'dialog'"
    :title="dialoginfo.name + ''"
    v-model="visiable"
    width="1700px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span>
      <el-form
        :ref="dialoginfo.modlecode + 'dialogform'"
        inline
        :model="thisdata"
        :rules="thisrules"
      >
        <el-form-item label="合同归属" prop="v_name">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.v_name"
            style="width: 230px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="单据时间" prop="docdatetime">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.docdatetime"
            style="width: 170px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="审核时间" prop="postdatetime">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.postdatetime"
            style="width: 170px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="摘要" prop="remark">
          <el-input
            :disabled="dialoginfo.modle == 'view' || thisdata.status >= 50"
            v-model="thisdata.remark"
            style="width: 260px"
            autocomplete="off"
            @blur="upremark"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status_v">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.status_v"
            style="width: 120px"
            autocomplete="off"
          /> </el-form-item
        ><br />
        <!-- <el-form-item label="账单金额" prop="totalbill">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalbill"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="票折金额" prop="totalfree">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalfree"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="应开发票金额" prop="totalinvo">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalinvo"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="票面折扣" prop="totalinvolv">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalinvolv"
            style="width: 100px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="发票申请金额" prop="totalinvoapp">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalinvoapp"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="发票金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="thisdata.totalinvoamount"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item> -->

        <el-form-item label="账单金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.billsam"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="- 费用金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.freesam"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="- 票折金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.discountam"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="= 发票金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.needinvoam"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-divider direction="vertical" />
        <el-form-item label="票折比率" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.dislv"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-divider direction="vertical" />
        <el-form-item label="开票金额" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="ntotalnum.invoam"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>

        <br />
      </el-form>

      <el-tabs v-model="activeName">
        <el-tab-pane label="账单明细" name="first">
          <el-button
            @click="openaddbill"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
          >
            添加
          </el-button>
          <el-button
            @click="dellbills"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
          >
            批量删除
          </el-button>
          <!-- &nbsp; &nbsp; 差额调整：<el-input-number
            @change="taildiffcg"
            step-strictly
            :disabled="dialoginfo.modle == 'view' || thisdata.status >= 50"
            v-model="thisdata.taildiff"
            :step="0.01"
          />（尾差或直接扣减开票金额不计入票折的扣款） -->

          <el-table
            :data="thisdata.bills"
            style="width: 100%; margin-top: 5px"
            height="450px"
            :header-cell-style="tableheadClass"
            @selection-change="billsselechange"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column type="selection" width="40" align="center" />
            <el-table-column
              prop="single_num"
              label="单据编号"
              width="110"
              align="center"
            />
            <el-table-column
              prop="buyer_order_num"
              label="客户订单号"
              width="130"
            />
            <el-table-column prop="buyername" label="客户名称" width="200" />

            <el-table-column
              prop="post_date"
              label="过账日期"
              width="100"
              :formatter="dateformart"
              align="center"
            />
            <el-table-column prop="goodsname" label="商品名称" width="200" />
            <el-table-column label="开印数量" width="100" align="right">
              <template #default="scope">
                {{ (scope.row.sec_num / 1000000).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="sec_unit"
              label="单位"
              width="55"
              align="center"
            />
            <el-table-column label="单价" width="80" align="right">
              <template #default="scope">
                <span v-if="scope.row.sec_num == 0">0</span>
                <span v-else>
                  {{
                    (
                      (scope.row.sale_amount / scope.row.sec_num) *
                      10000
                    ).toFixed(2)
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="含税金额" width="120" align="right">
              <template #default="scope">
                {{ (scope.row.sale_amount / 100).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="核销金额" width="120" align="right">
              <template #default="scope">
                {{ (scope.row.reconciliation_amount / 100).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="税率" width="80" align="right">
              <template #default="scope">
                {{ (scope.row.tax_rait / 10000).toFixed(2) + "%" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="单据备注" width="200" />
            <el-table-column width="80" align="center">
              <template #header> </template>
              <template #default="scope">
                <el-button
                  type="text"
                  @click="dellbill(scope.row)"
                  :disabled="
                    dialoginfo.modle == 'view' ||
                    thisdata.fromway == 1 ||
                    thisdata.status >= 50
                  "
                  size="small"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="票折明细" name="second">
          <el-button
            @click="addbillfree"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
          >
            引入匹配费用</el-button
          >
          <el-button
            @click="addfreevisiable = true"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
            >选择费用明细</el-button
          >
          <el-button
            @click="addadfreevisiable = true"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
            >添加费用明细</el-button
          >
          <el-button
            @click="addbffreevisiable = true"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
            >添加折扣前费用明细</el-button
          >
          <el-button
            @click="dellfrees"
            :disabled="
              dialoginfo.modle == 'view' ||
              thisdata.fromway == 1 ||
              thisdata.status >= 50
            "
            size="small"
          >
            批量删除</el-button
          >
          <el-table
            :data="thisdata.frees"
            style="width: 100%; margin-top: 5px"
            height="450px"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
            @selection-change="freesselechange"
          >
            <el-table-column type="selection" width="40" align="center" />
            <el-table-column
              prop="single_num"
              label="单据编号"
              width="180"
              align="center"
            />
            <el-table-column
              prop="buyer_order_num"
              label="客户订单号"
              width="130"
            />
            <el-table-column prop="buyernae" label="客户名称" width="200" />

            <el-table-column label="折扣项目名称" width="200">
              <template #default="scope">
                <span v-if="scope.row.v_name == ''"></span>
                <span v-else>{{ scope.row.v_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="free_ftype" label="折扣种类" width="80">
              <template #default="scope">
                <span v-if="scope.row.free_ftype == 1">票面折扣</span>
                <span v-else-if="scope.row.free_ftype == 2">折前费用</span>
                <span v-else>数据错误</span>
              </template>
            </el-table-column>
            <el-table-column prop="free_dtltype" label="折扣类型" width="80">
              <template #default="scope">
                <span v-if="scope.row.free_dtltype == 1">比例折扣</span>
                <span v-else-if="scope.row.free_dtltype == 2">定额折扣</span>
                <span v-else>数据错误</span>
              </template>
            </el-table-column>

            <el-table-column label="折扣金额" width="120" align="right">
              <template #default="scope">
                {{ (scope.row.sale_amount / 100).toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column prop="discountremark" label="折扣摘要" width="300"
              ><template #default="scope">
                {{
                  "[" +
                  scope.row.billremark +
                  "](" +
                  scope.row.discountremark +
                  "){" +
                  scope.row.remark +
                  "}"
                }}
              </template>
            </el-table-column>
            <el-table-column width="80" align="center">
              <template #header> </template>
              <template #default="scope">
                <el-button
                  type="text"
                  @click="dellfree(scope.row)"
                  :disabled="
                    dialoginfo.modle == 'view' ||
                    thisdata.fromway == 1 ||
                    thisdata.status >= 50
                  "
                  size="small"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="开票清单" name="four">
          <el-button
            @click="addinvoappopen"
            :disabled="dialoginfo.modle == 'view' || thisdata.status >= 50"
            size="small"
          >
            参照明细生成</el-button
          >
          &nbsp;&nbsp;
          <el-upload
            v-model:file-list="fileList"
            class="upload-demo"
            method="post"
            style="display: inline-block"
            action="/api_v3/interface/reconinvofile"
            accept=".xlsx"
            :data="{ contactscode: thisdata.contacts_code }"
            :show-file-list="false"
            :on-success="uploadinvosuccess"
            :on-error="uploadinvoerror"
            :limit="1"
            ><el-button size="small">上传开票模版文件</el-button></el-upload
          >&nbsp;&nbsp;
          <el-button
            @click="pullinvovisable = true"
            :disabled="dialoginfo.modle == 'view' || thisdata.status >= 50"
            size="small"
            >引入发票
          </el-button>
          &nbsp;
          <el-button
            @click="cleaninvosdata"
            :disabled="dialoginfo.modle == 'view' || thisdata.status >= 50"
            size="small"
          >
            清空
          </el-button>
          <el-table
            :data="thisdata.invos"
            style="width: 100%; margin-top: 5px"
            height="450px"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column width="120" align="left">
              <!--  -->
              <template #header>开票抬头 </template>
              <template #default="scope">
                {{ scope.row.platename }}
              </template>
            </el-table-column>
            <el-table-column width="680" align="left">
              <template #default="scope">
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>地 址、电 话：</b>{{ scope.row.buyeraddress }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>开户行、账号：</b>{{ scope.row.buyeraccount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="inv_type" width="260" align="left">
              <template #default="scope">
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>发票日期：</b>
                  {{ dateformart2(scope.row.inv_data) }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>发票代码：</b> {{ scope.row.tax_code }}</span
                ><br />
                <span style="padding: 0px; margin: 0px; text-indent: -12em"
                  ><b>发票号码：</b> {{ scope.row.tax_num }}</span
                ><br />
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-if="scope.row.inv_type == '020'"
                  ><b>发票类型：</b>全电专</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '007'"
                  ><b>发票类型：</b>纸普</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '021'"
                  ><b>发票类型：</b>全电普</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '026'"
                  ><b>发票类型：</b>电普</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '004'"
                  ><b>发票类型：</b>纸专</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.inv_type == '028'"
                  ><b>发票类型：</b>电专</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else
                  ><b>发票类型：</b>数据错误</span
                >
                <br />
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-if="scope.row.taxlv === ''"
                  ><b>发票税率：</b></span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
                  ><b>发票税率：</b>免税</span
                >
                <span
                  style="padding: 0px; margin: 0px; text-indent: -12em"
                  v-else
                  ><b>发票税率：</b
                  >{{
                    (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="发票金额" width="100" align="right">
              <template #default="scope">
                {{ (scope.row.total_amount / 100).toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注" width="280" />
            <el-table-column label="发票状态" width="100" align="right">
              <template #default="scope">
                <span v-if="scope.row.status == '0'">待确认</span>
                <span v-else-if="scope.row.status == '1'">待开票</span>
                <span v-else-if="scope.row.status == '4'">待作废</span>
                <span v-else-if="scope.row.status == '7'">待红冲</span>
                <span v-else-if="scope.row.status == '10'">出现错误</span>
                <span v-else-if="scope.row.status == '20'">发送中</span>
                <span v-else-if="scope.row.status == '23'">专红申请</span>
                <span v-else-if="scope.row.status == '27'">专红发送</span>
                <span v-else-if="scope.row.status == '30'">开票中</span>
                <span v-else-if="scope.row.status == '32'">专红审核中</span>
                <span v-else-if="scope.row.status == '34'">专红开票中</span>
                <span v-else-if="scope.row.status == '40'">开票已完成</span>
                <span v-else-if="scope.row.status == '90'">已作废</span>
                <span v-else-if="scope.row.status == '95'">已红冲</span>
                <span v-else-if="scope.row.status == '99'">未开删除</span>
                <span v-else>数据错误</span>
              </template>
            </el-table-column>
            <el-table-column width="80" align="center">
              <template #header> 操作</template>
              <template #default="scope">
                <el-button
                  type="text"
                  @click="viewinvos(scope.row)"
                  size="small"
                >
                  查看</el-button
                ><br />
                <span
                  v-if="
                    scope.row.interface_code.length < 32 && thisdata.status == 0
                  "
                >
                  <el-button
                    type="text"
                    @click="dellinvos(scope.row)"
                    :disabled="
                      dialoginfo.modle == 'view' ||
                      scope.row.interface_code.length == 32
                    "
                    size="small"
                  >
                    删除</el-button
                  ><br />
                </span>
                <span v-if="scope.row.status == 40">
                  <el-button
                    type="text"
                    @click="disableinvos(scope.row)"
                    :disabled="
                      (scope.row.inv_type != '007' &&
                        scope.row.inv_type != '004') ||
                      getmounth(scope.row.inv_data) != getmounth('') ||
                      scope.row.status != 40 ||
                      scope.row.inv_direction != 1
                    "
                    size="small"
                    >作废</el-button
                  ><br />
                </span>
                <span v-if="scope.row.status == 40">
                  <el-button
                    type="text"
                    @click="redinvos(scope.row)"
                    :disabled="scope.row.status != 40"
                    size="small"
                  >
                    红冲</el-button
                  >
                </span>
                <span v-if="scope.row.status == 90 || scope.row.status == 95">
                  <el-button
                    type="text"
                    @click="renewinvos(scope.row)"
                    :disabled="scope.row.status != 40"
                    size="small"
                  >
                    重开</el-button
                  >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </span>

    <template #footer>
      <span class="dialog-footer"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button @click="visiable = false">取 消</el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view' && thisdata.status < 50"
          @click="checkdata"
          >确 认
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="添加账单明细"
    v-model="addbillvisiable"
    width="1650px"
    :show-close="false"
    @close="clearaddbilldata"
    center
    ><span>
      <el-form
        :model="addbillsearchdata"
        ref="searchform"
        :inline="true"
        label-width="130px"
      >
        <el-form-item label="单据日期：" prop="doctimes">
          <el-date-picker
            v-model="addbillsearchdata.doctimes"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="addbillsdataareachange_doctimes"
          />
        </el-form-item>
        <el-form-item label="审核日期：" prop="posttimes">
          <el-date-picker
            v-model="addbillsearchdata.posttimes"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="addbullsdataareachange_posttimes"
          />
        </el-form-item>
        <el-form-item label="单据/客户单号 ：" prop="docnum">
          <el-input
            v-model="addbillsearchdata.docnum"
            width="200"
            clearable
            class="searchinput"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="购货客户:" prop="buyerif">
          <el-input
            v-model="addbillsearchdata.buyerif"
            width="200"
            clearable
            class="searchinput"
            autocomplete="off"
          /> </el-form-item
        ><el-form-item label="商品名称:" prop="goodesinfor">
          <el-input
            v-model="addbillsearchdata.goodesinfor"
            width="200"
            clearable
            class="searchinput"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="仓库名称:" prop="warehouse">
          <WarehouseSelect
            :rowcode="addbillsearchdata.warehouse"
            width="200"
            :multiple="true"
            @datachange="addbillswarehousecg"
            :clearable="true"
          />
        </el-form-item>

        <el-form-item label="金额范围:" prop="">
          <el-input
            v-model="minamount"
            style="width: 120px"
            autocomplete="off"
            @change="minamcc"
          />-<el-input
            v-model="maxamount"
            style="width: 120px"
            autocomplete="off"
            @change="maxamcc"
          /> </el-form-item
        ><br />
        <el-form-item label="账单金额：" prop="totalinvoamount">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="addbilltotalamountstr"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-button @click="resetbillsearch" size="small">重 置</el-button>
        <el-button @click="addbillsseacrchstart" size="small">查 询</el-button>
        <el-button @click="addbillsviewsele" size="small">选中项</el-button>
        &nbsp;&nbsp;<el-upload
          v-model:file-list="fileList"
          class="upload-demo"
          method="post"
          style="display: inline-block"
          action="/api_v3/interface/uploadreconFile"
          accept=".xlsx"
          :data="{ contactscode: thisdata.contacts_code }"
          :show-file-list="false"
          :on-success="uploadsuccess"
          :on-error="uploaderror"
          :limit="1"
          ><el-button size="small">上传对账文件</el-button></el-upload
        ><el-divider direction="vertical" /> 自动勾选金额：<el-input
          v-model="autoselectbills"
          style="width: 140px"
          autocomplete="off"
        />
        &nbsp;&nbsp;
        <el-button @click="autoselectnillbt" size="small">开始勾选</el-button>
      </el-form>
      <el-table
        :data="billssearchresults.rows"
        style="width: 100%; margin-top: 5px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="bill_dtl_code"
        border
        default-expand-all
        :tree-props="{}"
        ref="addbillstables"
        @selection-change="addbilltableselechange"
        @cell-click="billsadddetabClick"
      >
        <el-table-column type="selection" width="40" align="center" />
        <el-table-column
          prop="single_num"
          label="单据编号"
          width="110"
          align="center"
        />
        <el-table-column
          prop="buyer_order_num"
          label="客户订单号"
          width="110"
        />
        <el-table-column prop="buyername" label="客户名称" width="180" />

        <el-table-column
          prop="post_date"
          label="过账日期"
          width="110"
          :formatter="dateformart"
          align="center"
        />
        <el-table-column prop="goodsname" label="商品名称" width="180" />
        <el-table-column label="开印数量" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.sec_num / 1000000).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sec_unit"
          label="单位"
          width="55"
          align="center"
        />
        <el-table-column label="单价" width="80" align="right">
          <template #default="scope">
            <span v-if="scope.row.sec_num == 0">0</span>
            <span v-else>
              {{
                ((scope.row.sale_amount / scope.row.sec_num) * 10000).toFixed(2)
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="含税金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.sale_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="待销金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.main_remain_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="amountedit"
          label="核销金额"
          width="100"
          align="right"
        >
          <template #default="scope">
            <span
              v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'amountedit'
              "
            >
              {{ (scope.row.main_remain_amount_edit / 100).toFixed(2) }}</span
            ><span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 90px"
                @change="numbercgxx2"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="税率" width="70" align="right">
          <template #default="scope">
            {{ (scope.row.tax_rait / 10000).toFixed(2) + "%" }}
          </template>
        </el-table-column>
        <el-table-column label="票折" width="70" align="right">
          <template #default="scope">
            <span v-if="scope.row.sale_amount != 0">{{
              ((scope.row.free_amount / scope.row.sale_amount) * 100).toFixed(
                2
              ) + "%"
            }}</span>
            <span v-else>0.00%</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="单据备注" width="180" />
      </el-table>
      <el-pagination
        background
        @size-change="addbillshandleSizeChange"
        @current-change="addbillshandleCurrentChange"
        :current-page="addbillspagenow"
        :page-sizes="[50, 100, 300, 500, 1000]"
        :page-size="addbillspagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addbillstotalrow"
      >
      </el-pagination>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addbillvisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveaddbilldata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="添加票折明细"
    v-model="addfreevisiable"
    width="1550px"
    :show-close="false"
    @close="clearaddfreedata"
    center
    ><span>
      <el-form :model="addfreesearchdata" :inline="true" label-width="130px">
        <el-form-item label="单据日期：" prop="doctimes">
          <el-date-picker
            v-model="addfreesearchdata.doctimes"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="addfreesdataareachange_doctimes"
          />
        </el-form-item>
        <el-form-item label="审核日期：" prop="posttimes">
          <el-date-picker
            v-model="addfreesearchdata.posttimes"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="addfreesdataareachange_posttimes"
          />
        </el-form-item>
        <el-form-item label="单据/客户单号 ：" prop="docnum">
          <el-input
            v-model="addfreesearchdata.docnum"
            width="200"
            clearable
            class="searchinput"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="部门:" prop="department">
          <DepartmentCascader
            :thisdata="addfreesearchdata.department"
            width="200"
            :clearable="true"
            @datachange="addfreesdepartmentcg"
            :multiple="true"
            :isview="true"
          />
        </el-form-item>
        <el-form-item label="购货客户:" prop="buyerinfor">
          <BuyerInformationSeltct
            :code="addfreesearchdata.buyerinfor"
            width="200"
            :ismultiple="true"
            @datachange="addfreesbuyerinforcg"
            :iscleanable="true"
          />
        </el-form-item>
        <el-form-item label="仓库名称:" prop="warehouse">
          <WarehouseSelect
            :rowcode="addfreesearchdata.warehouse"
            width="200"
            :multiple="true"
            @datachange="addfreeswarehousecg"
            :clearable="true"
          />
        </el-form-item>
        <el-form-item label="账单金额：">
          <el-input
            :disabled="dialoginfo.modle == 'view' || true"
            v-model="addfreetotalamountstr"
            style="width: 140px"
            autocomplete="off"
          />
        </el-form-item>
        <el-button @click="resetfreesearch" size="small">重 置</el-button>
        <el-button @click="addfreesseacrchstart" size="small">查 询</el-button>
        <el-button @click="addfreesviewsele" size="small">选中项</el-button>
      </el-form>
      <el-table
        :data="freessearchresults"
        style="width: 100%; margin-top: 5px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="bill_dtl_code"
        border
        default-expand-all
        :tree-props="{}"
        ref="addfreestables"
        @selection-change="addfreetableselechange"
      >
        <el-table-column type="selection" width="40" align="center" />
        <el-table-column
          prop="single_num"
          label="单据编号"
          width="180"
          align="center"
        />
        <el-table-column
          prop="buyer_order_num"
          label="客户订单号"
          width="130"
        />
        <el-table-column prop="buyernae" label="客户名称" width="200" />

        <el-table-column label="折扣项目名称" width="200">
          <template #default="scope">
            <span v-if="scope.row.v_name == ''"></span>
            <span v-else>{{ scope.row.v_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dtltype" label="折扣类型" width="80">
          <template #default="scope">
            <span v-if="scope.row.dtltype == 1">比例折扣</span>
            <span v-else-if="scope.row.dtltype == 2">定额折扣</span>
            <span v-else>数据错误</span>
          </template>
        </el-table-column>

        <el-table-column label="折扣金额" width="120" align="right">
          <template #default="scope">
            {{ (scope.row.sale_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="discountremark" label="折扣摘要" width="300" />
      </el-table>
      <el-pagination
        background
        @size-change="addfreeshandleSizeChange"
        @current-change="addfreeshandleCurrentChange"
        :current-page="addfreespagenow"
        :page-sizes="[50, 100, 300, 500, 1000]"
        :page-size="addfreespagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addfreestotalrow"
      >
      </el-pagination>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addfreevisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveaddfreedata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="添加票折明细"
    v-model="addadfreevisiable"
    width="600px"
    :show-close="false"
    @close="clearaddadfreedata"
    center
    ><span>
      <el-form :model="addadfreesdata" label-width="130px">
        <el-form-item label="选择折扣项目:" prop="discount">
          <ContactsdiscountSelect
            :code="addadfreesdata.discount"
            width="450"
            :clearable="true"
            @datachange="addadfreesdatadiscountcg"
            :isview="true"
            :contaccodes="[thisdata.contacts_code]"
            :atype="1"
            :btype="2"
          />
        </el-form-item>
        <el-form-item label="折扣项目名称：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.v_name"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣项生效时间：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.time"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣项摘要：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.remark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣金额：">
          <el-input
            type="number"
            v-model="addadfreesdata.number"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣备注：">
          <el-input
            v-model="addadfreesdata.atremark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="addadfreevisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveaddadfreedata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="添加折扣前费用明细"
    v-model="addbffreevisiable"
    width="600px"
    :show-close="false"
    @close="clearaddadfreedata"
    center
    ><span>
      <el-form :model="addadfreesdata" label-width="130px">
        <el-form-item label="选择折扣项目:" prop="discount">
          <ContactsdiscountSelect
            :code="addadfreesdata.discount"
            width="450"
            :clearable="true"
            @datachange="addadfreesdatadiscountcg"
            :isview="true"
            :contaccodes="[thisdata.contacts_code]"
            :atype="2"
            :btype="2"
          />
        </el-form-item>
        <el-form-item label="折扣项目名称：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.v_name"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣项生效时间：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.time"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣项摘要：">
          <el-input
            :disabled="true"
            v-model="addadfreesdata.remark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣金额：">
          <el-input
            type="number"
            v-model="addadfreesdata.number"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="折扣备注：">
          <el-input
            v-model="addadfreesdata.atremark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="addbffreevisiable = false">取 消</el-button>
        <el-button type="primary" @click="savebfdadfreedata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="参照新建"
    v-model="addinvoappvisiable"
    width="1550px"
    :show-close="false"
    center
    ><span>
      <el-table
        :data="invoappsedit.head"
        style="width: 100%; margin-top: 5px"
        height="250px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        @selection-change="addinvoapptableselechange"
        @cell-click="invoapptabClick"
      >
        <el-table-column type="selection" width="40" align="center" />
        <el-table-column width="190" align="left">
          <!--  -->
          <template #header
            >开票抬头
            <el-button @click="editinvoinfovisiable = true" size="small"
              >编辑</el-button
            >
          </template>
          <template #default="scope">
            {{ scope.row.pt_inv_name }}
          </template>
        </el-table-column>
        <el-table-column width="280" align="left">
          <template #header
            >购货客户抬头
            <el-button @click="editinvoinfovisiable = true" size="small"
              >编辑</el-button
            >
          </template>
          <template #default="scope">
            {{
              "名称：" +
              scope.row.buyer_inv_name +
              "\n 税号：" +
              scope.row.buyer_inv_taxnum
            }}
          </template>
        </el-table-column>
        <el-table-column prop="invotype" width="90" align="center">
          <template #header>发票类型 </template>
          <template #default="scope">
            <span
              v-if="
                scope.row.invotype == '000' &&
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'invotype')
              "
              >待定</span
            >
            <span
              v-else-if="
                scope.row.invotype == '007' &&
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'invotype')
              "
              >纸普</span
            >
            <span
              v-else-if="
                scope.row.invotype == '021' &&
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'invotype')
              "
              >全电普</span
            >
            <span
              v-else-if="
                scope.row.invotype == '004' &&
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'invotype')
              "
              >纸专</span
            >
            <span
              v-else-if="
                scope.row.invotype == '020' &&
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'invotype')
              "
              >全电专</span
            >
            <span
              v-else-if="
                cellediterow == scope.row.row_code &&
                celleditecomm == 'invotype'
              "
            >
              <el-select v-model="invotype2" @change="invotypecg2" size="small">
                <el-option
                  v-for="item in invotypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
            <span v-else>数据错误</span>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="120" align="left" />
        <el-table-column
          prop="taxcode"
          label="税收编码"
          width="120"
          align="center"
        >
          <template #default="scope">
            <span
              v-if="
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'taxcode') &&
                scope.row.taxcodetext != '' &&
                scope.row.taxcodetext != null
              "
            >
              {{ scope.row.taxcodetext }}
            </span>
            <span
              v-else-if="
                (cellediterow != scope.row.row_code ||
                  celleditecomm != 'taxcode') &&
                (scope.row.taxcodetext == '' || scope.row.taxcodetext == null)
              "
            >
              请选择物料税收编码
            </span>
            <span v-else>
              <TaxcodeSelect
                :code="edtaxcode"
                width="100px"
                @datachange="edtaxcodecg2"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="160" align="left" />

        <el-table-column prop="taxlv" width="70" align="right">
          <template #header>税率 </template>
          <template #default="scope">
            <span
              v-if="
                scope.row.taxlv === '' &&
                (cellediterow != scope.row.row_code || celleditecomm != 'taxlv')
              "
            ></span>
            <span
              v-else-if="
                scope.row.taxlv == -1 &&
                (cellediterow != scope.row.row_code || celleditecomm != 'taxlv')
              "
              >免税</span
            ><span
              v-else-if="
                cellediterow == scope.row.row_code && celleditecomm == 'taxlv'
              "
            >
              <el-select v-model="involv" @change="involvtypecg1" size="small">
                <el-option
                  v-for="item in involvs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
            <span v-else>{{ (scope.row.taxlv / 10000).toFixed(2) + "%" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="账单金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.totalamount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="totalfree"
          label="费用金额"
          width="100"
          align="right"
        >
          <template #default="scope">
            <span
              v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'totalfree'
              "
              >{{ (scope.row.totalfree / 100).toFixed(2) }}
            </span>
            <span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 90px"
                @change="numbercgxx"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="totaldis"
          label="折扣金额"
          width="100"
          align="right"
        >
          <template #default="scope">
            <span
              v-if="
                cellediterow != scope.row.row_code ||
                celleditecomm != 'totaldis'
              "
              >{{ (scope.row.totaldis / 100).toFixed(2) }}
            </span>
            <span v-else>
              <el-input
                v-model="editetotalfree"
                style="width: 90px"
                @change="numbercgxx3"
              />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="票面净额" width="100" align="right">
          <template #default="scope">
            {{
              (
                (scope.row.totalamount -
                  scope.row.totalfree -
                  scope.row.totaldis) /
                100
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
      </el-table>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 编号/订单号：
      <el-input v-model="appserchdata.num" style="width: 190px" />
      &nbsp;客户：
      <el-input v-model="appserchdata.buyername" style="width: 190px" />
      &nbsp;物料：
      <el-input v-model="appserchdata.goods" style="width: 190px" />&nbsp;
      <el-button @click="resetattcode" size="small">重置</el-button>
      <el-button @click="attcodeserch" size="small">查询</el-button>
      <el-button @click="onetotwo" size="small">重组</el-button>
      <el-button @click="editgoodsvisiable = true" size="small"
        >开票物料设置</el-button
      >

      &nbsp;&nbsp;&nbsp; 费用差异：{{
        (this.frecc / 100).toFixed(2)
      }}&nbsp;&nbsp;&nbsp; 折扣差异：{{ (this.discc / 100).toFixed(2) }}
      <el-table
        :data="invoappseditviewdtl"
        style="width: 100%; margin-top: 5px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        @selection-change="addinvoappdtltableselechange"
      >
        <el-table-column type="selection" width="40" align="center" />
        <el-table-column
          prop="single_num"
          label="单据编号"
          width="180"
          align="center"
        />
        <el-table-column
          prop="buyer_order_num"
          label="客户订单号"
          width="130"
        />
        <el-table-column prop="buyername" label="客户名称" width="200" />

        <el-table-column
          prop="post_date"
          label="过账日期"
          width="100"
          :formatter="dateformart"
          align="center"
        />
        <el-table-column prop="goodsname" label="系统商品名称" width="200" />
        <el-table-column
          prop="ivocegoodsname"
          label="开票商品名称"
          width="200"
        />
        <el-table-column prop="ivocev_spec" label="规格" width="80" />
        <el-table-column label="开印数量" width="120" align="right">
          <template #default="scope">
            {{ (scope.row.sec_num / 1000000).toFixed(2) + scope.row.sec_unit }}
          </template>
        </el-table-column>
        <el-table-column label="开票金额" width="120" align="right">
          <template #default="scope">
            {{ (scope.row.reconciliation_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="税率" width="80" align="right">
          <template #default="scope">
            <span v-if="scope.row.tax_rait === ''"></span>
            <span
              v-else-if="scope.row.tax_rait == -1 || scope.row.tax_rait == '-1'"
              >免税</span
            >
            <span v-else>{{
              (parseInt(scope.row.tax_rait) / 10000).toFixed(2) + "%"
            }}</span>
          </template>
        </el-table-column>
      </el-table> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="addinvoappvisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveinvoappdata">确 定</el-button>
      </span>
    </template></el-dialog
  >
  <el-dialog
    title="开票信息编辑"
    v-model="editinvoinfovisiable"
    width="600px"
    :show-close="false"
    @close="cleareditinvoinfodata"
    center
    ><span>
      <el-form :model="editinvoinfo" label-width="140px">
        <el-form-item label="选择开票抬头:" prop="edtaxplt">
          <TaxplateSelect
            :code="editinvoinfo.edtaxplt"
            width="450"
            :clearable="true"
            @datachange="edtaxpltcg"
          />
        </el-form-item>
        <el-form-item label="开票抬头：">
          <el-input
            :disabled="true"
            v-model="editinvoinfo.pttname"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="开票税号：">
          <el-input
            :disabled="true"
            v-model="editinvoinfo.ptttax"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="选择购方抬头:" prop="discount">
          <InvobuyerSelect
            :code="editinvoinfo.burercode"
            width="450"
            :clearable="true"
            @datachange="burercodecg"
          />
        </el-form-item>
        <el-form-item label="购方名称：">
          <el-input
            v-model="editinvoinfo.burername"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="购方税号：">
          <el-input
            v-model="editinvoinfo.burertax"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="购方地址、电话：">
          <el-input
            v-model="editinvoinfo.bureradd"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="购方开户行及账号：">
          <el-input
            v-model="editinvoinfo.bureracc"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="电子邮件：">
          <el-input
            height="420"
            v-model="editinvoinfo.email"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="发票备注：">
          <el-input
            type="textarea"
            height="420"
            v-model="editinvoinfo.remark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="editinvoinfovisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveeditinvoinfodata"
          >确 定</el-button
        >
      </span>
    </template></el-dialog
  >
  <el-dialog
    title="物料信息编辑"
    v-model="editgoodsvisiable"
    width="600px"
    :show-close="false"
    @close="cleareditgoodsdata"
    center
    ><span>
      <el-form :model="editgoodsinfo" label-width="140px">
        <el-form-item label="物料名称：">
          <el-input
            v-model="editgoodsinfo.name"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="规格：">
          <el-input
            height="420"
            v-model="editgoodsinfo.spc"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <br />
        <el-form-item label="包装基数：">
          <el-input
            height="420"
            v-model="editgoodsinfo.js"
            style="width: 350px"
            autocomplete="off"
          /> </el-form-item
        ><el-form-item label="单位：">
          <el-input
            height="420"
            v-model="editgoodsinfo.ddi"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="参考单价：">
          <el-input
            height="420"
            v-model="editgoodsinfo.pc"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="editgoodsvisiable = false">取 消</el-button>
        <el-button type="primary" @click="saveeditgoodsdata">确 定</el-button>
      </span>
    </template></el-dialog
  >
  <el-dialog
    title="引入发票"
    v-model="pullinvovisable"
    width="1200px"
    :show-close="false"
    @close="clearpullinvo"
    center
  >
    <span>
      <el-form :model="pullinvosearch" :inline="true" label-width="140px">
        <el-form-item label="开票抬头：">
          <TaxplateSelect
            :code="pullinvosearch.salerid"
            width="350px"
            :clearable="true"
            @datachange="edtaxpltcg2"
            @clear="edtaxpltcg2(null)"
          />
        </el-form-item>
        <el-form-item label="购货客户信息：">
          <el-input
            v-model="pullinvosearch.buyercode"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="发票号码：">
          <el-input
            v-model="pullinvosearch.taxcode"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="备注信息：">
          <el-input
            v-model="pullinvosearch.remark"
            style="width: 350px"
            autocomplete="off"
          />
        </el-form-item>
        <el-button @click="dopullinvosearch" size="small">查 询</el-button>
      </el-form>
      <el-table
        :data="pullinvodata.invos"
        style="width: 100%; margin-top: 5px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        @selection-change="addpullinvotableselechange"
      >
        <el-table-column type="selection" width="40" align="center" />

        <el-table-column width="120" align="left">
          <!--  -->
          <template #header>开票抬头 </template>
          <template #default="scope">
            {{ scope.row.platename }}
          </template>
        </el-table-column>
        <el-table-column width="420" align="left">
          <template #default="scope">
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
            ><br />
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
            ><br />
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>地 址、电 话：</b>{{ scope.row.buyeraddress }}</span
            ><br />
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>开户行、账号：</b>{{ scope.row.buyeraccount }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="inv_type" width="200" align="left">
          <template #default="scope">
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>发票日期：</b> {{ dateformart2(scope.row.inv_data) }}</span
            ><br />
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>发票代码：</b> {{ scope.row.tax_code }}</span
            ><br />
            <span style="padding: 0px; margin: 0px; text-indent: -12em"
              ><b>发票号码：</b> {{ scope.row.tax_num }}</span
            ><br />
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-if="scope.row.inv_type == '000'"
              ><b>发票类型：</b>待定</span
            >
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-else-if="scope.row.inv_type == '007'"
              ><b>发票类型：</b>纸普</span
            >
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-else-if="scope.row.inv_type == '026'"
              ><b>发票类型：</b>电普</span
            >
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-else-if="scope.row.inv_type == '004'"
              ><b>发票类型：</b>纸专</span
            >
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-else-if="scope.row.inv_type == '028'"
              ><b>发票类型：</b>电专</span
            >
            <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
              ><b>发票类型：</b>数据错误</span
            >
            <br />
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-if="scope.row.taxlv === ''"
              ><b>发票税率：</b></span
            >
            <span
              style="padding: 0px; margin: 0px; text-indent: -12em"
              v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
              ><b>发票税率：</b>免税</span
            >
            <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
              ><b>发票税率：</b
              >{{ (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="发票金额" width="100" align="right">
          <template #default="scope">
            {{ (scope.row.total_amount / 100).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="remark" label="备注" width="180" />
      </el-table> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="pullinvovisable = false">取 消</el-button>
        <el-button type="primary" @click="savepullinvodata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="发票查看"
    v-model="viewinvovisable"
    width="1200px"
    :show-close="false"
    @close="clearpullinvo"
    center
  >
    <span>
      <span class="invoviewtool"><b>购货客户名称：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyername }}</span>

      <span class="invoviewtool"><b>开票抬头：</b></span>
      <span class="invoviewdata">{{ viewinvodata.platename }}</span>

      <span class="invoviewtool"><b>购货客户税号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyertaxnum }}</span>

      <span class="invoviewtool"><b>发票编号：</b></span>
      <span class="invoviewdata">{{
        viewinvodata.tax_code + "   " + viewinvodata.tax_num
      }}</span>

      <span class="invoviewtool"><b>购货客户地址、电话：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraddress }}</span>

      <span class="invoviewtool"><b>电子邮箱：</b></span>
      <span class="invoviewdata">{{ viewinvodata.mail }}</span>

      <span class="invoviewtool"><b>购货客户开户行、账号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraccount }}</span>

      <span class="invoviewtool"><b>开票日期：</b></span>
      <span class="invoviewdata">{{
        dateformart2(viewinvodata.inv_data)
      }}</span>

      <span class="invoviewtool"><b>发票类型：</b></span>
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction == 1"
        >待定</span
      >
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction != 1"
        >红字待定</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction == 1
        "
        >纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction != 1
        "
        >红字纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction == 1
        "
        >电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction != 1
        "
        >红字电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction == 1
        "
        >纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction != 1
        "
        >红字纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction == 1
        "
        >电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction != 1
        "
        >红字电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '020' && viewinvodata.inv_direction == 1
        "
        >全电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '020' && viewinvodata.inv_direction != 1
        "
        >红字全电专</span
      >

      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '021' && viewinvodata.inv_direction == 1
        "
        >全电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '021' && viewinvodata.inv_direction != 1
        "
        >红字全电普</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>发票金额：</b></span>
      <span class="invoviewdata">{{
        finallnum(viewinvodata.total_amount / 100)
      }}</span>

      <span class="invoviewtool"><b>状态：</b></span>
      <span class="invoviewdata" v-if="viewinvodata.status == '0'">待确认</span>
      <span class="invoviewdata" v-else-if="viewinvodata.status == '1'"
        >待开票</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '4'"
        >待作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '7'"
        >待红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '10'"
        >出现错误</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '20'"
        >发送中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '23'"
        >专红申请</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '27'"
        >专红发送</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '30'"
        >开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '32'"
        >专红审核中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '34'"
        >专红开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '40'"
        >开票已完成</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '90'"
        >已作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '95'"
        >已红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '99'"
        >未开删除</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>备注：</b></span>
      <span class="invoviewdata">{{ viewinvodata.remark }}</span>
      <el-table
        :data="viewinvodata.dtl"
        style="width: 100%; margin-top: 12px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
      >
        <el-table-column label="商品名称" width="320" align="left">
          <template #default="scope">
            {{ scope.row.goodsname }}
          </template>
        </el-table-column>
        <el-table-column label="商品规格" width="120" align="center">
          <template #default="scope">
            {{ scope.row.goodspec }}
          </template>
        </el-table-column>
        <el-table-column label="单位" width="80" align="center">
          <template #default="scope">
            {{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="数量" width="120" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null
              "
              >{{ (parseInt(scope.row.num) / 1000000).toFixed(2) }}</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税单价" width="80" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null &&
                (scope.row.taxamount == null || scope.row.taxamount == 0)
              "
              >{{
                (
                  ((parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                    parseInt(scope.row.num)) *
                  10000
                ).toFixed(2)
              }}</span
            >
            <span
              v-else-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null &&
                scope.row.taxamount != null &&
                scope.row.taxamount != 0
              "
            >
              {{
                (
                  (parseInt(scope.row.taxamount) / parseInt(scope.row.num)) *
                  10000
                ).toFixed(2)
              }}
            </span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税金额" width="120" align="center">
          <template #default="scope">
            <span
              v-if="scope.row.taxamount == null || scope.row.taxamount == 0"
            >
              {{
                (
                  (parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                  100
                ).toFixed(2)
              }}</span
            >
            <span
              v-else-if="
                scope.row.taxamount != null && scope.row.taxamount != 0
              "
            >
              {{ (parseInt(scope.row.taxamount) / 100).toFixed(2) }}
            </span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="税率" width="80" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxlv === ''"></span>
            <span v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
              >免税</span
            >
            <span v-else>{{
              (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税额" width="120" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.taxlv == -1 ||
                scope.row.taxlv == '-1' ||
                scope.row.taxlv == 0 ||
                scope.row.taxlv == '0'
              "
              >**</span
            >
            <span
              v-else-if="
                scope.row.taxamount == null || scope.row.taxamount == 0
              "
            >
              {{ (scope.row.tax / 100).toFixed(2) }}</span
            >
            <span
              v-else-if="
                scope.row.taxamount != null && scope.row.taxamount != 0
              "
            >
              {{
                (
                  (parseInt(scope.row.taxamount) -
                    parseInt(scope.row.taxamount) /
                      (1 + parseInt(scope.row.taxlv) / 1000000)) /
                  100
                ).toFixed(2)
              }}
            </span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="折扣金额" width="120" align="center">
          <template #default="scope">
            <span
              v-if="scope.row.taxamount == null || scope.row.taxamount == 0"
            >
              **</span
            >
            <span
              v-else-if="
                scope.row.taxamount != null && scope.row.taxamount != 0
              "
            >
              {{ (parseInt(scope.row.taxdiscont) / 100).toFixed(2) }}
            </span>
            <span v-else></span>
          </template>
        </el-table-column>
      </el-table> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="viewinvovisable = false">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="checkmark" width="350px" :show-close="false" center
    ><span style="line-height: 200%; text-indent: 10px"
      >{{ this.checktaxst }}<br /><br />{{
        this.ceckcode
      }}&nbsp;&nbsp;&nbsp;<el-input
        v-model="ceckcodeinput"
        style="width: 80px"
        autocomplete="off" /></span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="checkmark = false">关 闭</el-button>
        <el-button @click="ceckmarkcech">确 认</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :ref="'makemyinvoce'"
    :title="'发票生成'"
    v-model="mkivisiable"
    width="1200px"
    :show-close="false"
    @close="mkinvcleardata"
    center
  >
  </el-dialog>
</template>
<script >
import { ElMessage } from "element-plus";
//import GoodstypeCascaderVue from "../../../../../../controls/GoodstypeCascader.vue"

import { ElMessageBox } from "element-plus";
import axios from "axios";
import DepartmentCascader from "../../../../../../controls/DepartmentCascader.vue";
import BuyerInformationSeltct from "../../../../../../controls/BuyerInformationSeltct.vue";
//import ContactsCascader from "../../../../../../controls/ContactsCascader.vue"
import WarehouseSelect from "../../../../../../controls/WarehouseSelect.vue";
import ContactsdiscountSelect from "../../../../../../controls/ContactsdiscountSelect.vue";
import TaxplateSelect from "../../../../../../controls/TaxplateSelect.vue";
import InvobuyerSelect from "../../../../../../controls/InvobuyerSelect.vue";
import TaxcodeSelect from "../../../../../../controls/TaxcodeSelect.vue";

import { v4 as uuidv4 } from "uuid";
import { postback } from "@/publiccomm";

export default {
  emits: ["changedata", "dialogclose"],
  components: {
    DepartmentCascader,
    BuyerInformationSeltct,
    WarehouseSelect,
    ContactsdiscountSelect,
    TaxplateSelect,
    InvobuyerSelect,
    TaxcodeSelect,
  },
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    return {
      frecc: 0,
      discc: 0,
      mkivisiable: false,
      ntotalnumint: {
        billsam: 0,
        freesam: 0,
        discountam: 0,
        needinvoam: 0,
        invoam: 0,
      },
      ntotalnum: {
        billsam: "0.00",
        freesam: "0.00",
        discountam: "0.00",
        needinvoam: "0.00",
        dislv: "0.00%",
        invoam: "0.00",
      },
      autoselectbills: 0,
      editstring: 0,
      fileList: [],
      minamount: 0,
      maxamount: 0,
      checkmark: false,
      checkrout: 0,
      checktaxst: "",
      ceckcode: "",
      ceckcodeinput: "",
      ceckcloseat: false,
      edtaxcode: "",
      viewinvovisable: false,
      viewinvodata: [],
      pullinvodata: [],
      pullinvoselectdata: [],
      pullinvosearch: {
        salerid: "",
        salercode: "",
        buyercode: "",
        taxcode: "",
        remark: "",
      },
      editgoodsinfo: { name: "", spc: "", ddi: "", pc: 0, js: 0 },
      editgoodsvisiable: false,
      invoapptotalfree: 0,
      appserchdata: { num: "", buyername: "", goods: "" },
      appserchdatax: { num: "", buyername: "", goods: "" },
      cellediterow: "",
      celleditecomm: "",
      addadfreesdata: {
        discount: "",
        v_name: "",
        time: "",
        remark: "",
        number: 0,
        atremark: "",
      },
      invotype: "",
      invotype2: "",
      invotypes: [
        { label: "待定", value: "000" },
        { label: "纸普", value: "007" },
        { label: "纸专", value: "004" },
        { label: "全电普", value: "021" },
        { label: "全电专", value: "020" },
      ],
      involv: "",
      editetotalfree: 0,
      involvs: [
        { label: "免税", value: -1 },
        { label: "0.00%", value: 0 },
        { label: "9.00%", value: 90000 },
        { label: "13.00%", value: 130000 },
      ],
      invoappseditviewdtl: [],
      editinvoinfo: {
        edtaxplt: "",
        pttname: "",
        pttcode: "",
        ptttax: "",
        burercode: "",
        burername: "",
        burertax: "",
        bureradd: "",
        bureracc: "",
        remark: "",
        number: 0,
        email: "",
      },
      editinvoinfovisiable: false,
      invoappsedit: [],
      addadfreevisiable: false,
      addbffreevisiable: false,
      addinvoappvisiable: false,
      activeName: "first",
      addbillvisiable: false,
      addfreevisiable: false,
      dtlvisiable: false,
      tempareas: [
        { value: 1, label: "常温" },
        { value: 2, label: "冰鲜" },
        { value: 3, label: "冷冻" },
      ],
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },
      addbillsearchdata: {
        goodesinfor: "",
        buyerif: "",
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
        minamount: 0,
        maxamount: 0,
        filesselect: "",
      },
      addfreesearchdata: {
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
      },
      pullinvovisable: false,
      addappsheadselect: [],
      addappsdtlsselect: [],
      addbilltype: 1,
      addfreetype: 1,
      billsdata: [],
      addbilltotalamount: 0,
      addbilltotalamountstr: "0.00",
      addfreetotalamount: 0,
      addfreetotalamountstr: "0.00",
      billssearchresults: [],
      freessearchresults: [],
      addbullsselectdata: [],
      addfreesselectdata: [],
      freesseleed: [],
      addbullsnowpageselectdata: [],
      addfreesnowpageselectdata: [],
      addbillstotalrow: 0,
      addfreestotalrow: 0,
      addbillspagenow: 1,
      addfreespagenow: 1,
      addbillspagesize: 50,
      addfreespagesize: 50,
      billsseleed: [],
      thisdata: { dtl: [], free: [] },
      visiable: false,
      yesno: [
        { label: "是", value: "1" },
        { label: "否", value: "99" },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.cleardtldata();
        //this.visiable = true;
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);

        if (this.thisdata.status == 0) {
          this.thisdata.status_v = "待确认";
        } else if (this.thisdata.status == 50) {
          this.thisdata.status_v = "已确认";
        } else if (this.thisdata.status == 99) {
          this.thisdata.status_v = "作废";
        } else {
          this.thisdata.status_v = "数据错误";
        }

        this.activeName = "first";

        this.getdata();
        this._timer = setInterval(this.upchecktime, 10000);
      }
    },

    // thisdata: function () {
    //     console.log(this.thisdata,this.thisdata)
    //     // this.thisdata.postdatetime=this.datetimeformart(this.thisdata.post_date)
    //     // this.thisdata.docdatetime=this.datetimeformart(this.thisdata.doc_date)
    // }
  },
  mounted() {},
  methods: {
    autoselectnillbt() {
      var selecnum = 0;
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);

      if (!numRe.test(this.autoselectbills)) {
        this.autoselectbills = 0;
        ElMessage.error({
          message: "输入的自动勾选金额不合法，请重新输入",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }
      ElMessageBox.confirm(
        "自动勾选会清除已购选明细，并自上而下依次购选明细至满足自动勾选金额。最后一条明细会调整核销金额确保勾选金额与填写金额一致；确认进行操作吗？",
        "提示"
      ).then(() => {
        selecnum = parseFloat(this.autoselectbills) * 100;
        console.log("start num", selecnum);
        this.$refs.addbillstables.clearSelection();
        var i = 0;
        var rowss = this.billssearchresults.rows;
        for (i = 0; i < rowss.length; i++) {
          if (selecnum > rowss[i].main_remain_amount) {
            this.$refs.addbillstables.toggleRowSelection(rowss[i], true);
            selecnum -= rowss[i].main_remain_amount;
          } else {
            rowss[i].main_remain_amount_edit = selecnum;
            this.$refs.addbillstables.toggleRowSelection(rowss[i], true);
            selecnum -= rowss[i].main_remain_amount_edit;
            break;
          }
        }
        this.autoselectbills = selecnum / 100;
        // this.billssearchresults.rows.map((val)=>{
        //   if(selecnum>val.main_remain_amount){
        //     this.$refs.addbillstables.toggleRowSelection(val,true)
        //     selecnum-=val.main_remain_amount

        //   }else{
        //     val.main_remain_amount_edit=selecnum
        //     this.$refs.addbillstables.toggleRowSelection(val,true)
        //     return
        //   }
        // })
      });
    },
    dataupload(rowcode) {
      console.log("getupdata", rowcode);
      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "search", row_code: rowcode },
        })
        .then((request) => {
          console.log(request);
          if (request.status == 3) {
            var redt = JSON.parse(request.log);
            this.addbillsearchdata.filesselect = redt.codestring;
            window.open(
              "/api_v3/interface/getfile?file=" +
                "/filedown/" +
                rowcode +
                ".xlsx",
              "_self"
            );
            this.addbilltype = 1;
            this.addbillsflashdata();
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
          } else {
            this._getuploadstatus = setTimeout(() => {
              this.dataupload(rowcode);
            }, 2000);
          }
        })
        .catch(() => {});
    },
    uploadinvosuccess(req, file, files) {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      if (req.rescode != 0) {
        console.log("upload success", {
          req: req,
          file: file,
          files: files,
          thisdata: this.thisdata,
        });
        ElMessage.error({
          message: req.resmsg,
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
      } else {
        console.log("upload success", {
          req: req,
          file: file,
          files: files,
          thisdata: this.thisdata,
        });
        postback(
          "/" + this.dialoginfo.modlecode + "/reconinvofileop",
          {
            contacts_code: this.thisdata.contacts_code,
            filename: req.data,
            row_code: this.thisdata.row_code,
          },
          this.reconinvofileopback
        );
      }
      this.fileList = [];
    },

    uploadsuccess(req, file, files) {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      // req=JSON.parse(req)

      if (req.rescode != 0) {
        ElMessage.error({
          message: req.resmsg,
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
      } else {
        console.log("upload success", {
          req: req,
          file: file,
          files: files,
          thisdata: this.thisdata,
        });
        postback(
          "/" + this.dialoginfo.modlecode + "/reconfileop",
          {
            contacts_code: this.thisdata.contacts_code,
            filename: req.data,
            hasid: this.thisdata.bills,
          },
          this.reconfileopback
        );
      }
      this.fileList = [];

      // this._getuploadstatus = setTimeout(() => {
      //   this.dataupload(req.data);
      // }, 2000);
    },
    reconfileopback(request) {
      var req = JSON.parse(request);
      console.log("opfileback requ", req);
      this.addbillsearchdata.filesselect = req.codestring;
      //window.open(req.filepath, "_self");
      window.open("/api_v3/interface/getfile?file=" + req.filepath, "_self");
      this.addbilltype = 1;
      this.addbillsflashdata();
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
    },
    reconinvofileopback(request) {
      var req = JSON.parse(request);
      console.log("opfileback requxxx", req);
      if (req.haserror) {
        ElMessage.error({
          message:
            "上传发票数据解析失败，即将自动下载解析结果文件，请将错误处理完后重新上传！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        window.open("/api_v3/interface/getfile?file=" + req.filepath, "_self");     
      } else {
        this.thisdata.invos=req.enddata.rows
        this.ntotalnum.invoam=this.finallnum(req.enddata.totalamount/100)
        this.ntotalnumint.invoam=req.enddata.totalamount


      }

      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");



    },

    uploadinvoerror(req, file, files) {
      ElMessage.error({
        message: "上传文件失败，请重试！！！！！",
        duration: 2000,
        dangerouslyUseHTMLString: true,
        center: false,
      });
      console.log("upload error", { req: req, file: file, files: files });
      this.fileList = [];
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
    },

    uploaderror(req, file, files) {
      ElMessage.error({
        message: "上传文件失败，请重试！！！！！",
        duration: 2000,
        dangerouslyUseHTMLString: true,
        center: false,
      });
      console.log("upload error", { req: req, file: file, files: files });
      this.fileList = [];
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
    },

    uploadfile(file, files) {
      console.log("uploadfile", { file: file, files: files });
    },
    taildiffcg(val) {
      console.log(val);
      clearTimeout(this._uptaildi);
      this._uptaildi = setTimeout(() => {
        axios
          .post("/" + this.dialoginfo.modlecode + "/uptaildiff", {
            data: { row_code: this.thisdata.row_code, taildiff: val },
          })
          .then(() => {
            this.remathdata();
          });
      }, 600);
    },
    editwcdd(val) {
      var numReg = /^[0-9]{0,1}$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(val)) {
        ElMessage.error({
          message: "尾差调整范围为-0.99至0.99，请勿超出范围！！！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.editstring = 0;
      }
    },
    cleaninvosdata() {
      ElMessageBox.confirm("是否确认清空发票数据？", "警告！！")
        .then(() => {
          // this.thisdata.invos = [];
          // this.thisdata.totalinvoamountnum = 0;
          // this.thisdata.totalinvoamount = this.finallnum(
          //   this.thisdata.totalinvoamountnum / 100
          // );
          this.$bus.$emit("showloading");
          this.$bus.$emit("showloading");
          this.$bus.$emit("showloading");

          axios
            .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
              data: { type: "get" },
            })
            .then((response) => {
              console.log("response", response);
              axios
                .post(
                  "/" + this.dialoginfo.modlecode + "/deletinvos",
                  {
                    data: {
                      invos: this.thisdata.invos,
                      row_code: this.thisdata.row_code,
                      runopuu: response,
                    },
                  },
                  { timeout: 15000 }
                )
                .then(() => {})
                .catch(() => {
                  // this.$bus.$emit("hideloading");
                });

              this.$bus.$emit("showloading");
              this._getopstatus = setTimeout(() => {
                this.checkopstatus(response, "invosave");
              }, 2000);
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        })
        .catch(() => {});
    },
    viewinvos(val) {
      console.log(val);
      if (val.sid == "") {
        this.viewinvodata = val;
        this.viewinvovisable = true;
      } else {
        axios
          .post("/" + this.dialoginfo.modlecode + "/getinvodataall", {
            data: { row_code: val.row_code },
          })
          .then((req) => {
            this.viewinvodata = req;
            this.viewinvovisable = true;
          })
          .catch(() => {
            // this.$bus.$emit("hideloading");
          });
      }
    },
    dellinvos(val) {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      var deledd = Array();
      deledd.push(val);

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/deletinvos",
              {
                data: {
                  invos: deledd,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "invosave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    addpullinvotableselechange(val) {
      console.log("addpullinvotableselechange", val);
      this.pullinvoselectdata = val;
    },
    savepullinvodata() {
      this.$bus.$emit("showloading");

      if (this.pullinvoselectdata.length <= 0) {
        this.pullinvovisable = false;
        this.$bus.$emit("hideloading");
        return;
      }

      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/saveinvos",
              {
                data: {
                  invos: this.pullinvoselectdata,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                  type: "bandinvo",
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "invosave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });

      // var totalinv = 0;
      // for (var xdii = this.thisdata.invos.length; xdii > 0; xdii--) {
      //   if (this.thisdata.invos[xdii - 1].status == 0) {
      //     this.thisdata.invos.splice(xdii - 1, 1);
      //   }
      // }
      // this.pullinvoselectdata.map((vh) => {
      //   this.thisdata.invos.push(vh);

      //   totalinv += parseInt(vh.total_amount);
      // });
      // this.thisdata.totalinvoamountnum += totalinv;
      // this.thisdata.totalinvoamount = this.finallnum(
      //   this.thisdata.totalinvoamountnum / 100
      // );
      // this.thisdata.invoapps.map((invoapphead) => {
      //   invoapphead.way_type = 2
      // })
      // this.pullinvovisable = false;
      // this.$bus.$emit("hideloading");
    },
    clearpullinvo() {
      this.pullinvodata = [];
      this.pullinvosearch = {
        salerid: "",
        salercode: "",
        buyercode: "",
        taxcode: "",
        remark: "",
      };
    },
    dopullinvosearch() {
      axios
        .post("/" + this.dialoginfo.modlecode + "/getinvocelist", {
          data: {
            data: this.pullinvosearch,
            havselect: this.thisdata.invos,
          },
        })
        .then((request) => {
          this.pullinvodata = request;
        })
        .catch(() => {});
    },
    makemyinvoce() {
      var taxplatelist = Array();
      var invohead = Array();
      var tpdtl = Array();
      ElMessageBox.confirm(
        "是否确认重新生成发票数据？原发票数据（如有）将被覆盖，请确认信息无误",
        "警告！！"
      )
        .then(() => {
          axios
            .post("/getdicc/taxplate", {
              data: {
                codename: "",
                status: 1,
                code: "",
                contaccodes: "",
                atype: "",
                btype: "",
              },
            })
            .then((response) => {
              taxplatelist = response;
              console.log("sdads", taxplatelist);
              //var alltotalamount = 0;
              this.thisdata.invoapps.map((headdata) => {
                console.log("开始处理发票申请单，数据", headdata);
                headdata.way_type = 1;
                var tpdtled = Array();
                var nowtaxplainf = Array();

                for (var i = 0; i < taxplatelist.length; i++) {
                  if (taxplatelist[i].row_code == headdata.tax_plate_code) {
                    nowtaxplainf = taxplatelist[i];
                  }
                }
                if (
                  nowtaxplainf.invoicetype.indexOf(headdata.inv_type) < 0 &&
                  headdata.inv_type != "000"
                ) {
                  ElMessage.error({
                    message:
                      nowtaxplainf.platename +
                      "选择了不受支持的发票类型，请检查开票申请单数据",
                    duration: 2000,
                    dangerouslyUseHTMLString: true,
                    center: false,
                  });
                  return false;
                }
                if (headdata.inv_type == "000") {
                  return;
                }
                if (
                  ((headdata.inv_type == "004" || headdata.inv_type == "028") &&
                    (headdata.buyer_inv_name == "" ||
                      headdata.buyer_inv_taxnum == "" ||
                      headdata.buyer_inv_account == "" ||
                      headdata.buyer_inv_address == "")) ||
                  ((headdata.inv_type == "007" || headdata.inv_type == "026") &&
                    headdata.buyer_inv_name == "")
                ) {
                  ElMessage.error({
                    message: nowtaxplainf.platename + "购货人信息未提供完整",
                    duration: 2000,
                    dangerouslyUseHTMLString: true,
                    center: false,
                  });
                  return false;
                }
                //设置税收编码，根据发票类型和税率
                var taxcodestring = headdata.taxcode;

                //设置明细记录的税率
                var taxlvstss = 0;
                var taxnum = 0;
                if (parseInt(headdata.taxlv) + 1 == 0) {
                  taxlvstss = -1;
                  taxnum = 0;
                } else {
                  taxlvstss = headdata.taxlv;
                  taxnum = headdata.taxlv;
                }

                var maxamount = 0;
                if (headdata.inv_type == "004") {
                  maxamount = parseInt(nowtaxplainf.invoiceinfo_zp);
                }
                if (headdata.inv_type == "007") {
                  maxamount = parseInt(nowtaxplainf.invoiceinfo_pp);
                }
                if (headdata.inv_type == "026") {
                  maxamount = parseInt(nowtaxplainf.invoiceinfo_dp);
                }
                if (headdata.inv_type == "028") {
                  maxamount = parseInt(nowtaxplainf.invoiceinfo_dz);
                }
                maxamount *= 100;
                var nowtotalamount = 0;
                tpdtl = Array();
                var nowinvorowcode = uuidv4().replace(/-/g, "");
                var goodslist = Array();
                var unitlist = Array();
                var dtldata = Array();
                headdata.dtl.map((dtldatax) => {
                  dtldata = JSON.parse(JSON.stringify(dtldatax));
                  console.log("开始处理明细", dtldata);
                  var nowselect = -1;
                  var gi = 0;
                  var nowdtldata = dtldata;
                  var tpntam = 0;
                  var tptaam = 0;
                  var tpdtled;
                  var bs = 1;
                  var wilest = true;
                  var tptax = 0;
                  var tjnum = 0;
                  var tjamount = 0;
                  var tjdisamount = 0;
                  //var alltotalamount = 0;
                  while (parseInt(nowdtldata.amount) != 0) {
                    console.log("处理明细中", nowdtldata);

                    if (
                      nowtotalamount +
                        parseInt(nowdtldata.amount) -
                        parseInt(nowdtldata.discount_amount) >
                      maxamount
                    ) {
                      //超过发票限额，拆分
                      bs = 1;
                      wilest = true;
                      while (wilest) {
                        bs *= 2;
                        if (
                          Math.round(
                            (parseInt(nowdtldata.amount) -
                              parseInt(nowdtldata.discount_amount)) /
                              bs
                          ) <
                          maxamount - nowtotalamount
                        ) {
                          break;
                        }
                        if (
                          (parseInt(nowdtldata.amount) -
                            parseInt(nowdtldata.discount_amount)) /
                            bs <
                          40000
                        ) {
                          bs = 0;
                          break;
                        }
                      }
                      if (bs > 0) {
                        //拆分添加明细后，结束当前发票
                        console.log("需要拆分出" + bs + "分之一", nowdtldata);
                        tjnum = 0;
                        tjamount = 0;
                        tjdisamount = 0;
                        tjnum = Math.floor(parseInt(nowdtldata.sale_num) / bs);
                        tjamount = Math.floor(parseInt(nowdtldata.amount) / bs);
                        tjdisamount = Math.floor(
                          parseInt(nowdtldata.discount_amount) / bs
                        );
                        nowdtldata.sale_num -= tjnum;
                        nowdtldata.amount -= tjamount;
                        nowdtldata.discount_amount -= tjdisamount;

                        nowselect = -1;
                        for (gi = 0; gi < goodslist.length; gi++) {
                          if (
                            goodslist[gi] == nowdtldata.inv_goods_name &&
                            unitlist[gi] == nowdtldata.sale_unit
                          ) {
                            nowselect = gi;
                            break;
                          }
                        }
                        if (nowselect < 0) {
                          //物料未存在，需添加
                          tpdtl.push({
                            sid: "",
                            row_code: uuidv4().replace(/-/g, ""),
                            h_row_code: nowinvorowcode,
                            goodsname: nowdtldata.inv_goods_name,
                            unit: nowdtldata.sale_unit,
                            num: 0,
                            taxlv: taxlvstss,
                            notaxamount: 0,
                            tax: 0,
                            tax_code: taxcodestring,
                            goodspec: nowdtldata.inv_goods_spec,
                            disamount: 0,
                            distax: 0,
                          });
                          console.log("new goodsadd", nowdtldata);
                          nowselect = tpdtl.length - 1;
                        }
                        tpdtl[nowselect].num += tjnum;
                        tpntam = 0;
                        tptaam = 0;
                        tptax = taxnum / 1000000;
                        tptaam = Math.round((tjamount / (1 + tptax)) * tptax);
                        tpntam = tjamount - tptaam;
                        console.log("拆分含税计算内容1", {
                          "税率：": tptax,
                          "含税金额：": tjamount,
                          "未税金额：": tpntam,
                          "税额:": tptaam,
                          合计金额: tpntam + tptaam,
                          "计算税额：": tpntam * tptax,
                        });
                        tpdtl[nowselect].notaxamount += tpntam;
                        tpdtl[nowselect].tax += tptaam;
                        nowtotalamount += tptaam + tpntam;
                        if (parseInt(tjdisamount) != 0) {
                          tpntam = 0;
                          tptaam = 0;
                          tptax = taxnum / 1000000;
                          tptaam = Math.round(
                            (tjdisamount / (1 + tptax)) * tptax
                          );
                          tpntam = tjdisamount - tptaam;
                          console.log("拆分含税计算内容2", {
                            "税率：": tptax,
                            "含税金额：": tjdisamount,
                            "未税金额：": tpntam,
                            "税额:": tptaam,
                            合计金额: tpntam + tptaam,
                            "计算税额：": tpntam * tptax,
                          });

                          tpdtl[nowselect].disamount += tpntam;
                          tpdtl[nowselect].distax += tptaam;
                          nowtotalamount -= tptaam + tpntam;
                        }

                        tpdtled = Array();

                        tpdtl.map((valtpdddd) => {
                          console.log("处理", valtpdddd);
                          tpdtled.push({
                            sid: valtpdddd.sid,
                            row_code: valtpdddd.row_code,
                            h_row_code: valtpdddd.h_row_code,
                            goodsname: valtpdddd.goodsname,
                            unit: valtpdddd.unit,
                            num: valtpdddd.num,
                            taxlv: valtpdddd.taxlv,
                            notaxamount: valtpdddd.notaxamount,
                            tax: valtpdddd.tax,
                            tax_code: valtpdddd.tax_code,
                            goodspec: valtpdddd.inv_goods_spec,
                          });
                          if (valtpdddd.disamount != 0) {
                            tpdtled.push({
                              sid: valtpdddd.sid,
                              row_code: uuidv4().replace(/-/g, ""),
                              h_row_code: valtpdddd.h_row_code,
                              goodsname: valtpdddd.goodsname,
                              unit: "",
                              num: 0,
                              taxlv: valtpdddd.taxlv,
                              notaxamount: -valtpdddd.disamount,
                              tax: -valtpdddd.distax,
                              tax_code: valtpdddd.tax_code,
                              goodspec: "",
                            });
                          }

                          console.log("处理1-end", tpdtled);
                        });

                        //alltotalamount += nowtotalamount;
                        invohead.push({
                          sid: "",
                          row_code: nowinvorowcode,
                          interface_code: uuidv4().replace(/-/g, ""),
                          buyername: headdata.buyer_inv_name,
                          buyertaxnum: headdata.buyer_inv_taxnum,
                          buyeraccount: headdata.buyer_inv_account,
                          buyeraddress: headdata.buyer_inv_address,
                          doc_date: 0,
                          inv_data: 0,
                          status: 0,
                          spid: nowtaxplainf.plateid,
                          request: "",
                          tax_code: "",
                          tax_num: "",
                          tax_info: "",
                          email: headdata.email,
                          url: "",
                          taxlv: tpdtled[0].taxlv,
                          old_codenum: "",
                          red_infotable: "",
                          red_radcode: "",
                          total_amount: nowtotalamount,
                          inv_type: headdata.inv_type,
                          inv_direction: 1,
                          old_inv_code: "",
                          reconciliation_code: headdata.h_row_code,
                          reconciliation_invo_code: headdata.row_code,
                          platename: headdata.platename,
                          dtl: tpdtled,
                          remark: headdata.remark,
                        });
                        console.log("处理1-xed", invohead);
                        tpdtl = Array();
                        nowtotalamount = 0;
                        nowinvorowcode = uuidv4().replace(/-/g, "");
                        goodslist = Array();
                        unitlist = Array();
                      } else {
                        //结束当前发票，不拆分明细

                        console.log(
                          "不拆分，完结当前发票，当前明细另起一张发票",
                          nowdtldata
                        );
                        tpdtled = Array();
                        tpdtl.map((valtpdddd) => {
                          console.log("处理2", valtpdddd);
                          tpdtled.push({
                            sid: valtpdddd.sid,
                            row_code: valtpdddd.row_code,
                            h_row_code: valtpdddd.h_row_code,
                            goodsname: valtpdddd.goodsname,
                            unit: valtpdddd.unit,
                            num: valtpdddd.num,
                            taxlv: valtpdddd.taxlv,
                            notaxamount: valtpdddd.notaxamount,
                            tax: valtpdddd.tax,
                            tax_code: valtpdddd.tax_code,
                            goodspec: valtpdddd.goodspec,
                          });
                          if (valtpdddd.disamount != 0) {
                            tpdtled.push({
                              sid: valtpdddd.sid,
                              row_code: uuidv4().replace(/-/g, ""),
                              h_row_code: valtpdddd.h_row_code,
                              goodsname: valtpdddd.goodsname,
                              unit: "",
                              num: 0,
                              taxlv: valtpdddd.taxlv,
                              notaxamount: -valtpdddd.disamount,
                              tax: -valtpdddd.distax,
                              tax_code: valtpdddd.tax_code,
                              goodspec: "",
                            });
                          }

                          console.log("处理2-end", tpdtled);
                        });
                        //alltotalamount += nowtotalamount;
                        invohead.push({
                          sid: "",
                          row_code: nowinvorowcode,
                          interface_code: uuidv4().replace(/-/g, ""),
                          buyername: headdata.buyer_inv_name,
                          buyertaxnum: headdata.buyer_inv_taxnum,
                          buyeraccount: headdata.buyer_inv_account,
                          buyeraddress: headdata.buyer_inv_address,
                          doc_date: 0,
                          inv_data: 0,
                          status: 0,
                          spid: nowtaxplainf.plateid,
                          request: "",
                          tax_code: "",
                          tax_num: "",
                          tax_info: "",
                          email: headdata.email,
                          url: "",
                          taxlv: tpdtled[0].taxlv,
                          old_codenum: "",
                          red_infotable: "",
                          red_radcode: "",
                          total_amount: nowtotalamount,
                          inv_type: headdata.inv_type,
                          inv_direction: 1,
                          old_inv_code: "",
                          reconciliation_code: headdata.h_row_code,
                          reconciliation_invo_code: headdata.row_code,
                          platename: headdata.platename,
                          dtl: tpdtled,
                          remark: headdata.remark,
                        });
                        console.log("处理2-xed", invohead);
                        tpdtl = Array();
                        nowtotalamount = 0;
                        nowinvorowcode = uuidv4().replace(/-/g, "");
                        goodslist = Array();
                        unitlist = Array();
                      }
                    } else {
                      console.log("明细加入当前发票", nowdtldata);
                      //未超过发票限额，直接开票
                      //检查是否存在相同物料
                      nowselect = -1;
                      for (gi = 0; gi < goodslist.length; gi++) {
                        if (
                          goodslist[gi] == nowdtldata.inv_goods_name &&
                          unitlist[gi] == nowdtldata.sale_unit
                        ) {
                          nowselect = gi;
                          break;
                        }
                      }
                      if (nowselect < 0) {
                        //物料未存在，需添加
                        tpdtl.push({
                          sid: "",
                          row_code: uuidv4().replace(/-/g, ""),
                          h_row_code: nowinvorowcode,
                          goodsname: nowdtldata.inv_goods_name,
                          unit: nowdtldata.sale_unit,
                          num: 0,
                          taxlv: taxlvstss,
                          notaxamount: 0,
                          tax: 0,
                          tax_code: taxcodestring,
                          goodspec: nowdtldata.inv_goods_spec,
                          disamount: 0,
                          distax: 0,
                        });
                        console.log("new goodsadd", nowdtldata);
                        nowselect = tpdtl.length - 1;
                      }
                      tpdtl[nowselect].num += parseInt(nowdtldata.sale_num);
                      tpntam = 0;
                      tptaam = 0;
                      tptax = taxnum / 1000000;
                      tptaam = Math.round(
                        (parseInt(nowdtldata.amount) / (1 + tptax)) * tptax
                      );
                      tpntam = parseInt(nowdtldata.amount) - tptaam;

                      console.log("拆分含税计算内容3", {
                        "税率：": tptax,
                        "含税金额：": parseInt(nowdtldata.amount),
                        "未税金额：": tpntam,
                        "税额:": tptaam,
                        合计金额: tpntam + tptaam,
                        "计算税额：": tpntam * tptax,
                      });

                      tpdtl[nowselect].notaxamount += parseInt(tpntam);
                      tpdtl[nowselect].tax += parseInt(tptaam);
                      nowtotalamount += parseInt(tptaam) + parseInt(tpntam);
                      if (parseInt(nowdtldata.discount_amount) != 0) {
                        tpntam = 0;
                        tptaam = 0;
                        tptax = taxnum / 1000000;
                        tptaam = Math.round(
                          (parseInt(nowdtldata.discount_amount) / (1 + tptax)) *
                            tptax
                        );
                        tpntam =
                          parseInt(nowdtldata.discount_amount) -
                          parseInt(tptaam);
                        console.log("拆分含税计算内容4", {
                          "税率：": tptax,
                          "含税金额：": parseInt(nowdtldata.discount_amount),
                          "未税金额：": tpntam,
                          "税额:": tptaam,
                          合计金额: tpntam + tptaam,
                          "计算税额：": tpntam * tptax,
                        });

                        tpdtl[nowselect].disamount += parseInt(tpntam);
                        tpdtl[nowselect].distax += parseInt(tptaam);
                        nowtotalamount -= parseInt(tptaam) + parseInt(tpntam);
                      }
                      nowdtldata.amount = 0;
                    }
                  }
                });

                if (nowtotalamount > 0) {
                  tpdtled = Array();
                  tpdtl.map((valtpdddd) => {
                    console.log("处理3", valtpdddd);
                    tpdtled.push({
                      sid: valtpdddd.sid,
                      row_code: valtpdddd.row_code,
                      h_row_code: valtpdddd.h_row_code,
                      goodsname: valtpdddd.goodsname,
                      unit: valtpdddd.unit,
                      num: valtpdddd.num,
                      taxlv: valtpdddd.taxlv,
                      notaxamount: valtpdddd.notaxamount,
                      tax: valtpdddd.tax,
                      tax_code: valtpdddd.tax_code,
                      goodspec: valtpdddd.goodspec,
                    });
                    if (valtpdddd.disamount != 0) {
                      tpdtled.push({
                        sid: valtpdddd.sid,
                        row_code: uuidv4().replace(/-/g, ""),
                        h_row_code: valtpdddd.h_row_code,
                        goodsname: valtpdddd.goodsname,
                        unit: "",
                        num: 0,
                        taxlv: valtpdddd.taxlv,
                        notaxamount: -valtpdddd.disamount,
                        tax: -valtpdddd.distax,
                        tax_code: valtpdddd.tax_code,
                        goodspec: "",
                      });
                    }

                    console.log("处理3-end", tpdtled);
                  });
                  //alltotalamount += nowtotalamount;
                  invohead.push({
                    sid: "",
                    row_code: nowinvorowcode,
                    interface_code: uuidv4().replace(/-/g, ""),
                    buyername: headdata.buyer_inv_name,
                    buyertaxnum: headdata.buyer_inv_taxnum,
                    buyeraccount: headdata.buyer_inv_account,
                    buyeraddress: headdata.buyer_inv_address,
                    doc_date: 0,
                    inv_data: 0,
                    status: 0,
                    spid: nowtaxplainf.plateid,
                    request: "",
                    tax_code: "",
                    tax_num: "",
                    tax_info: "",
                    email: headdata.email,
                    url: "",
                    taxlv: tpdtled[0].taxlv,
                    old_codenum: "",
                    red_infotable: "",
                    red_radcode: "",
                    total_amount: nowtotalamount,
                    inv_type: headdata.inv_type,
                    inv_direction: 1,
                    old_inv_code: "",
                    reconciliation_code: headdata.h_row_code,
                    reconciliation_invo_code: headdata.row_code,
                    platename: headdata.platename,
                    dtl: tpdtled,
                    remark: headdata.remark,
                  });

                  console.log("处理3-xed", invohead);
                }
              });

              //this.thisdata.invos = invohead;

              this.$bus.$emit("showloading");
              this.$bus.$emit("showloading");
              this.$bus.$emit("showloading");

              axios
                .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
                  data: { type: "get" },
                })
                .then((response) => {
                  console.log("response", response);
                  axios
                    .post(
                      "/" + this.dialoginfo.modlecode + "/saveinvos",
                      {
                        data: {
                          invos: invohead,
                          row_code: this.thisdata.row_code,
                          runopuu: response,
                          type: "makemy",
                        },
                      },
                      { timeout: 15000 }
                    )
                    .then(() => {})
                    .catch(() => {
                      // this.$bus.$emit("hideloading");
                    });

                  this.$bus.$emit("showloading");
                  this._getopstatus = setTimeout(() => {
                    this.checkopstatus(response, "invosave");
                  }, 2000);
                })
                .catch(() => {
                  this.$bus.$emit("hideloading");
                });
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    saveeditgoodsdata() {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      var ckpass = true;
      if (!numRe.test(this.editgoodsinfo.js)) {
        ckpass = false;
      }
      if (!numRe.test(this.editgoodsinfo.pc)) {
        ckpass = false;
      }
      if (
        (this.editgoodsinfo.ddi != "" &&
          (this.editgoodsinfo.js == 0 || this.editgoodsinfo.pc == 0)) ||
        (this.editgoodsinfo.js != 0 &&
          (this.editgoodsinfo.ddi == "" || this.editgoodsinfo.pc == 0)) ||
        (this.editgoodsinfo.pc != 0 &&
          (this.editgoodsinfo.js == 0 || this.editgoodsinfo.ddi == ""))
      ) {
        ckpass = false;
      }
      if (!ckpass) {
        ElMessage.error({
          message: "包装基数，单位，参考单价如填写一个则需全部填写！！！",
          duration: 2000,
        });
        return;
      }
      this.$bus.$emit("showloading");
      var stt = "|";
      this.addappsdtlsselect.map((val) => {
        stt += val.row_code + "|";
      });
      var accnum = 0;

      this.invoappsedit.dtls.map((val, index) => {
        if (stt.indexOf(val.row_code) > 0) {
          if (this.editgoodsinfo.name != "") {
            this.invoappsedit.dtls[index].ivocegoodsname =
              this.editgoodsinfo.name;
          }
          this.invoappsedit.dtls[index].ivocev_spec = this.editgoodsinfo.spc;
          if (this.editgoodsinfo.ddi != "") {
            this.invoappsedit.dtls[index].sec_unit = this.editgoodsinfo.ddi;
            accnum =
              this.invoappsedit.dtls[index].reconciliation_amount /
              100 /
              parseFloat(this.editgoodsinfo.pc);
            accnum = Math.round(accnum / parseFloat(this.editgoodsinfo.js));
            accnum = accnum * parseFloat(this.editgoodsinfo.js);
            this.invoappsedit.dtls[index].sec_num = accnum * 1000000;
          }
        }
      });
      this.editgoodsvisiable = false;
      this.$bus.$emit("hideloading");
    },
    cleareditgoodsdata() {
      this.editgoodsinfo = { name: "", spc: "", ddi: "", pc: 0, js: 0 };
    },
    onetotwo() {
      this.$bus.$emit("showloading");
      console.log("start hebing");
      var uuidstr = uuidv4().replace(/-/g, "");

      var nadddate = {};
      var endhead = [];
      var noutam = {};
      var noutfree = {};
      var noutdis = {};
      var niname = 0;
      var ninfree = 0;
      var nindis = 0;
      var freelv = {};
      var dislv = {};
      var rowcodest = "|";
      var rowhcode = "|";
      nadddate = {
        sid: "",
        row_code: uuidstr,
        remark: "",
        totalamount: 0,
        totalfree: 0,
        totaldis: 0,
        taxlv: "",
        invotype: "000",
        email: "",
        ptcode: "",
        pt_inv_name: "",
        pt_inv_taxnum: "",
        pt_inv_address: "",
        pt_inv_account: "",
        buyer_inv_name: "",
        buyer_inv_taxnum: "",
        buyer_inv_address: "",
        buyer_inv_account: "",
      };
      this.invoappsedit.head.map((val) => {
        dislv[val.row_code] = val.totaldis / val.totalamount;
        freelv[val.row_code] = val.totalfree / val.totalamount;
        noutam[val.row_code] = 0;
        noutfree[val.row_code] = 0;
        noutdis[val.row_code] = 0;
      });
      console.log("start hebing 111", {
        aa: freelv,
        bb: dislv,
        cc: noutam,
        dd: noutfree,
        ee: noutdis,
      });
      this.addappsdtlsselect.map((val) => {
        rowcodest += val.row_code + "|";
        rowhcode += val.h_row_code + "|";
        noutam[val.h_row_code] += val.reconciliation_amount;
        noutfree[val.h_row_code] += Math.round(
          val.reconciliation_amount * freelv[val.h_row_code]
        );
        noutdis[val.h_row_code] += Math.round(
          val.reconciliation_amount * dislv[val.h_row_code]
        );
        niname += val.reconciliation_amount;
        ninfree += Math.round(
          val.reconciliation_amount * freelv[val.h_row_code]
        );
        nindis += Math.round(val.reconciliation_amount * dislv[val.h_row_code]);
      });
      nadddate.totalamount = niname;
      nadddate.totalfree = ninfree;
      nadddate.totaldis = nindis;
      console.log("start hebing sss", {
        aa: rowcodest,
        bb: this.invoappsedit.head,
        cc: noutam,
        dd: noutfree,
        ee: noutdis,
      });
      this.invoappsedit.head.map((val) => {
        if (rowhcode.indexOf(val.row_code) >= 0) {
          console.log("sdsdsdeesdsd");
          val.totalamount -= noutam[val.row_code];
          val.totalfree -= noutfree[val.row_code];
          val.totaldis -= noutdis[val.row_code];
          if (
            val.totalamount == 0 &&
            (val.totalfree != 0 || val.totaldis != 0)
          ) {
            nadddate.totalfree += val.totalfree;
            nadddate.totaldis += val.totaldis;
            val.totaldis = 0;
            val.totalfree = 0;
          }
        }
        if (val.totalamount != 0) {
          endhead.push(val);
        }
      });
      endhead.push(nadddate);

      this.invoappsedit.dtls.map((val) => {
        if (rowcodest.indexOf(val.row_code) >= 0) {
          val.h_row_code = uuidstr;
        }
      });

      this.invoappsedit.head = endhead;
      this.$bus.$emit("hideloading");
    },
    resetattcode() {
      this.appserchdatax = { num: "", buyername: "", goods: "" };
      this.appserchdata = this.appserchdatax;
      this.flushinvoappdt();
    },
    attcodeserch() {
      this.appserchdatax = this.appserchdata;
      this.flushinvoappdt();
    },
    minamcc(val) {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(val)) {
        this.addbillsearchdata.minamount = val;
      } else {
        this.minamount = this.addbillsearchdata.minamount;
      }
    },
    maxamcc(val) {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(val)) {
        this.addbillsearchdata.maxamount = val;
      } else {
        this.maxamount = this.addbillsearchdata.maxamount;
      }
    },
    numbercgxx(val) {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      var ttfrees = 0;
      var ttdiss = 0;
      if (numRe.test(val)) {
        console.log("ckpass", val);
        this.invoappsedit.head.map((value, index) => {
          if (value.row_code == this.cellediterow) {
            this.invoappsedit.head[index].totalfree = val * 100;
          }
        });
      }
      this.invoappsedit.head.map((val) => {
        ttfrees += val.totalfree;
        ttdiss += val.totaldis;
      });
      (this.frecc = ttfrees - this.ntotalnumint.freesam),
        (this.discc = ttdiss - this.ntotalnumint.discountam),
        (this.cellediterow = "");
      this.celleditecomm = "";
      console.log(val);
    },

    numbercgxx3(val) {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      var ttfrees = 0;
      var ttdiss = 0;
      if (numRe.test(val)) {
        console.log("ckpass", val);
        this.invoappsedit.head.map((value, index) => {
          if (value.row_code == this.cellediterow) {
            this.invoappsedit.head[index].totaldis = val * 100;
          }
        });
      }
      this.invoappsedit.head.map((val) => {
        ttfrees += val.totalfree;
        ttdiss += val.totaldis;
      });
      (this.frecc = ttfrees - this.ntotalnumint.freesam),
        (this.discc = ttdiss - this.ntotalnumint.discountam),
        (this.cellediterow = "");
      this.celleditecomm = "";
      console.log(val);
    },
    numbercgxx2(val) {
      var numReg = /^[0-9]+[.]{0,1}[0-9]{0,2}$/;
      var numRe = new RegExp(numReg);
      var totalfreetp = 0;
      if (numRe.test(val)) {
        this.billssearchresults.rows.map((val) => {
          if (val.row_code == this.cellediterow) {
            if (this.editetotalfree * 100 > val.main_remain_amount) {
              ElMessage.error({
                message: "核销金额不可超过待核销金额！！！",
                duration: 2000,
              });
            } else {
              val.main_remain_amount_edit = this.editetotalfree * 100;
              this.addbullsnowpageselectdata.map((val2) => {
                if (val2.row_code == val.row_code) {
                  val2.main_remain_amount_edit = val.main_remain_amount_edit;
                }
              });
            }
          }
        });
        this.addbullsnowpageselectdata.map((val) => {
          totalfreetp += val.main_remain_amount_edit;
        });

        this.addbilltotalamountstr = (totalfreetp / 100).toFixed(2);
      }

      this.cellediterow = "";
      this.celleditecomm = "";
      console.log("sdsdjlhhwlhd", totalfreetp);
    },
    billsadddetabClick(row, column) {
      this.cellediterow = row.row_code;
      this.celleditecomm = column.property;
      this.editetotalfree = (row.main_remain_amount_edit / 100).toFixed(2);
    },
    invoapptabClick(row, column) {
      this.cellediterow = row.row_code;
      this.celleditecomm = column.property;
      if (column.property == "totalfree") {
        this.editetotalfree = (row.totalfree / 100).toFixed(2);
      }
      if (column.property == "totaldis") {
        this.editetotalfree = (row.totaldis / 100).toFixed(2);
      }
    },
    addinvoappdtltableselechange(val) {
      this.addappsdtlsselect = val;
    },
    involvtypecg1(val) {
      if (val !== "") {
        this.invoappsedit.head.map((value, index) => {
          if (value.row_code == this.cellediterow) {
            this.invoappsedit.head[index].taxlv = val;
          }
        });
        this.invoappsedit.dtls.map((value, index) => {
          if (value.h_row_code == this.cellediterow) {
            this.invoappsedit.dtls[index].tax_rait = val;
          }
        });
      }
      this.involv = "";
      this.cellediterow = "";
      this.celleditecomm = "";
    },
    edtaxcodecg2(val) {
      console.log(val);
      this.invoappsedit.head.map((value, index) => {
        if (value.row_code == this.cellediterow) {
          this.invoappsedit.head[index].taxcode = val.v_num;
          this.invoappsedit.head[index].taxcodetext = val.v_name;
          this.invoappsedit.head[index].taxcodlv = val.v_lvs;
        }
      });
    },
    invotypecg2(val) {
      this.invoappsedit.head.map((value, index) => {
        if (value.row_code == this.cellediterow) {
          this.invoappsedit.head[index].invotype = val;
        }
      });
      this.invotype2 = "";
      this.cellediterow = "";
      this.celleditecomm = "";
    },

    invotypecg1(val) {
      if (val.value != "") {
        this.invoappsedit.head.map((value, index) => {
          this.addappsheadselect.map((value2) => {
            if (value.row_code == value2.row_code) {
              this.invoappsedit.head[index].invotype = val;
            }
          });
        });
      }
      this.invotype = "";
    },
    saveeditinvoinfodata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/updateinvobuyer", {
          data: {
            data: this.editinvoinfo,
          },
        })
        .then(() => {
          this.invoappsedit.head.map((value, index) => {
            this.addappsheadselect.map((value2) => {
              if (value.row_code == value2.row_code) {
                this.invoappsedit.head[index].ptcode =
                  this.editinvoinfo.pttcode;
                this.invoappsedit.head[index].pt_inv_name =
                  this.editinvoinfo.pttname;
                this.invoappsedit.head[index].pt_inv_taxnum =
                  this.editinvoinfo.ptttax;
                this.invoappsedit.head[index].buyer_inv_name =
                  this.editinvoinfo.burername;
                this.invoappsedit.head[index].buyer_inv_taxnum =
                  this.editinvoinfo.burertax;
                this.invoappsedit.head[index].buyer_inv_address =
                  this.editinvoinfo.bureradd;
                this.invoappsedit.head[index].buyer_inv_account =
                  this.editinvoinfo.bureracc;
                this.invoappsedit.head[index].remark = this.editinvoinfo.remark;
                this.invoappsedit.head[index].email = this.editinvoinfo.email;
              }
            });
          });
          this.editinvoinfovisiable = false;
        })
        .catch(() => {});

      this.$bus.$emit("hideloading");
    },
    cleareditinvoinfodata() {
      this.editinvoinfo = {
        edtaxplt: "",
        pttname: "",
        pttcode: "",
        ptttax: "",
        burercode: "",
        burername: "",
        burertax: "",
        bureradd: "",
        bureracc: "",
        remark: "",
        number: 0,
        email: "",
      };
    },
    burercodecg(val) {
      console.log("sdsdsd", val);
      // editinvoinfo: { edtaxplt: '', pttname: '', pttcode: '', ptttax: '', burercode: '', burername: '', burertax: '', bureradd: '', bureracc: '', remark: '', number: 0, atremark: "" },
      this.editinvoinfo.burercode = val.row_code;
      this.editinvoinfo.burername = val.v_name;
      this.editinvoinfo.burertax = val.taxnum;
      this.editinvoinfo.bureradd = val.address;
      this.editinvoinfo.bureracc = val.account;
    },
    edtaxpltcg(val) {
      console.log("aaaa", { val: val, endata: this.editinvoinfo });
      // editinvoinfo:{edtaxplt:'',pttname:'',pttcode:'',ptttax:'',
      this.editinvoinfo.pttname = val.platename;
      this.editinvoinfo.pttcode = val.row_code;
      this.editinvoinfo.edtaxplt = val.row_code;
      this.editinvoinfo.ptttax = val.platetaxnum;
    },
    edtaxpltcg2(val) {
      console.log("aaaa", val);
      if (val == null) {
        this.pullinvosearch.salercode = "";
        this.pullinvosearch.salerid = "";
      } else {
        this.pullinvosearch.salercode = val.plateid;
        this.pullinvosearch.salerid = val.row_code;
      }
    },
    addinvoapptableselechange(val) {
      this.addappsheadselect = val;
      this.flushinvoappdt();
    },
    flushinvoappdt() {
      var rowstring = "||";
      this.addappsheadselect.map((value) => {
        rowstring += value.row_code + "|";
      });
      this.invoappseditviewdtl = [];
      this.invoappsedit.dtls.map((value) => {
        // this.appserchdatax = { num: "", buyername: "", goods: "" };
        if (
          rowstring.indexOf(value.h_row_code) > 0 &&
          (value.single_num.indexOf(this.appserchdatax.num) >= 0 ||
            value.buyer_order_num.indexOf(this.appserchdatax.num) >= 0) &&
          value.buyername.indexOf(this.appserchdatax.buyername) >= 0 &&
          value.goodsname.indexOf(this.appserchdatax.goods) >= 0
        ) {
          this.invoappseditviewdtl.push(value);
        }
      });
    },
    checkdata() {
      var zsfps = 0;
      console.log(this.thisdata);
      if (
        this.thisdata.totalbillnum +
          this.thisdata.taildiff * 100 -
          this.thisdata.totalfreenun -
          this.thisdata.totalinvoappnum !=
        0
      ) {
        ElMessage.error({
          message: "账单、折扣、发票申请金额不匹配，请检查后确认！！！！！",
          duration: 2000,
          center: false,
        });
        return;
      }
      this.thisdata.invoapps.map((val) => {
        console.log(val.inv_type);
        if (val.inv_type == "000") {
          zsfps++;
        }
      });
      console.log(this.thisdata.invoapps.length, zsfps);
      if (zsfps != this.thisdata.invoapps.length && zsfps > 0) {
        ElMessage.error({
          message:
            "待确认类型的发票申请不可以和其他类型的发票申请同时存在，请检查后确认！！！！！",
          duration: 2000,
          center: false,
        });
        return;
      }
      if (
        zsfps == 0 &&
        this.thisdata.totalinvoamountnum != this.thisdata.totalinvoappnum
      ) {
        ElMessage.error({
          message: "发票金额与发票申请单金额不一致，请检查后确认！！！！！",
          duration: 2000,
          center: false,
        });
        return;
      }
      if (
        zsfps == this.thisdata.invoapps.length &&
        this.thisdata.invos.length > 0
      ) {
        ElMessage.error({
          message:
            "发票申请单中发票类型为待定时，不可添加发票明细，请检查后再确认！！！！！",
          duration: 2000,
          center: false,
        });
        return;
      }
      var ceckcode = "100" + Math.round(Math.random() * 10000000);
      console.log(ceckcode);
      ceckcode = ceckcode.substring(5, 9);
      this.checkrout = 1;
      this.checktaxst =
        "确认对账单后将无法撤销，同时发票数据将提交至税控系统开票。请检查数据无误后输入验证码进行确认！！";

      this.ceckcode = ceckcode;
      this.ceckcodeinput = "";
      this.checkmark = true;

      // ElMessageBox.prompt(
      //   "确认对账单后将无法撤销，同时发票数据将提交至税控系统开票\n请检查数据无误后输入验证码进行确认！！\n" +
      //     ceckcode,
      //   "警告！！",
      //   {
      //     beforeclose: "closecheckmes",
      //   }
      // );
      // .then((promptvalue) => {
      //   console.log(promptvalue)
      //   return
      //   // axios
      //   //   .post(
      //   //     "/" + this.dialoginfo.modlecode + "/ceckdata",
      //   //     {
      //   //       data: {
      //   //         row_code: this.thisdata.row_code,
      //   //       },
      //   //     },
      //   //     { timeout: 15000 }
      //   //   )
      //   //   .then(() => {})
      //   //   .catch(() => {
      //   //     // this.$bus.$emit("hideloading");
      //   //   });
      // })
      // .catch(() => {});
    },
    upremark() {
      axios
        .post(
          "/" + this.dialoginfo.modlecode + "/upremark",
          {
            data: {
              row_code: this.thisdata.row_code,
              remark: this.thisdata.remark,
            },
          },
          { timeout: 15000 }
        )
        .then(() => {})
        .catch(() => {
          // this.$bus.$emit("hideloading");
        });
    },
    ceckmarkcech() {
      if (this.ceckcodeinput == this.ceckcode) {
        if (this.checkrout == 1) {
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/ceckdata",
              {
                data: {
                  row_code: this.thisdata.row_code,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {
              this.checkmark = false;
              this.visiable = false;
              this.$emit("changedata", {});
            })
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });
        }
      } else {
        var ceckcode = "100" + Math.round(Math.random() * 10000000);
        console.log(ceckcode);
        ceckcode = ceckcode.substring(5, 9);

        this.ceckcode = ceckcode;
        this.ceckcodeinput = "";
      }
    },

    addinvoappopen() {
      this.$bus.$emit("showloading");

      this.cellediterow = "";
      this.celleditecomm = "";
      var tpdtls = [];
      this.thisdata.bills.map((val) => {
        if (val.reconciliation_amount == 0) {
          return;
        }
        tpdtls.push(val);
      });
      this.invoappsedit.dtls = JSON.parse(JSON.stringify(tpdtls));
      console.log("开始处理", this.invoappsedit.dtls);
      // this.ntotalnum.billsam
      // this.ntotalnum.freesam=this.finallnum(frees2amx/100);
      // this.ntotalnum.discountam=this.finallnum(frees1amx/100);
      this.invoappsedit.head = [];
      var freelv = 0;
      var discontlv = 0;
      if (this.ntotalnumint.billsam != 0) {
        freelv =
          parseInt(this.ntotalnumint.freesam) /
          parseInt(this.ntotalnumint.billsam);
      }
      if (this.ntotalnumint.billsam != 0) {
        discontlv =
          parseInt(this.ntotalnumint.discountam) /
          parseInt(this.ntotalnumint.billsam);
      }
      var taxlvls = "|";
      var taxuuids = Array();
      var billamount = Array();
      var uuidstr = "";

      this.invoappsedit.dtls.map((val, index) => {
        this.invoappsedit.dtls[index].ivocegoodsname =
          this.invoappsedit.dtls[index].goodsname;
        this.invoappsedit.dtls[index].ivocev_spec =
          this.invoappsedit.dtls[index].v_spec;
        if (val.reconciliation_amount == 0) {
          return;
        }
        if (taxlvls.indexOf("|" + val.tax_rait + "|") >= 0) {
          //税率已存在
          this.invoappsedit.dtls[index].h_row_code =
            taxuuids["|" + val.tax_rait + "|"];
          billamount["|" + val.tax_rait + "|"] += parseInt(
            val.reconciliation_amount
          );
        } else {
          //税率未存在
          taxlvls += val.tax_rait + "|";
          uuidstr = uuidv4().replace(/-/g, "");
          taxuuids["|" + val.tax_rait + "|"] = uuidstr;
          billamount["|" + val.tax_rait + "|"] = parseInt(
            val.reconciliation_amount
          );
          this.invoappsedit.head.push({
            sid: "",
            row_code: uuidstr,
            remark: "",
            totalamount: 0,
            totalfree: 0,
            totaldis: 0,
            taxlv: val.tax_rait,
            invotype: "000",
            email: "",
            ptcode: "",
            pt_inv_name: "",
            pt_inv_taxnum: "",
            pt_inv_address: "",
            pt_inv_account: "",
            buyer_inv_name: "",
            buyer_inv_taxnum: "",
            buyer_inv_address: "",
            buyer_inv_account: "",
          });
          this.invoappsedit.dtls[index].h_row_code = uuidstr;
        }
      });
      var totalfree = 0;
      var totaldis = 0;

      this.invoappsedit.head.map((val) => {
        val.totalamount = billamount["|" + val.taxlv + "|"];
        val.totalfree = Math.round(parseInt(val.totalamount) * freelv);
        val.totaldis = Math.round(parseInt(val.totalamount) * discontlv);
        totalfree += val.totalfree;
        totaldis += val.totaldis;
      });
      console.log("editapps", {
        tt: totalfree,
        ss: totaldis,
        dd: discontlv,
        ddd: this.invoappsedit.head,
      });
      if (totalfree != this.ntotalnumint.freesam) {
        var opt = 0;
        var ccp = 0;
        if (totalfree > this.ntotalnumint.freesam) {
          opt = -1;
          ccp = totalfree - this.ntotalnumint.freesam;
        } else {
          opt = 1;
          ccp = -totalfree + this.ntotalnumint.freesam;
        }
        while (ccp > 0) {
          for (var itp = 0; itp < this.invoappsedit.head.length; itp++) {
            ccp--;
            this.invoappsedit.head[itp].totalfree + opt;
            if (ccp == 0) {
              break;
            }
          }
        }
      }
      if (totaldis != this.ntotalnumint.discountam) {
        opt = 0;
        ccp = 0;
        if (totaldis > this.ntotalnumint.discountam) {
          opt = -1;
          ccp = totaldis - this.ntotalnumint.discountam;
        } else {
          opt = 1;
          ccp = -totaldis + this.ntotalnumint.discountam;
        }
        while (ccp > 0) {
          for (itp = 0; itp < this.invoappsedit.head.length; itp++) {
            ccp--;
            this.invoappsedit.head[itp].totaldis + opt;
            if (ccp == 0) {
              break;
            }
          }
        }
      }
      var ttfrees = 0;
      var ttdiss = 0;
      this.invoappsedit.head.map((val) => {
        ttfrees += val.totalfree;
        ttdiss += val.totaldis;
      });
      (this.frecc = ttfrees - this.ntotalnumint.freesam),
        (this.discc = ttdiss - this.ntotalnumint.discountam),
        // this.invoappsedit.head = [{
        //   row_code: this.thisdata.row_code,
        //   remark: '待处理数据',
        //   totalamount: this.thisdata.totalbillnum,
        //   totalfree: this.thisdata.totalfreenun,
        //   taxlv: '',
        //   invotype: '000',
        //   email: '',
        //   ptcode: '',
        //   pt_inv_name: "",
        //   pt_inv_taxnum: "",
        //   pt_inv_address: "",
        //   pt_inv_account: "",
        //   buyer_inv_name: "",
        //   buyer_inv_taxnum: "",
        //   buyer_inv_address: "",
        //   buyer_inv_account: "",

        // }]

        (this.addinvoappvisiable = true);

      this.$bus.$emit("hideloading");
    },
    savemakeinvocall(request) {
      console.log("mkinvos call", request);
      var req = JSON.parse(request);
      console.log("mkinvos call", req);
      if (req.rescode == 0) {
        // if (val2 == "invosave") {
        //       dtt = JSON.parse(response.log);
        //       this.thisdata.invos = dtt.rows;
        //       this.thisdata.totalinvoamountnum = dtt.totalamount;
        //       this.remathdata();
        //       this.pullinvovisable = false;
        //     }
        //     this.$bus.$emit("hideloading");
        //     this.$bus.$emit("hideloading");
        //     this.$bus.$emit("hideloading");
        this.thisdata.invos = req.data.rows;
        this.ntotalnum.needinvoam = req.data.totalamount;
        this.remathdata();
        this.addinvoappvisiable = false;

        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
      } else {
        ElMessage.error({
          message: "存在错误，信息如下：" + req.resmsg,
          duration: 2000,
        });
      }
    },
    saveinvoappdata() {
      var errorst = "";
      console.log("kjskdhkhasd", this.invoappsedit);
      this.invoappsedit.head.map((val) => {
        if (val.pt_inv_name == "") {
          if (!(errorst.indexOf("存在未选择开票抬头的记录") >= 0)) {
            errorst += "存在未选择开票抬头的记录;  ";
          }
        }

        if (val.buyer_inv_name == "") {
          if (!(errorst.indexOf("存在未选择购货抬头的记录") >= 0)) {
            errorst += "存在未选择购货抬头的记录;  ";
          }
        }

        if (val.invotype == "000") {
          if (!(errorst.indexOf("存在未选择发票类型的记录") >= 0)) {
            errorst += "存在未选择发票类型的记录;  ";
          }
        }

        if (val.taxcodetext == "") {
          if (!(errorst.indexOf("存在未选择税收编码的记录") >= 0)) {
            errorst += "存在未选择税收编码的记录;  ";
          }
        }
        console.log("asd", {
          aa: val.taxcodlv,
          bb: val.taxlv,
          cc: val.taxcodetext,
        });
        if (val.taxcodlv !== undefined) {
          if (
            !(
              val.taxcodlv.indexOf((val.taxlv / 1000000).toFixed(2)) >= 0 ||
              (val.taxlv == -1 && val.taxcodlv.indexOf("免税") >= 0)
            )
          ) {
            if (!(errorst.indexOf("存在税率与税收编码不匹配的记录") >= 0)) {
              errorst += "存在税率与税收编码不匹配的记录;  ";
            }
          }
        }
        if (
          (val.invotype == "004" || val.invotype == "020") &&
          (val.taxlv == -1 ||
            val.taxlv == "-1" ||
            val.taxlv == 0 ||
            val.taxlv == "0")
        ) {
          if (!(errorst.indexOf("存在发票类型与税率不匹配的记录") >= 0)) {
            errorst += "存在发票类型与税率不匹配的记录;  ";
          }
        }
      });
      if (this.frecc != 0 || this.discc != 0) {
        if (!(errorst.indexOf("费用差异或折扣差异不为0") >= 0)) {
          errorst += "费用差异或折扣差异不为0;  ";
        }
      }
      console.log("asdsd", errorst);
      if (errorst != "") {
        ElMessage.error({
          message: "存在错误，信息如下：" + errorst + "请修改后重试！！！！",
          duration: 2000,
        });
        return;
      }

      this.$bus.$emit("showloading");
      ElMessageBox.confirm(
        "是否确认更新发票？原发票（如有）都将被清除，请确认信息无误",
        "警告！！"
      )
        .then(() => {
          axios
            .post("/getdicc/taxplate", {
              data: {
                codename: "",
                status: 1,
                code: "",
                contaccodes: "",
                atype: "",
                btype: "",
              },
            })
            .then((request) => {
              console.log("khskdksd", request);
              var compdatas = JSON.parse(JSON.stringify(request));

              console.log("khskdksdxxx2", this.invoappsedit);

              var invodata = Array();
              var tpinvapphead = Array();
              var tpinvappdtl = Array();
              var totalamount = 0;
              var i = 0;
              var nowcomp = -1;
              var ccerr = "";

              this.invoappsedit.head.map((val) => {
                tpinvapphead = Array();
                tpinvappdtl = Array();
                totalamount = 0;
                var ddamount = 0,
                  ddfree = 0,
                  dddic = 0;
                nowcomp = -1;
                for (i = 0; i < compdatas.length; i++) {
                  if (
                    val.pt_inv_taxnum == compdatas[i].platetaxnum &&
                    compdatas[i].status == 1
                  ) {
                    nowcomp = i;
                    break;
                  }
                }
                if (nowcomp == -1) {
                  ElMessage.error({
                    message: "未能获取开票公司信息，请联系管理员！！！！",
                    duration: 2000,
                  });
                  return;
                }

                tpinvapphead = {
                  sid: "",
                  row_code: uuidv4().replace(/-/g, ""),
                  interface_code: uuidv4().replace(/-/g, ""),
                  buyername: val.buyer_inv_name,
                  buyertaxnum: val.buyer_inv_taxnum,
                  buyeraddress: val.buyer_inv_address,
                  buyeraccount: val.buyer_inv_account,
                  spid: compdatas[i].plateid,
                  doc_date: Date.now(),
                  inv_data: 0,
                  status: 0,
                  request: "",
                  tax_code: "",
                  tax_num: "",
                  tax_info: "",
                  total_amount: 0,
                  inv_type: val.invotype,
                  inv_direction: 1,
                  reconciliation_code: this.thisdata.row_code,
                  remark: val.remark,
                  source: 1,
                  authdepart: "",
                  platename: compdatas[i].platename,
                  taxlv: val.taxlv,
                  email: val.email,
                };
                console.log("asdasda", [
                  tpinvapphead,
                  tpinvappdtl,
                  totalamount,
                ]);

                ddamount = Number(val.totalamount);
                ddfree = Number(val.totalfree);
                dddic = Number(val.totaldis);

                if (ddamount - ddfree - dddic < 0) {
                  ElMessage.error({
                    message:
                      "发票金额为负数，为红字发票。红字发票需在发票列表查找对应蓝字发票后参照开具，开票完成后通过引入发票功能绑定至对账单！！！！",
                    duration: 5000,
                  });
                  return;
                }

                var fpxe = -1; //发票限额，-1为不限制
                if (val.invotype == "007") {
                  fpxe = Number(compdatas[i].invoiceinfo_pp) * 100;
                }

                if (val.invotype == "004") {
                  fpxe = Number(compdatas[i].invoiceinfo_zp) * 100;
                }

                if (
                  val.taxlv == -1 &&
                  (val.invotype == "004" ||
                    val.invotype == "020" ||
                    compdatas[i].invoicetype_v == 1)
                ) {
                  console.log("sdsdsd", [
                    val.taxlv,
                    val.invotype,
                    val.invotype,
                    compdatas[i].invoicetype_v,
                  ]);
                  ccerr = "err";
                  return;
                }

                //合并明细
                var tphbdtls = Array();

                this.invoappsedit.dtls.map((ttvar) => {
                  if (ttvar.h_row_code != val.row_code) {
                    return;
                  }
                  var hasrow = false;
                  for (var jji = 0; jji < tphbdtls.length; jji++) {
                    if (
                      tphbdtls[jji].ivocegoodsname == ttvar.ivocegoodsname &&
                      tphbdtls[jji].sec_unit == ttvar.sec_unit &&
                      tphbdtls[jji].ivocev_spec == ttvar.ivocev_spec
                    ) {
                      console.log("sss", [
                        tphbdtls[jji].reconciliation_amount,
                        ttvar.reconciliation_amount,
                      ]);
                      tphbdtls[jji].reconciliation_amount =
                        Number(tphbdtls[jji].reconciliation_amount) +
                        Number(ttvar.reconciliation_amount);
                      tphbdtls[jji].reconciliation_num =
                        Number(tphbdtls[jji].reconciliation_num) +
                        Number(ttvar.reconciliation_num);
                      tphbdtls[jji].main_num =
                        Number(tphbdtls[jji].main_num) + Number(ttvar.main_num);
                      tphbdtls[jji].sale_amount =
                        Number(tphbdtls[jji].sale_amount) +
                        Number(ttvar.sale_amount);
                      tphbdtls[jji].sec_num =
                        Number(tphbdtls[jji].sec_num) + Number(ttvar.sec_num);
                      hasrow = true;
                    }
                  }
                  if (!hasrow) {
                    tphbdtls.push(JSON.parse(JSON.stringify(ttvar)));
                  }
                });
                console.log("sdlkjlhqwed3", [
                  tphbdtls,
                  ccerr,
                  ddamount,
                  ddfree,
                  dddic,
                  fpxe,
                  val,
                ]);
                //生成发票数据
                var tpinvodtls = Array();
                var tptotala = 0;
                var huuid = uuidv4().replace(/-/g, "");
                var clttamount = 0;
                var clttfree = 0;
                var clttdis = 0;

                tphbdtls.map((valtpdt) => {
                  var dqclamount = Number(valtpdt.reconciliation_amount);
                  var dqclnum = Number(valtpdt.reconciliation_num);
                  var dqclfree = Math.round(
                    (Number(valtpdt.reconciliation_amount) / ddamount) * ddfree
                  );
                  var dqcldis = Math.round(
                    (Number(valtpdt.reconciliation_amount) / ddamount) * dddic
                  );
                  var bcxramount, bcxrnum, bcxrfree, bcxrdis;
                  console.log("chli", {
                    valtpdt: valtpdt,
                    dqclamount: dqclamount,
                    dqclfree: dqclfree,
                    dqcldis: dqcldis,
                  });
                  while (dqclamount != 0) {
                    console.log("chuli", dqclamount);
                    if (
                      tptotala + dqclamount - dqclfree - dqcldis < fpxe ||
                      fpxe == -1
                    ) {
                      tpinvodtls.push({
                        row_code: uuidv4().replace(/-/g, ""),
                        h_row_code: huuid,
                        goodsname: valtpdt.ivocegoodsname,
                        unit: valtpdt.sec_unit,
                        num: dqclnum,
                        taxlv: valtpdt.tax_rait,
                        notaxamount: 0,
                        tax: 0,
                        tax_code: val.taxcode,
                        goodspec: valtpdt.ivocev_spec,
                        taxamount: dqclamount - dqclfree,
                        taxdiscont: dqcldis,
                      });
                      clttamount += dqclamount;
                      clttfree += dqclfree;
                      clttdis += dqcldis;
                      tptotala += dqclamount - dqclfree - dqcldis;
                      console.log("chulixx1", {
                        dqclamount: dqclamount,
                        dqclfree: dqclfree,
                        dqcldis: dqcldis,
                        tptotala: tptotala,
                      });
                      dqclamount -= dqclamount;
                      dqclnum -= dqclnum;
                      dqclfree -= dqclfree;
                      dqcldis -= dqcldis;
                    } else {
                      if (fpxe - tptotala < 50000) {
                        //当前发票可开具金额小于500元，另起一张
                        console.log("chulixx2", {
                          dqclamount: dqclamount,
                          fpxe: fpxe,
                          tptotala: tptotala,
                        });
                        invodata.push({
                          sid: "",
                          row_code: huuid,
                          interface_code: uuidv4().replace(/-/g, ""),
                          buyername: tpinvapphead.buyername,
                          buyertaxnum: tpinvapphead.buyertaxnum,
                          buyeraddress: tpinvapphead.buyeraddress,
                          buyeraccount: tpinvapphead.buyeraccount,
                          spid: tpinvapphead.spid,
                          doc_date: tpinvapphead.doc_date,
                          inv_data: 0,
                          status: 0,
                          request: tpinvapphead.request,
                          tax_code: tpinvapphead.tax_code,
                          tax_num: tpinvapphead.tax_num,
                          tax_info: tpinvapphead.tax_info,
                          total_amount: tptotala,
                          inv_type: tpinvapphead.inv_type,
                          inv_direction: tpinvapphead.inv_direction,
                          reconciliation_code: tpinvapphead.reconciliation_code,
                          remark: tpinvapphead.remark,
                          source: tpinvapphead.source,
                          authdepart: tpinvapphead.authdepart,
                          platename: tpinvapphead.platename,
                          taxlv: tpinvapphead.taxlv,
                          taxcode: val.taxcode,
                          dtls: tpinvodtls,
                          email: tpinvapphead.email,
                        });
                        huuid = uuidv4().replace(/-/g, "");
                        tptotala = 0;
                        tpinvodtls = Array();
                      } else {
                        bcxramount = dqclamount;
                        bcxrnum = dqclnum;
                        bcxrfree = dqclfree;
                        bcxrdis = dqcldis;
                        var needcf = true;
                        while (needcf) {
                          bcxramount = Math.round(bcxramount / 2);
                          bcxrnum = Math.round(bcxrnum / 2);
                          bcxrfree = Math.round(bcxrfree / 2);
                          bcxrdis = Math.round(bcxrdis / 2);
                          if (
                            tptotala + bcxramount - bcxrfree - bcxrdis <
                            fpxe
                          ) {
                            needcf = false;
                          }
                        }
                        tpinvodtls.push({
                          row_code: uuidv4().replace(/-/g, ""),
                          h_row_code: huuid,
                          goodsname: valtpdt.ivocegoodsname,
                          unit: valtpdt.sec_unit,
                          num: bcxrnum,
                          taxlv: valtpdt.tax_rait,
                          notaxamount: 0,
                          tax: 0,
                          tax_code: val.taxcode,
                          goodspec: valtpdt.ivocev_spec,
                          taxamount: bcxramount - bcxrfree,
                          taxdiscont: bcxrdis,
                        });
                        dqclamount -= bcxramount;
                        dqclnum -= bcxrnum;
                        dqclfree -= bcxrfree;
                        dqcldis -= bcxrdis;
                        tptotala += bcxramount - bcxrfree - bcxrdis;
                        clttamount += bcxramount;
                        clttfree += bcxrfree;
                        clttdis += bcxrdis;
                        console.log("chulixx3", {
                          tptotala: tptotala,
                          bcxramount: bcxramount,
                          bcxrfree: bcxrfree,
                          bcxrdis: bcxrdis,
                        });
                        invodata.push({
                          sid: "",
                          row_code: huuid,
                          interface_code: uuidv4().replace(/-/g, ""),
                          buyername: tpinvapphead.buyername,
                          buyertaxnum: tpinvapphead.buyertaxnum,
                          buyeraddress: tpinvapphead.buyeraddress,
                          buyeraccount: tpinvapphead.buyeraccount,
                          spid: tpinvapphead.spid,
                          doc_date: tpinvapphead.doc_date,
                          inv_data: 0,
                          status: 0,
                          request: tpinvapphead.request,
                          tax_code: tpinvapphead.tax_code,
                          tax_num: tpinvapphead.tax_num,
                          tax_info: tpinvapphead.tax_info,
                          total_amount: tptotala,
                          inv_type: tpinvapphead.inv_type,
                          inv_direction: tpinvapphead.inv_direction,
                          reconciliation_code: tpinvapphead.reconciliation_code,
                          remark: tpinvapphead.remark,
                          source: tpinvapphead.source,
                          authdepart: tpinvapphead.authdepart,
                          platename: tpinvapphead.platename,
                          taxlv: tpinvapphead.taxlv,
                          taxcode: val.taxcode,
                          dtls: tpinvodtls,
                          email: tpinvapphead.email,
                        });
                        huuid = uuidv4().replace(/-/g, "");
                        tptotala = 0;
                        tpinvodtls = Array();
                      }
                    }
                  }
                });

                invodata.push({
                  sid: "",
                  row_code: huuid,
                  interface_code: uuidv4().replace(/-/g, ""),
                  buyername: tpinvapphead.buyername,
                  buyertaxnum: tpinvapphead.buyertaxnum,
                  buyeraddress: tpinvapphead.buyeraddress,
                  buyeraccount: tpinvapphead.buyeraccount,
                  spid: tpinvapphead.spid,
                  doc_date: tpinvapphead.doc_date,
                  inv_data: 0,
                  status: 0,
                  request: tpinvapphead.request,
                  tax_code: tpinvapphead.tax_code,
                  tax_num: tpinvapphead.tax_num,
                  tax_info: tpinvapphead.tax_info,
                  total_amount: tptotala,
                  inv_type: tpinvapphead.inv_type,
                  inv_direction: tpinvapphead.inv_direction,
                  reconciliation_code: tpinvapphead.reconciliation_code,
                  remark: tpinvapphead.remark,
                  source: tpinvapphead.source,
                  authdepart: tpinvapphead.authdepart,
                  platename: tpinvapphead.platename,
                  taxlv: tpinvapphead.taxlv,
                  taxcode: val.taxcode,
                  dtls: tpinvodtls,
                  email: tpinvapphead.email,
                });
                huuid = uuidv4().replace(/-/g, "");
                tptotala = 0;
                tpinvodtls = Array();

                //检查差异
                if (
                  clttamount != ddamount ||
                  clttfree != ddfree ||
                  clttdis != dddic
                ) {
                  console.log("caldsljasld ", "");
                }
              });
              console.log("sdkjhkasd4", { invodata: invodata });

              //处理完毕

              postback(
                "/" + this.dialoginfo.modlecode + "/saveinvos",
                {
                  data: {
                    invos: invodata,
                    row_code: this.thisdata.row_code,
                    type: "makemy",
                  },
                },
                this.savemakeinvocall
              );

              // axios
              //   .post(
              //     "/" + this.dialoginfo.modlecode + "/saveinvos",
              //     {
              //       data: {
              //         invos: invohead,
              //         row_code: this.thisdata.row_code,
              //         runopuu: response,
              //         type: "makemy",
              //       },
              //     },
              //     { timeout: 15000 }
              //   )
              //   .then(() => {})
              //   .catch(() => {
              //     // this.$bus.$emit("hideloading");
              //   });
            });

          // //this.thisdata.invos = [];
          // //this.thisdata.totalinvoamount = this.finallnum(0);
          // //this.thisdata.totalinvoamountnum = 0;
          // var invoappdata = Array();
          // var tpinvapphead = Array();
          // var tpinvappdtl = Array();
          // //var totalamount = 0;
          // this.invoappsedit.head.map((val) => {
          //   tpinvapphead = Array();
          //   tpinvappdtl = Array();
          //   var disrat = 0;
          //   if (val.totalamount != 0) {
          //     disrat = val.totalfree / val.totalamount / 100;
          //   }
          //   tpinvapphead = {
          //     sid: "",
          //     row_code: val.row_code,
          //     h_row_code: this.thisdata.row_code,
          //     tax_plate_code: val.ptcode,
          //     buyer_inv_name: val.buyer_inv_name,
          //     buyer_inv_code: "",
          //     buyer_inv_taxnum: val.buyer_inv_taxnum,
          //     buyer_inv_address: val.buyer_inv_address,
          //     buyer_inv_account: val.buyer_inv_account,
          //     total_amount: val.totalamount - val.totalfree,
          //     discount_ratio: disrat,
          //     taxlv: val.taxlv,
          //     inv_type: val.invotype,
          //     contacts_code: this.thisdata.contacts_code,
          //     way_type: 0,
          //     status: 0,
          //     email: val.email,
          //     post_data: 0,
          //     remark: val.remark,
          //     platename: val.pt_inv_name,
          //     taxcode: val.taxcode,
          //   };
          //   var goodslist = Array();
          //   var unitlist = Array();
          //   var totalfree = 0;
          //   this.invoappsedit.dtls.map((value) => {
          //     if (value.h_row_code != val.row_code) {
          //       return;
          //     }
          //     var needadd = true;
          //     var nownum = 0;
          //     goodslist.map((goods, xxii) => {
          //       if (
          //         goods == value.ivocegoodsname &&
          //         unitlist[xxii] == value.sec_unit
          //       ) {
          //         needadd = false;
          //         nownum = xxii;
          //         return;
          //       }
          //     });
          //     if (needadd) {
          //       goodslist.push(value.ivocegoodsname);
          //       unitlist.push(value.sec_unit);
          //       nownum = goodslist.length - 1;
          //       tpinvappdtl.push({
          //         sid: "",
          //         row_code: value.row_code,
          //         h_row_code: val.row_code,
          //         goods_code: value.goods_code,
          //         goods_name: value.goodsname,
          //         inv_goods_name: value.ivocegoodsname,
          //         sale_num: 0,
          //         sale_unit: value.sec_unit,
          //         main_num: 0,
          //         amount: 0,
          //         taxlv: value.tax_rait,
          //         discount_amount: 0,
          //         inv_goods_spec: value.ivocev_spec,
          //         v_name: value.goodsname,
          //       });
          //     }
          //     tpinvappdtl[nownum].sale_num += parseInt(value.reconciliation_num);
          //     tpinvappdtl[nownum].main_num += parseInt(value.main_num);
          //     tpinvappdtl[nownum].amount += parseInt(value.reconciliation_amount);
          //     tpinvappdtl[nownum].discount_amount += Math.round(
          //       (parseInt(value.reconciliation_amount) * val.totalfree) /
          //         val.totalamount
          //     );
          //     totalfree += Math.round(
          //       (parseInt(value.reconciliation_amount) * val.totalfree) /
          //         val.totalamount
          //     );
          //   });
          //   if (totalfree != val.totalfree) {
          //     var opn = 1;
          //     var ttd = 0;
          //     if (totalfree > val.totalfree) {
          //       opn = -1;
          //       ttd = totalfree - val.totalfree;
          //     } else {
          //       opn = 1;
          //       ttd = -totalfree + val.totalfree;
          //     }
          //     var i = 0;
          //     var runcount = 0;
          //     while (ttd > 0 && runcount < 50) {
          //       runcount++;
          //       if (i == tpinvappdtl.length) {
          //         i = 0;
          //       }
          //       if (tpinvappdtl[i].discount_amount + opn >= 0) {
          //         tpinvappdtl[i].discount_amount += opn;
          //         ttd--;
          //       }
          //       i++;
          //     }
          //   }

          //   tpinvapphead.dtl = tpinvappdtl;
          //   invoappdata.push(tpinvapphead);
          // });
          // this.thisdata.invoapps.map((val) => {
          //   if (val.sid != "") {
          //     this.thisdata.detelinvoapps.push(val);
          //   }
          // });

          // this.$bus.$emit("showloading");
          // this.$bus.$emit("showloading");

          // axios
          //   .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          //     data: { type: "get" },
          //   })
          //   .then((response) => {
          //     console.log("response", response);
          //     axios
          //       .post(
          //         "/" + this.dialoginfo.modlecode + "/saveinvoapps",
          //         {
          //           data: {
          //             invoapps: invoappdata,
          //             row_code: this.thisdata.row_code,
          //             runopuu: response,
          //           },
          //         },
          //         { timeout: 15000 }
          //       )
          //       .then(() => {})
          //       .catch(() => {
          //         // this.$bus.$emit("hideloading");
          //       });

          //     this.$bus.$emit("showloading");
          //     this._getopstatus = setTimeout(() => {
          //       this.checkopstatus(response, "invoappsave");
          //     }, 2000);
          //   })
          //   .catch(() => {
          //     this.$bus.$emit("hideloading");
          //   });

          // console.log("sdsadgahsdasdasdasdasd", this.thisdata.invoapps);
          // totalamount = 0;
          // this.thisdata.invoapps.map((val) => {
          //   val.dtl.map((val2) => {
          //     totalamount +=
          //       parseInt(val2.amount) - parseInt(val2.discount_amount);
          //   });
          // });

          // this.thisdata.totalinvoappnum = totalamount;
          // this.remathdata();
          // this.addinvoappvisiable = false;
        })
        .catch(() => {});
      this.$bus.$emit("hideloading");
    },
    clearaddadfreedata() {
      this.addadfreesdata = {
        discount: "",
        v_name: "",
        time: "",
        remark: "",
        number: 0,
        atremark: "",
      };
    },
    savebfdadfreedata() {
      if (this.addadfreesdata.number == "" || this.addadfreesdata.number == 0) {
        ElMessage.error({
          message: "折扣金额不可为0或空白，请填写！！！！！",
          duration: 2000,
        });
        return;
      }
      this.$bus.$emit("showloading");
      var uuidstrin = uuidv4().replace(/-/g, "");
      var myva = Array();
      myva.push({
        sid: "",
        row_code: uuidstrin,
        h_row_code: this.thisdata.row_code,
        bill_code: "",
        bill_free_code: "",
        discount_code: this.addadfreesdata.discount,
        goods_code: "",
        sec_unit: "",
        remark: this.addadfreesdata.atremark,
        sec_num: "0",
        free_ftype: "2",
        main_unit: "",
        main_num: "0",
        sale_amount: this.addadfreesdata.number * 100,
        reconciliation_amount: this.addadfreesdata.number * 100,
        free_dtltype: "2",
        v_name: this.addadfreesdata.v_name,
        discountremark: this.addadfreesdata.remark,
        dtltype: "2",
        single_num: "",
        doc_date: 0,
        post_date: 0,
        buyer_order_num: "",
        billremark: "",
        buyernae: "",
      });

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/savefrees",
              {
                data: {
                  frees: myva,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "freesave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    saveaddadfreedata() {
      if (this.addadfreesdata.number == "" || this.addadfreesdata.number == 0) {
        ElMessage.error({
          message: "折扣金额不可为0或空白，请填写！！！！！",
          duration: 2000,
        });
        return;
      }
      this.$bus.$emit("showloading");
      var uuidstrin = uuidv4().replace(/-/g, "");
      var myva = Array();
      myva.push({
        sid: "",
        row_code: uuidstrin,
        h_row_code: this.thisdata.row_code,
        bill_code: "",
        bill_free_code: "",
        discount_code: this.addadfreesdata.discount,
        goods_code: "",
        sec_unit: "",
        remark: this.addadfreesdata.atremark,
        sec_num: "0",
        free_ftype: "1",
        main_unit: "",
        main_num: "0",
        sale_amount: this.addadfreesdata.number * 100,
        reconciliation_amount: this.addadfreesdata.number * 100,
        free_dtltype: "2",
        v_name: this.addadfreesdata.v_name,
        discountremark: this.addadfreesdata.remark,
        dtltype: "2",
        single_num: "",
        doc_date: 0,
        post_date: 0,
        buyer_order_num: "",
        billremark: "",
        buyernae: "",
      });

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/savefrees",
              {
                data: {
                  frees: myva,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "freesave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    addadfreesdatadiscountcg(val) {
      // {discount:'',v_name:'',time:'',remark:''},
      if (val == null) {
        this.addadfreesdata.discount = "";
        this.addadfreesdata.v_name = "";
        this.addadfreesdata.remark = "";
        this.addadfreesdata.time = "";
        return;
      }
      this.addadfreesdata.discount = val.row_code;
      this.addadfreesdata.v_name = val.v_name;
      this.addadfreesdata.remark = val.remark;
      this.addadfreesdata.time =
        this.datetimeformart(val.begintime) +
        " 至 " +
        this.datetimeformart(val.endtime);
      console.log("addadfreesdatadiscount", {
        val: val,
        addl: this.addadfreesdata,
      });
    },
    billsselechange(val) {
      this.billsseleed = val;
    },
    freesselechange(val) {
      this.freesseleed = val;
    },
    addfreetableselechange(val) {
      if (!this.addfreevisiable) {
        return;
      }
      var check = true;
      for (var i = 0; i < this.addfreesnowpageselectdata.length; i++) {
        check = false;
        for (var j = 0; j < val.length; j++) {
          if (
            this.addfreesnowpageselectdata[i].bill_free_code ==
            val[j].bill_free_code
          ) {
            check = true;
            break;
          }
        }
        if (!check) {
          for (j = 0; j < this.addfreesselectdata.length; j++) {
            if (
              this.addfreesnowpageselectdata[i].bill_free_code ==
              this.addfreesselectdata[j].bill_free_code
            ) {
              this.addfreetotalamount -=
                parseInt(this.addfreesselectdata[j].reconciliation_amount) /
                100;
              //console.log('remove data', this.addbullsselectdata[j])
              this.addfreesselectdata.splice(j, 1);
              break;
            }
          }
        }
      }
      this.addfreesnowpageselectdata = JSON.parse(JSON.stringify(val));

      for (i = 0; i < this.addfreesnowpageselectdata.length; i++) {
        check = false;
        for (j = 0; j < this.addfreesselectdata.length; j++) {
          if (
            this.addfreesnowpageselectdata[i].bill_free_code ==
            this.addfreesselectdata[j].bill_free_code
          ) {
            check = true;
            break;
          }
        }
        if (!check) {
          this.addfreetotalamount +=
            parseInt(this.addfreesnowpageselectdata[i].reconciliation_amount) /
            100;
          console.log("add data", this.addfreetotalamount);
          this.addfreesselectdata.push(this.addfreesnowpageselectdata[i]);
        }
      }
      this.addfreetotalamountstr = this.addfreetotalamount.toFixed(2);
    },
    addbilltableselechange(val) {
      if (!this.addbillvisiable) {
        return;
      }
      var check = true;
      for (var i = 0; i < this.addbullsnowpageselectdata.length; i++) {
        check = false;
        for (var j = 0; j < val.length; j++) {
          if (
            this.addbullsnowpageselectdata[i].bill_dtl_code ==
            val[j].bill_dtl_code
          ) {
            check = true;
            break;
          }
        }
        if (!check) {
          console.log("check false");
          for (j = 0; j < this.addbullsselectdata.length; j++) {
            if (
              this.addbullsnowpageselectdata[i].bill_dtl_code ==
              this.addbullsselectdata[j].bill_dtl_code
            ) {
              this.addbilltotalamount -=
                parseInt(this.addbullsselectdata[j].sale_amount) / 100;
              //console.log('remove data', this.addbullsselectdata[j])
              this.addbullsselectdata.splice(j, 1);
              break;
            }
          }
        }
      }

      this.addbullsnowpageselectdata = JSON.parse(JSON.stringify(val));
      for (i = 0; i < this.addbullsnowpageselectdata.length; i++) {
        check = false;
        for (j = 0; j < this.addbullsselectdata.length; j++) {
          if (
            this.addbullsnowpageselectdata[i].bill_dtl_code ==
            this.addbullsselectdata[j].bill_dtl_code
          ) {
            check = true;
            break;
          }
        }
        if (!check) {
          this.addbilltotalamount +=
            parseInt(this.addbullsnowpageselectdata[i].sale_amount) / 100;
          //console.log('add data', this.addbullsnowpageselectdata[j])
          this.addbullsselectdata.push(this.addbullsnowpageselectdata[i]);
        }
      }
      var totalfreetp = 0;
      this.addbullsnowpageselectdata.map((val) => {
        totalfreetp += val.main_remain_amount_edit;
      });
      this.addbilltotalamountstr = (totalfreetp / 100).toFixed(2);
    },
    addfreeswarehousecg(val) {
      this.addfreesearchdata.warehouse = [];
      for (var i = 0; i < val.length; i++) {
        this.addfreesearchdata.warehouse.push(val[i].row_code);
      }
    },
    addbillswarehousecg(val) {
      this.addbillsearchdata.warehouse = [];
      for (var i = 0; i < val.length; i++) {
        this.addbillsearchdata.warehouse.push(val[i].row_code);
      }
    },
    addfreesbuyerinforcg(val) {
      this.addfreesearchdata.buyerinfor = [];
      for (var i = 0; i < val.length; i++) {
        this.addfreesearchdata.buyerinfor.push(val[i].row_code);
      }
    },
    addbillsbuyerinforcg(val) {
      this.addbillsearchdata.buyerinfor = [];
      for (var i = 0; i < val.length; i++) {
        this.addbillsearchdata.buyerinfor.push(val[i].row_code);
      }
    },
    addfreesdepartmentcg(val) {
      this.addfreesearchdata.department = [];
      for (var i = 0; i < val.length; i++) {
        this.addfreesearchdata.department.push(val[i].value);
      }
    },
    addbillsdepartmentcg(val) {
      this.addbillsearchdata.department = [];
      for (var i = 0; i < val.length; i++) {
        this.addbillsearchdata.department.push(val[i].value);
      }
      //console.log(this.addbillsearchdata.department);
    },
    addfreesdataareachange_doctimes(val) {
      if (val != null) {
        this.addfreesearchdata.doctimes[0] = val[0].getTime();
        this.addfreesearchdata.doctimes[1] = val[1].getTime() + 86399999;
      } else {
        this.addfreesearchdata.doctimes = ["", ""];
      }
    },
    addbillsdataareachange_doctimes(val) {
      if (val != null) {
        this.addbillsearchdata.doctimes[0] = val[0].getTime();
        this.addbillsearchdata.doctimes[1] = val[1].getTime() + 86399999;
      } else {
        this.addbillsearchdata.doctimes = ["", ""];
      }
    },
    addfreesdataareachange_posttimes(val) {
      if (val != null) {
        this.addfreesearchdata.posttimes[0] = val[0].getTime();
        this.addfreesearchdata.posttimes[1] = val[1].getTime() + 86399999;
      } else {
        this.addfreesearchdata.posttimes = ["", ""];
      }
    },
    addbullsdataareachange_posttimes(val) {
      if (val != null) {
        this.addbillsearchdata.posttimes[0] = val[0].getTime();
        this.addbillsearchdata.posttimes[1] = val[1].getTime() + 86399999;
      } else {
        this.addbillsearchdata.posttimes = ["", ""];
      }
    },
    addfreesviewsele() {
      this.addfreetype = 2;
      this.addfreesflashdata();
    },
    addbillsviewsele() {
      this.addbilltype = 2;
      this.addbillsflashdata();
    },
    addfreesseacrchstart() {
      this.addfreetype = 1;
      this.addfreesflashdata();
    },
    addbillsseacrchstart() {
      this.addbilltype = 1;
      this.addbillsflashdata();
    },
    resetfreesearch() {
      this.addfreetype = 1;
      this.addfreesearchdata = {
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
      };

      this.addfreesflashdata();
    },
    resetbillsearch() {
      this.addbilltype = 1;
      this.addbillsearchdata = {
        goodesinfor: "",
        buyerif: "",
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
        filesselect: "",
      };

      this.addbillsflashdata();
    },
    addfreeshandleSizeChange(val) {
      let pgs = Math.ceil(this.addfreestotalrow / val);
      this.addfreespagesize = val;
      if (pgs >= this.addfreespagenow) {
        this.addfreesflashdata();
      }
    },
    addbillshandleSizeChange(val) {
      let pgs = Math.ceil(this.addbillstotalrow / val);
      this.addbillspagesize = val;
      if (pgs >= this.addbillspagenow) {
        this.addbillsflashdata();
      }
    },
    addfreeshandleCurrentChange(val) {
      this.addfreespagenow = val;
      this.addfreesflashdata();
    },
    addbillshandleCurrentChange(val) {
      this.addbillspagenow = val;
      this.addbillsflashdata();
    },
    addfreesflashdata() {
      this.$bus.$emit("showloading");
      this.addfreesnowpageselectdata = [];
      this.$refs.addfreestables.clearSelection();
      if (this.addfreetype == 1) {
        axios
          .post("/" + this.dialoginfo.modlecode + "/getaddfreesdata", {
            data: {
              searchdata: this.addfreesearchdata,
              pageSize: this.addfreespagesize,
              pagenow: this.addfreespagenow,
              contantcode: this.thisdata.contacts_code,
              rowcode: this.thisdata.row_code,
              havbills: this.thisdata.bills,
              havfrees: this.thisdata.frees,
            },
          })
          .then((required) => {
            this.freessearchresults = required.rows;
            this.addfreestotalrow = required.total;
            for (var i = 0; i < this.freessearchresults.length; i++) {
              for (var j = 0; j < this.addfreesselectdata.length; j++) {
                if (
                  this.addfreesselectdata[j].bill_free_code ==
                  this.freessearchresults[i].bill_free_code
                ) {
                  this.$refs.addfreestables.toggleRowSelection(
                    this.freessearchresults[i],
                    true
                  );
                  this.addfreesnowpageselectdata.push(
                    this.freessearchresults[i]
                  );
                  break;
                }
              }
            }
          })
          .catch(() => {});
      } else {
        var tplist = Array();
        var past = 0;
        for (var i = 0; i < this.addfreespagesize; i++) {
          past = (this.addfreespagenow - 1) * this.addfreespagesize + i;
          if (past >= this.addfreesselectdata.length) {
            break;
          }
          tplist.push(this.addfreesselectdata[past]);
        }
        this.freessearchresults = tplist;
        this.addfreestotalrow = this.addfreesselectdata.length;
        for (i = 0; i < this.freessearchresults.length; i++) {
          for (var j = 0; j < this.addfreesselectdata.length; j++) {
            if (
              this.addfreesselectdata[j].bill_free_code ==
              this.freessearchresults[i].bill_free_code
            ) {
              this.$refs.addfreestables.toggleRowSelection(
                this.freessearchresults[i],
                true
              );
              this.addfreesnowpageselectdata.push(this.freessearchresults[i]);
              break;
            }
          }
        }
      }
      this.$bus.$emit("hideloading");
    },
    addbillsfalshdatacallback(request) {
      // console.log("callbackdata", request);
      request = JSON.parse(request);
      console.log("ss", { sss: request.rescode, adjhg: request.data });
      if (request.rescode == "0") {
        request = request.data;
        console.log("stpp");
        this.billssearchresults.rows = request.rows;
        this.addbillstotalrow = request.total;
        for (var i = 0; i < this.billssearchresults.rows.length; i++) {
          for (var j = 0; j < this.addbullsselectdata.length; j++) {
            if (
              this.addbullsselectdata[j].bill_dtl_code ==
              this.billssearchresults.rows[i].bill_dtl_code
            ) {
              this.$refs.addbillstables.toggleRowSelection(
                this.billssearchresults.rows[i],
                true
              );
              this.addbullsnowpageselectdata.push(
                this.billssearchresults.rows[i]
              );
              break;
            }
          }
        }
      } else {
        ElMessage.error({
          message: request.resmsg,
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
      }
      this.$bus.$emit("hideloading");
    },
    addbillsflashdata() {
      this.$bus.$emit("showloading");
      this.addbullsnowpageselectdata = [];
      this.$refs.addbillstables.clearSelection();
      if (this.addbilltype == 1) {
        postback(
          "/" + this.dialoginfo.modlecode + "/getaddbillsdata",
          {
            searchdata: this.addbillsearchdata,
            pageSize: this.addbillspagesize,
            pagenow: this.addbillspagenow,
            contantcode: this.thisdata.contacts_code,
            rowcode: this.thisdata.row_code,
            havbills: this.thisdata.bills,
          },
          this.addbillsfalshdatacallback
        );
        // axios
        //   .post("/" + this.dialoginfo.modlecode + "/getaddbillsdata", {
        //     data: {
        //       searchdata: this.addbillsearchdata,
        //       pageSize: this.addbillspagesize,
        //       pagenow: this.addbillspagenow,
        //       contantcode: this.thisdata.contacts_code,
        //       rowcode: this.thisdata.row_code,
        //       havbills: this.thisdata.bills,
        //     },
        //   })
        //   .then((required) => {
        //     this.billssearchresults.rows = required.rows;
        //     this.addbillstotalrow = required.total;
        //     for (var i = 0; i < this.billssearchresults.rows.length; i++) {
        //       for (var j = 0; j < this.addbullsselectdata.length; j++) {
        //         if (
        //           this.addbullsselectdata[j].bill_dtl_code ==
        //           this.billssearchresults.rows[i].bill_dtl_code
        //         ) {
        //           this.$refs.addbillstables.toggleRowSelection(
        //             this.billssearchresults.rows[i],
        //             true
        //           );
        //           this.addbullsnowpageselectdata.push(
        //             this.billssearchresults.rows[i]
        //           );
        //           break;
        //         }
        //       }
        //     }
        //   })
        //   .catch(() => {});
      } else {
        var tplist = Array();
        var past = 0;
        for (var i = 0; i < this.addbillspagesize; i++) {
          past = (this.addbillspagenow - 1) * this.addbillspagesize + i;
          if (past >= this.addbullsselectdata.length) {
            break;
          }
          tplist.push(this.addbullsselectdata[past]);
        }
        this.billssearchresults.rows = tplist;
        this.addbillstotalrow = this.addbullsselectdata.length;
        for (i = 0; i < this.billssearchresults.rows.length; i++) {
          for (var j = 0; j < this.addbullsselectdata.length; j++) {
            if (
              this.addbullsselectdata[j].bill_dtl_code ==
              this.billssearchresults.rows[i].bill_dtl_code
            ) {
              this.$refs.addbillstables.toggleRowSelection(
                this.billssearchresults.rows[i],
                true
              );
              this.addbullsnowpageselectdata.push(
                this.billssearchresults.rows[i]
              );
              break;
            }
          }
        }
        this.$bus.$emit("hideloading");
      }
    },
    remathdata() {
      //重算合计数
      this.thisdata.totalbill = this.finallnum(
        (this.thisdata.totalbillnum + this.thisdata.taildiff * 100) / 100
      );
      this.thisdata.totalfree = this.finallnum(
        this.thisdata.totalfreenun / 100
      );
      this.thisdata.totalinvo = this.finallnum(
        (this.thisdata.totalbillnum -
          this.thisdata.totalfreenun +
          this.thisdata.taildiff * 100) /
          100
      );
      if (this.thisdata.totalbillnum != 0) {
        this.thisdata.totalinvolv =
          this.finallnum(
            (this.thisdata.totalfreenun /
              (this.thisdata.totalbillnum + this.thisdata.taildiff * 100)) *
              100
          ) + "%";
      } else {
        this.thisdata.totalinvolv = "0.00%";
      }

      this.thisdata.totalinvoapp = this.finallnum(
        this.thisdata.totalinvoappnum / 100
      );
      this.thisdata.totalinvoamount = this.finallnum(
        this.thisdata.totalinvoamountnum / 100
      );
      //新版合计数计算
      var billsamx = 0;
      this.thisdata.bills.map((row) => {
        billsamx += row.reconciliation_amount;
      });
      this.ntotalnum.billsam = this.finallnum(billsamx / 100);
      this.ntotalnumint.billsam = billsamx;
      var frees1amx = 0;
      var frees2amx = 0;
      this.thisdata.frees.map((row) => {
        if (row.free_ftype == 1) {
          frees1amx += row.sale_amount;
        }
        if (row.free_ftype == 2) {
          frees2amx += row.sale_amount;
        }
      });

      this.ntotalnumint.freesam = frees2amx;
      this.ntotalnumint.discountam = frees1amx;
      this.ntotalnum.freesam = this.finallnum(frees2amx / 100);
      this.ntotalnum.discountam = this.finallnum(frees1amx / 100);
      this.ntotalnum.needinvoam = this.finallnum(
        (billsamx - frees1amx - frees2amx) / 100
      );
      if (billsamx != 0) {
        this.ntotalnum.dislv =
          this.finallnum((frees1amx / (billsamx - frees2amx)) * 100) + "%";
      } else {
        this.ntotalnum.dislv = "0.00%";
      }
      var invoamx = 0;
      this.thisdata.invos.map((row) => {
        invoamx += row.total_amount;
      });
      this.ntotalnum.invoam = this.finallnum(invoamx / 100);
      this.ntotalnumint.invoam = invoamx;
    },
    finallnum(val) {
      if (val == "" || val == null || val == 0) {
        return "0.00";
      }
      var num = val;
      num = num.toFixed(2);
      var fh = "";
      if (num.substring(0, 1) == "-") {
        fh = "-";
        num = num.substring(1);
      }
      var cutnum = 0;
      while (num.length > 6) {
        if (num.length % 3 != 0) {
          cutnum = num.length % 3;
          fh += num.substring(0, cutnum);
          fh += ",";
          num = num.substring(cutnum);
        } else {
          fh += num.substring(0, 3);
          fh += ",";
          num = num.substring(3);
        }
      }
      fh += num;
      return fh;
    },
    saveaddfreedata() {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      if (this.addfreesselectdata.length == 0) {
        this.addfreevisiable = false;
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        return;
      }

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/savefrees",
              {
                data: {
                  frees: this.addfreesselectdata,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "freesave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });

      // this.$bus.$emit("showloading");
      // this.addfreesselectdata.map((val) => {
      //   this.thisdata.frees.push(val);
      // });
      // this.thisdata.totalfreenun += parseInt(this.addfreetotalamount * 100);
      // this.remathdata();

      // this.addfreevisiable = false;
      // this.$bus.$emit("hideloading");
    },
    saveaddbilldata() {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      if (this.addbullsselectdata.length == 0) {
        this.addbillvisiable = false;
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        return;
      }

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/savebills",
              {
                data: {
                  bills: this.addbullsselectdata,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "billsave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });

      // axios
      //   .post("/" + this.dialoginfo.modlecode + "/savebills", {
      //     data: { bills: this.addbullsselectdata, row_code: this.thisdata.row_code },
      //   })
      //   .then(() => {
      //     this.addbullsselectdata.map((val) => {
      //       this.thisdata.bills.push(val);
      //       ttamount += parseInt(val.reconciliation_amount);
      //     });

      //     this.thisdata.totalbillnum += ttamount;

      //     this.remathdata();

      //     this.addbillvisiable = false;

      //     this.$bus.$emit("hideloading");
      //   })
      //   .catch(() => {

      //     this.$bus.$emit("hideloading");
      //   });
    },
    clearaddfreedata() {
      this.addfreesearchdata = {
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
      };
      this.addfreetotalamount = 0;
      this.addfreetotalamountstr = "0.00";
      this.addfreesselectdata = [];
      this.freessearchresults = [];
      this.addfreestotalrow = 0;
      this.addfreespagenow = 1;
      this.addfreespagesize = 50;

      console.log("clear addfreedata", this.addfreesselectdata);
    },
    clearaddbilldata() {
      this.addbillsearchdata = {
        doctimes: ["", ""],
        posttimes: ["", ""],
        docnum: "",
        department: [],
        buyerinfor: [],
        contacts: [],
        warehouse: [],
        minamount: 0,
        maxamount: 0,
        filesselect: "",
      };
      this.autoselectbills = 0;
      this.minamount = 0;
      this.maxamount = 0;
      this.addbilltotalamount = 0;
      this.addbilltotalamountstr = "0";
      this.addbullsselectdata = [];
      this.billssearchresults = [];
      this.addbillstotalrow = 0;
      this.addbillspagenow = 1;
      this.addbillspagesize = 50;
    },
    invoappview(val) {
      console.log("test", val);
    },
    dellbills() {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      if (this.billsseleed.length <= 0) {
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        return;
      }

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/deletbills",
              {
                data: {
                  bills: this.billsseleed,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "billsave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    dellbill(val) {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      var dellbills = Array();
      dellbills.push(val);

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/deletbills",
              {
                data: {
                  bills: dellbills,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "billsave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    addbillfree() {
      ElMessageBox.confirm(
        "即将引入账单明细对应的票折明细，确认执行本操作吗？？？"
      )
        .then(() => {
          this.$bus.$emit("showloading");
          this.$bus.$emit("showloading");
          this.$bus.$emit("showloading");

          axios
            .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
              data: { type: "get" },
            })
            .then((response) => {
              console.log("response", response);
              axios
                .post(
                  "/" + this.dialoginfo.modlecode + "/getaddfreesaddata",
                  {
                    data: {
                      havbills: this.thisdata.bills,
                      row_code: this.thisdata.row_code,
                      runopuu: response,
                    },
                  },
                  { timeout: 15000 }
                )
                .then(() => {})
                .catch(() => {
                  // this.$bus.$emit("hideloading");
                });

              this.$bus.$emit("showloading");
              this._getopstatus = setTimeout(() => {
                this.checkopstatus(response, "freesave");
              }, 2000);
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });

          //   axios
          //     .post("/" + this.dialoginfo.modlecode + "/getaddfreesaddata", {
          //       data: {
          //         havbills: this.thisdata.bills,
          //         havfrees: this.thisdata.frees,
          //         rowcode: this.thisdata.row_code,
          //       },
          //     })
          //     .then((required) => {
          //       required.map((val) => {
          //         this.thisdata.frees.push(val);
          //         this.thisdata.totalfreenun += parseInt(
          //           val.reconciliation_amount
          //         );
          //       });

          //       this.remathdata();
          //     })
          //     .catch(() => { });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    dellfrees() {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");

      if (this.freesseleed.length <= 0) {
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
        return;
      }

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/deletfrees",
              {
                data: {
                  frees: this.freesseleed,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "freesave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    dellfree(val) {
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      this.$bus.$emit("showloading");
      var dlllfress = Array();
      dlllfress.push(val);

      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/deletfrees",
              {
                data: {
                  frees: dlllfress,
                  row_code: this.thisdata.row_code,
                  runopuu: response,
                },
              },
              { timeout: 15000 }
            )
            .then(() => {})
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response, "freesave");
          }, 2000);
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    test() {
      console.log("test");
    },
    openaddbill() {
      this.addbillvisiable = true;
    },
    getSummaries(data) {
      const sums = [];
      var bcs = 0;
      var cs = 0;
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "商品名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "出库数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sec_num);
          });
          value = (value / 1000000).toFixed(2);
        }
        if (element.label == "开印数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sec_print_num);
          });
          value = (value / 1000000).toFixed(2);
        }
        if (element.label == "含税金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.sale_amount);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "票折金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.invoicefree);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "票外金额") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.outinvoicefree);
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "票折比率") {
          bcs = 0;
          cs = 0;
          data.data.forEach((row) => {
            bcs += Number(row.invoicefree);
            cs += Number(row.sale_amount);
          });
          if (cs == 0) {
            value = "0.00%";
          } else {
            value = ((bcs / cs) * 100).toFixed(2) + "%";
          }
        }
        if (element.label == "票外比率") {
          bcs = 0;
          cs = 0;
          data.data.forEach((row) => {
            bcs += Number(row.outinvoicefree);
            cs += Number(row.sale_amount);
          });
          if (cs == 0) {
            value = "0.00%";
          } else {
            value = ((bcs / cs) * 100).toFixed(2) + "%";
          }
        }
        sums[index] = value;
      });

      return sums;
    },

    upchecktime(val = true) {
      axios
        .post("/" + this.dialoginfo.modlecode + "/updatechecktime", {
          data: { opt: val, row_code: this.thisdata.row_code },
        })
        .then(() => {})
        .catch(() => {});
    },
    freetypeformatter(row, column, cellvalue) {
      if (cellvalue == 1) {
        return "票面折扣";
      }
      if (cellvalue == 2) {
        return "票外折扣";
      }
      return "数据错误";
    },

    getmounth(Value) {
      var data = new Date();
      if (Value != "") {
        data = new Date(parseInt(Value));
      }
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      console.log("getmounth", dateNumFun(data.getMonth() + 1));
      return dateNumFun(data.getMonth() + 1);
    },

    dateformart2(Value) {
      //console.log("datetimeformart", Value);
      if (Value == 0) {
        return "";
      }
      const date = new Date(parseInt(Value));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
      ];
      var rtdate = `${Y}-${M}-${D}`;
      return rtdate;
    },

    datetimeformart(Value) {
      //console.log("datetimeformart", Value);
      if (Value == 0) {
        return "";
      }
      const date = new Date(parseInt(Value));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D, h, m, s] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
        dateNumFun(date.getHours()),
        dateNumFun(date.getMinutes()),
        dateNumFun(date.getSeconds()),
      ];
      var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
      return rtdate;
    },
    dateformart(a, b, Value) {
      //if(a==null && b==null){console.log('')}
      if (Value == 0) {
        return "";
      }
      const date = new Date(parseInt(Value));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
      ];
      var rtdate = `${Y}-${M}-${D}`;
      return rtdate;
    },
    // deldata(val) {
    //     // if (val.sid > 0) {
    //     //     this.thisdata.deletdtl.push(val);
    //     // }
    //     // for (var i = 0; i < this.thisdata.dtl.length; i++) {
    //     //     if (this.thisdata.dtl[i].row_code == val.row_code) {
    //     //         this.thisdata.dtl.splice(i, 1)
    //     //         return
    //     //     }
    //     // }
    // },

    dtlsave() {
      this.$bus.$emit("showloading");

      this.$bus.$emit("hideloading");
    },
    cleardtldata() {
      console.log("closedtladd", this.thiseddata);
    },

    tableheadClass() {
      return "text-align:center";
    },
    getdata() {
      this.$bus.$emit("showloading");
      this.thisdata.postdatetime = this.datetimeformart(
        this.thisdata.post_date
      );
      this.thisdata.docdatetime = this.datetimeformart(this.thisdata.doc_date);
      axios
        .post("/" + this.dialoginfo.modlecode + "/getdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.upchecktime();
          this.thisdata = response;
          this.thisdata.taildiff = Number(this.thisdata.taildiff);
          this.visiable = true;
          this.remathdata();
          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          clearInterval(this._timer);
          this.$emit("dialogclose", {});
          this.$bus.$emit("hideloading");
        });
    },
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      clearInterval(this._timer);
      this.upchecktime(false);
      this.$emit("dialogclose", {});
    },
    checkopstatus(val, val2) {
      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "search", row_code: val },
        })
        .then((response) => {
          console.log("opstatus", response);
          if (response.status == "1") {
            this._getopstatus = setTimeout(() => {
              this.checkopstatus(val, val2);
            }, 2000);
          }
          if (response.status == "3") {
            console.log("asdkjwn", val2);
            var dtt;
            if (val2 == "billsave") {
              dtt = JSON.parse(response.log);
              console.log("cackt optt", dtt);
              this.thisdata.totalbillnum = dtt.totalamount;
              this.thisdata.bills = dtt.rows;
              this.remathdata();
              this.addbillvisiable = false;
            }
            if (val2 == "freesave") {
              dtt = JSON.parse(response.log);
              console.log("cackt optt", dtt);
              this.thisdata.totalfreenun = dtt.totalamount;
              this.thisdata.frees = dtt.rows;
              this.remathdata();
              this.addfreevisiable = false;
              this.addadfreevisiable = false;
              this.addbffreevisiable = false;
            }

            if (val2 == "invoappsave") {
              dtt = JSON.parse(response.log);
              this.thisdata.totalinvoappnum = dtt.apptotalamount;
              this.thisdata.invoapps = dtt.approws;
              this.thisdata.invos = dtt.rows;
              this.thisdata.totalinvoamountnum = dtt.totalamount;
              this.remathdata();
              this.addinvoappvisiable = false;
            }

            if (val2 == "invosave") {
              dtt = JSON.parse(response.log);
              this.thisdata.invos = dtt.rows;
              this.thisdata.totalinvoamountnum = dtt.totalamount;
              this.remathdata();
              this.pullinvovisable = false;
            }
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
            this.$bus.$emit("hideloading");
          }
          if (response.status == "2") {
            ElMessage.error({
              message: response.log,
              duration: 2000,
            });
            this.$bus.$emit("hideloading");
          }
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    savedata() {
      console.log("savedata", this.thisdata);

      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/runtimeuuid", {
          data: { type: "get" },
        })
        .then((response) => {
          console.log("response", response);
          this.thisdata.runopuu = response;
          axios
            .post(
              "/" + this.dialoginfo.modlecode + "/save",
              {
                data: this.thisdata,
              },
              { timeout: 1 }
            )
            .then(() => {
              // this.$emit("changedata", {});
              // this.visiable = false;
              // this.$bus.$emit("hideloading");
            })
            .catch(() => {
              // this.$bus.$emit("hideloading");
            });

          this.$bus.$emit("showloading");
          this._getopstatus = setTimeout(() => {
            this.checkopstatus(response);
          }, 2000);
        })
        .catch(() => {});
      // axios
      //   .post("/" + this.dialoginfo.modlecode + "/save", {
      //     data: this.thisdata,
      //   })
      //   .then(() => {
      //     this.$emit("changedata", {});
      //     this.visiable = false;
      //     this.$bus.$emit("hideloading");
      //   })
      //   .catch(() => {
      //     this.$bus.$emit("hideloading");
      //   });

      //this.$bus.$emit("hideloading");
    },
  },
};
</script>
<style scoped>
:deep(.el-transfer-panel) {
  width: 300px;
}

:deep(.el-table .cell) {
  white-space: pre-line;
}

:deep(.invoviewtool) {
  width: 180px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
  text-align: right;
}

:deep(.invoviewdata) {
  width: 340px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
}

:deep(.myadditem .el-form-item__label) {
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}
</style>
  