<template>
  <el-container
    style="
      position: absolute;
      left: 50%;
      width: 1440px;
      margin-left: -720px;
      height: 100%;
    "
  >
    <el-header height="120px" style="text-align: left"></el-header>
    <el-container>
      <el-aside width="75%" style="text-align: left">
        <div id="floater">
          <br /><br /><br /><br />
          <div id="content"></div>
        </div>
      </el-aside>
      <el-container>
        <el-main style="text-align: center">
          <el-tabs v-model="logintype" type="card">
            <el-tab-pane label="扫码登录" name="sc">
              
              <div class="qr_login" id="qr_login"></div>
              <iframe
                ref="qccode"
                style="height: 400px"
                frameborder="0"
                src="https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=ww9fe85831907a5979&agentid= 1000019&redirect_uri=https%3A%2F%2Ffina.liuheqinqiang.com%2F&state=QYwxlogin"
              ></iframe>
              <div ref="mesag" >请通过https://fina.liuheqinqiang.com/域名访问以使用扫码登录</div>
            </el-tab-pane>
            <el-tab-pane label="账号密码登录" name="np">
              <el-form
                :model="ruleform"
                :rules="rules"
                ref="loginform"
                label-width="100px"
                class="npform"
              >
                <el-form-item label="用户代码：" prop="name">
                  <el-input
                    v-model="ruleform.name"
                    @keypress="keypress"
                    class="npinput"
                  ></el-input>
                </el-form-item>
                <el-form-item label="用户密码：" prop="pass">
                  <el-input
                    v-model="ruleform.pass"
                    @keypress="keypress"
                    class="npinput"
                    type="password"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="anniu">
                <el-button @click="formreset()">重 置</el-button>
                <el-button type="primary" @click="submitform()"
                  >登 录</el-button
                >
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-container>
    <HtmlFoot></HtmlFoot>
  </el-container>
</template>


<script>
import HtmlFoot from "../../controls/HtmlFoot";
// import { postback } from "@/publiccomm";

import Axios from "axios";
export default {
  components: {
    HtmlFoot,
  },
  data() {
    const namecheckr = "[^a-zA-Z0-9_]+";
    const passcheckr = "[^a-zA-Z0-9_!#@$%&()]+";
    const checkname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("用户名不可为空！！"));
      } else if (value.match(namecheckr)) {
        callback(new Error("用户名仅可包含数字大小写字母及下划线"));
      }
      callback();
    };
    const checkpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不可为空！！"));
      }
      if (value.match(passcheckr)) {
        callback(new Error("密码包含了非法字符！！"));
      }

      callback();
    };

    return {
      logintype: "sc",
      ruleform: {
        name: "",
        pass: "",
      },
      viewcode:true,
      rules: {
        name: [{ required: true, validator: checkname, trigger: "blur" }],
        pass: [{ required: true, validator: checkpass, trigger: "blur" }],
      },
    };
  },
  mounted() {
    var url = window.location.href; //获取访问的url 用于判断访问来源，添加微信登录
    if(url.indexOf("fina.liuheqinqiang.com")>=0){
      this.$refs.qccode.style.display="";
      this.$refs.mesag.style.display="none";
      this.logintype="sc";
    }else{
      this.$refs.qccode.style.display="none";
      this.$refs.mesag.style.display="";
      this.logintype="np";
    }
    console.log(url);
    
    var eturl=url.substring(0,url.indexOf('?')-1)
    console.log('at2',eturl)
    
    if (url.indexOf("code=") > 0 && url.indexOf("state=")) {
      var code = ""
      code = url.substring(url.indexOf("code=") + 5)
      if (code.indexOf("&") >= 0) {
        code = code.substring(0, code.indexOf("&"))
      }
      var state=''
      state=url.substring(url.indexOf("state=") + 6)
      if (state.indexOf("&") >= 0) {
        state = state.substring(0, state.indexOf("&"))
      }
      Axios.post("/login/qywxlogin", {
            data: { code: code, state: state },
          })
            .then(
              (response) => (
                localStorage.setItem("Islogin", "true"),
                localStorage.setItem("Token", response.userinfo.usertoken),
                localStorage.setItem("UserCode", response.userinfo.usercode),
                localStorage.setItem("UserId", response.userinfo.userid),
                localStorage.setItem("UserName", response.userinfo.username),
                localStorage.setItem("UserType", response.userinfo.usertype),
                localStorage.setItem(
                  "menulist",
                  JSON.stringify(response.menulist)
                ),
                localStorage.setItem(
                  "authlist",
                  JSON.stringify(response.authlist)
                ),
                localStorage.setItem(
                  "viewlist",
                  JSON.stringify(response.viewlist)
                ),
                location.replace(eturl)
              )
            )
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
    }
  },
  methods: {
    // testcallback(url,data){
    //   console.log(url,data)
    // },
    formreset() {
      // postback('sss','sdsd',this.testcallback)
      this.$refs.loginform.resetFields();
      //window.getQRCode();
    },
    keypress(data) {
      if (data.key == "Enter") {
        this.submitform();
      }
    },
    submitform() {
      this.$bus.$emit("showloading");
      this.$refs.loginform.validate((val) => {
        if (val) {
          //localStorage.clear();
          Axios.post("/login/weblogin", {
            data: { usercode: this.ruleform.name, pass: this.ruleform.pass },
          })
            .then(
              (response) => (
                localStorage.setItem("Islogin", "true"),
                localStorage.setItem("Token", response.userinfo.usertoken),
                localStorage.setItem("UserCode", response.userinfo.usercode),
                localStorage.setItem("UserId", response.userinfo.userid),
                localStorage.setItem("UserName", response.userinfo.username),
                localStorage.setItem("UserType", response.userinfo.usertype),
                localStorage.setItem(
                  "menulist",
                  JSON.stringify(response.menulist)
                ),
                localStorage.setItem(
                  "authlist",
                  JSON.stringify(response.authlist)
                ),
                localStorage.setItem(
                  "viewlist",
                  JSON.stringify(response.viewlist)
                ),
                location.reload()
              )
            )
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        } else {
          this.$bus.$emit("hideloading");
        }
      });
    },
  },
};
</script>
<style scoped>
.npform {
  text-align: left;
}

.npinput {
  width: 100%;
  text-align: left;
}

.anniu {
  text-align: center;
}
</style>