<template>
  <el-dialog
    :ref="dialoginfo.modlecode + 'dialog'"
    :title="dialoginfo.name"
    v-model="visiable"
    width="1000px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span>
      <el-form
        :ref="dialoginfo.modlecode + 'dialogform'"
        inline
        :model="thisdata"
        :rules="thisrules"
        label-width="100px"
      >
        <el-form-item label="用户代码" prop="v_code">
          <el-input
            :disabled="true"
            v-model="thisdata.v_code"
            style="width: 90px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="用户名称" prop="v_name">
          <el-input
            :disabled="true"
            v-model="thisdata.v_name"
            style="width: 100px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="用户电话" prop="v_phone">
          <el-input
            :disabled="true"
            v-model="thisdata.v_phone"
            style="width: 120px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="摘要" prop="remark">
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="thisdata.remark"
            style="width: 380px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="重置密码" prop="">
          <el-checkbox
            label=""
            @change="respass"
            :disabled="dialoginfo.modle == 'view'"
          />
          &nbsp;
          <el-input
            :disabled="true"
            v-model="thisdata.cgpass"
            style="width: 120px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="允许登录" prop="">
          <el-checkbox
            label=""
            @change="loginablecg"
            :disabled="dialoginfo.modle == 'view'"
            :checked="thisdata.islogin == 1"
          />
          &nbsp;
        </el-form-item>
      </el-form>
      <el-table
        :data="thisdata.dtl"
        style="width: 100%; margin-top: 5px"
        height="350px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        :default-sort="{ prop: 'order', order: 'descending' }"
      >
        <el-table-column prop="title" label="模块名称" width="260">
        </el-table-column>

        <el-table-column label="权限" width="670">
          <template #default="scope">
            <el-checkbox
              label="打开"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'view',
                  });
                }
              "
              :disabled="dialoginfo.modle == 'view'"
              :checked="scope.row.gpid > 0"
            />
            <el-checkbox
              label="新增"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'add',
                  });
                }
              "
              :disabled="
                (scope.row.operation & 128) != 128 ||
                scope.row.hasopt == 0 ||
                dialoginfo.modle == 'view'
              "
              :checked="(scope.row.gpoper & 128) == 128"
            />
            <el-checkbox
              label="修改"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'edit',
                  });
                }
              "
              :disabled="
                (scope.row.operation & 32) != 32 ||
                scope.row.hasopt == 0 ||
                dialoginfo.modle == 'view'
              "
              :checked="(scope.row.gpoper & 32) == 32"
            />
            <el-checkbox
              label="删除"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'del',
                  });
                }
              "
              :disabled="
                (scope.row.operation & 64) != 64 ||
                scope.row.hasopt == 0 ||
                dialoginfo.modle == 'view'
              "
              :checked="(scope.row.gpoper & 64) == 64"
            />
            <el-checkbox
              label="确认"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'check',
                  });
                }
              "
              :disabled="
                (scope.row.operation & 16) != 16 ||
                scope.row.hasopt == 0 ||
                dialoginfo.modle == 'view'
              "
              :checked="(scope.row.gpoper & 16) == 16"
            />
            <el-checkbox
              label="取消"
              v-show="scope.row.type == 1"
              @change="
                (pre, next) => {
                  optchange(pre, next, {
                    code: scope.row.row_code,
                    type: 'uncheck',
                  });
                }
              "
              :disabled="
                (scope.row.operation & 8) != 8 ||
                scope.row.hasopt == 0 ||
                dialoginfo.modle == 'view'
              "
              :checked="(scope.row.gpoper & 8) == 8"
            />
            <br />
            <span v-show="scope.row.type == 1">特殊数据权限：</span>
            <el-select
              multiple
              collapse-tags
              collapse-tags-tooltip
              v-model="scope.row.gpcondition"
              v-show="scope.row.type == 1"
              size="small"
              clearable
              :disabled="scope.row.hasopt == 0 || dialoginfo.modle == 'view'"
            >
              <el-option
                v-for="item in conditionstr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="商户数据授权"
        v-model="dtlvisiable"
        width="840px"
        :show-close="false"
        @close="cleardtldata"
        center
      >
        <span>
          <el-transfer
            v-model="thisdata.authlist"
            filterable
            :filter-method="usergroupfilder"
            width="300px"
            filter-placeholder="模糊查询"
            :data="thisdata.allauth"
            :titles="['可选商户', '已选商户']"
            :disabled="dialoginfo.modle == 'view'"
          />
        </span>

        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dtlvisiable = false"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>

      <el-dialog
        title="用户组管理"
        v-model="dtlvisiablegp"
        width="840px"
        :show-close="false"
        @close="cleardtldata"
        center
      >
        <span>
          <el-transfer
            v-model="thisdata.gplist"
            filterable
            :filter-method="usergroupfilder"
            width="300px"
            filter-placeholder="模糊查询"
            :data="thisdata.allgplist"
            :titles="['可选用户组', '已选用户组']"
            :disabled="dialoginfo.modle == 'view'"
          />
        </span>

        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dtlvisiablegp = false"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button type="warning" @click="openocc">数据权限</el-button>
        <el-button type="warning" @click="opengpcc">用户组管理</el-button>
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view'"
          @click="savedata"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
//import { ElMessage } from "element-plus";

import axios from "axios";

export default {
  emits: ["changedata", "dialogclose"],
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    const validateword = (rule, value, callback) => {
      if (value.toString().match("^[^a-z A-Z]?[^a-z A-Z 0-9]+")) {
        callback(new Error("只可输入字母"));
      } else if (value == "") {
        callback(new Error("标识不可为空"));
      } else {
        callback();
      }
    };
    return {
      dtlvisiablegp: false,
      conditionstr: [
        { label: "参与操作", value: "userin" },
        { label: "显示全部", value: "all" },
      ],
      ssvalue: {},
      ssdatas: [],
      dtlvisiable: false,
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },
      dtldatarules: {
        v_name: [
          { required: false, message: "本项目不可为空！！", trigger: "blur" },
        ],
        mark: [{ required: true, validator: validateword, trigger: "blur" }],
      },
      thisdata: {},
      visiable: false,
      yesno: [
        { label: "是", value: "1" },
        { label: "否", value: "99" },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.thisdata.cgpass = "";
        this.visiable = true;
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);
        this.getdata();
      }
    },
  },
  mounted() {
    console.log(new Date() + "");
  },
  methods: {
    loginablecg(val) {
      if (val) {
        this.thisdata.islogin = 1;
        this.thisdata.islogin_v = "允许";
      } else {
        this.thisdata.islogin = 99;
        this.thisdata.islogin_v = "禁止";
      }
    },
    respass(val) {
      let passed =
        "abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ123456789123456789_!#@$%&()]+_!#@$%&()]+abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ123456789123456789_!#@$%&()]+_!#@$%&()]+";
      let endpass = "";
      if (val) {
        for (var i = 0; i < 8; i++) {
          endpass += passed[parseInt(Math.random() * (86 + 1), 10)];
        }
        this.thisdata.cgpass = endpass;
      } else {
        this.thisdata.cgpass = "";
      }
    },
    opengpcc() {
      if (this.dialoginfo.modle == "view") {
        this.thisdata.allgplist.map((val) => {
          val.disabled = true;
        });
      } else {
        this.thisdata.allgplist.map((val) => {
          val.disabled = false;
        });
      }
      this.dtlvisiablegp = true;
    },
    openocc() {
      if (this.dialoginfo.modle == "view") {
        this.thisdata.allauth.map((val) => {
          val.disabled = true;
        });
      } else {
        this.thisdata.allauth.map((val) => {
          val.disabled = false;
        });
      }
      this.dtlvisiable = true;
    },
    usergroupfilder(query, item) {
      return item.label.indexOf(query) > -1;
    },
    optchange(pre, next, data) {
      this.$bus.$emit("showloading");

      this.thisdata.dtl = this.optchangechiled(
        this.thisdata.dtl,
        data.code,
        data.type,
        pre
      );

      this.$bus.$emit("hideloading");
      console.log(this.thisdata.dtl);
    },
    optchangechiled(tplist, code, optt, rtf) {
      let rtlist = Array();
      for (let i = 0; i < tplist.length; i++) {
        if (tplist[i]["row_code"] == code) {
          if (optt == "view") {
            if (rtf) {
              tplist[i].hasopt = 1;
            } else {
              tplist[i].hasopt = 0;
            }
          }
          if (optt == "add") {
            if (rtf) {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) + 128;
            } else {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) - 128;
            }
          }
          if (optt == "edit") {
            if (rtf) {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) + 32;
            } else {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) - 32;
            }
          }
          if (optt == "del") {
            if (rtf) {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) + 64;
            } else {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) - 64;
            }
          }
          if (optt == "check") {
            if (rtf) {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) + 16;
            } else {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) - 16;
            }
          }
          if (optt == "uncheck") {
            if (rtf) {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) + 8;
            } else {
              tplist[i].gpoper = parseInt(tplist[i].gpoper) - 8;
            }
          }
        }
        if (tplist[i].type == 0) {
          tplist[i].children = this.optchangechiled(
            tplist[i].children,
            code,
            optt,
            rtf
          );
        }
        rtlist.push(tplist[i]);
      }
      return rtlist;
    },
    dtlsavedata() {},
    cleardtldata() {},

    tableheadClass() {
      return "text-align:center";
    },
    getdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.thisdata.dtl = response.dtl;
          this.thisdata.authlist = response.authlsit;
          this.thisdata.allauth = response.allist;
          this.thisdata.allgplist = response.allgplist;
          this.thisdata.gplist = response.gplist;

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      let sdhe = this.pjdtldata(this.thisdata.dtl, "sdsd", "sdsdsds");
      let senddata = Array();
      senddata["dtl"] = sdhe;
      senddata["authlist"] = this.thisdata.authlist;
      senddata["sid"] = senddata["row_code"] = this.thisdata.row_code;
      senddata["remark"] = this.thisdata.remark;

      this.$bus.$emit("showloading");
      this.$refs[this.dialoginfo.modlecode + "dialogform"].validate((val) => {
        if (val) {
          axios
            .post("/" + this.dialoginfo.modlecode + "/save", {
              data: {
                dtl: sdhe,
                authlist: this.thisdata.authlist,
                sid: this.thisdata.sid,
                row_code: this.thisdata.row_code,
                remark: this.thisdata.remark,
                ver: this.thisdata.ver,
                gplist: this.thisdata.gplist,
                cgpass: this.thisdata.cgpass,
                islogin: this.thisdata.islogin,
                islogin_v: this.thisdata.islogin_v,
                passwords: this.thisdata.passwords,
                v_name: this.thisdata.v_name,
              },
            })
            .then(() => {
              this.$emit("changedata", {});
              this.visiable = false;
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        } else {
          this.$bus.$emit("hideloading");
        }
      });
      //this.$bus.$emit("hideloading");
    },
    pjdtldata(data, name, code) {
      var retdata = new Array();
      for (let i = 0; i < data.length; i++) {
        if (data[i].type == 0) {
          retdata = retdata.concat(
            this.pjdtldata(data[i].children, name, code)
          );
        }
        if (data[i].type != 0 && data[i].hasopt == 1) {
          retdata = retdata.concat({
            row_code: data[i].row_code,
            gpoper: data[i].gpoper,
            gpcondition: data[i].gpcondition,
          });
        }
      }
      return retdata;
    },
    opendtladd() {
      this.dtlvisiable = true;
    },
  },
};
</script>
<style scoped>
:deep(.el-transfer-panel) {
  width: 300px;
}
</style>
