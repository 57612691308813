<template>
  <el-select
    v-model="localcode"
    :filterable="true"
    :style="'width:' + width + ';'"
    collapse-tags
    reserve-keyword
    remote
    :remote-method="getdata"
    :multiple="ismultiple"
    :clearable="iscleanable"
    @change="datacg"
    default-first-option
    :disabled="isdisabled"
    :placeholder="placeholder"
  >
    <el-option
      v-for="item in localdatas"
      :key="item.row_code"
      :label="item.v_name "
      :value="item.row_code"
    >
    </el-option>
  </el-select>
</template>
<script>
import axios from "axios";

export default {
  emits: ["datachange"],
  props: {
    rowcode: { required: true },
    status: { default: "" },
    iscleanable: { default: false },
    ismultiple: { default: false },
    isdisabled: { default: false },
    width: { default: "320px" },
    placeholder: { default: "选择会计单位" },
  },

  data() {
    return {
      localcode: this.code,
      localdatas: [],
      serchdata: { codename: "", status: this.status, code: this.rowcode },
    };
  },
  watch: {
    rowcode: function () {
      console.log("account change watch", this.rowcode, this.localcode);
      if (this.rowcode != this.localcode) {
        this.serchdata.code=this.rowcode;
        this.getdata("");
        this.localcode = this.rowcode;
      }
    },
  },
  mounted() {},
  methods: {
    getdata(val) {
      this.serchdata.codename = val;
      clearTimeout(this._getgoods);
      this._getgoods = setTimeout(() => {
        axios
          .post("/getdicc/accountingunitgetlist", { data: this.serchdata })
          .then((response) => {
            this.localdatas = response;
          });
      }, 300);
    },
    datacg(val) {
      if (typeof (val)!='string') {
        let tpdata = Array();
        val.map((valmx) => {
          for (var i = 0; i < this.localdatas.length; i++) {
            if (this.localdatas[i].row_code == valmx) {
              tpdata.push(this.localdatas[i]);
              break;
            }
          }
        });
        this.$emit("datachange", tpdata);
        return;
      } else {
        let tpdata = Array();
        for (var i = 0; i < this.localdatas.length; i++) {
          if (this.localdatas[i].row_code == val) {
            tpdata.push(this.localdatas[i]);
            this.$emit("datachange",  tpdata);
            return;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.el-select-dropdown__list {
  text-align: left;
}
</style>