import axios from "axios"

export function postback(url, data, callback) {

    axios.post('/getopuid/getopuid', data)
        .then((request) => {
            console.log('sdsdsdsd', request)

            data.opuid = request
            axios
                .post(url, { data: data }, { timeout: 500 })

            setTimeout(getpoststate, 500, { 'url': url, 'data': data, 'callback': callback })

        })




}

function getpoststate(opuid) {
    console.log('opuidxdds', opuid)
    //opuid.callback(opuid)
    axios.post('/getopuid/getopstatus', opuid)
        .then((request) => {
            console.log('ressd', request)
            if(request.status==3){
                opuid.callback(request.log)
            }else{
                setTimeout(getpoststate, 500, opuid)
            }
            
        })
        .catch(()=>{
            opuid.callback(null)
        })
}