<template>
  <el-tabs v-model="activeTab" tab-position="bottom" style="height: 100%">

    <el-tab-pane style="height: 100%" key="results" name="ResultsTab" label="查询结果">

      <SearchResult :areaheight="areaheight" :searchdata="searchdata" :selfmodul="selfmodul"></SearchResult>

    </el-tab-pane>
  </el-tabs>
</template>
<script >
import SearchResult from "./comp/SearchResult.vue"


export default {
  components: { SearchResult },
  data() {
    return {
      selfmodul: { code: 'ModelConfig', name: '模块配置' },
      activeTab: "ResultsTab",
      searchdata: { codename: '', sentype: '' },
      areaheight: 0,
    };
  },
  methods: {
    updsearch(data) {
      this.activeTab = 'ResultsTab'

      this.searchdata = JSON.parse(JSON.stringify(data))

    },
    resultsbutton(data) {
      console.log(data)
    },
    changehei(data) {
      this.areaheight = data.hei
      console.log('modelchei', this.areaheight)
    },


  },
  mounted() {
    this.$bus.$on('heichange', this.changehei)

    this.$bus.$emit('requehei')

  }
};
</script>
<style scoped>
</style>