<template>
    <el-table :data="tabledata" style="width: 100%; margin-top: 5px" :height="tableheight"
        :header-cell-style="tableheadClass" stripe row-key="row_code" border default-expand-all :tree-props="{}">
        <el-table-column prop="v_name" label="系统名称" width="280">
        </el-table-column>

        <el-table-column prop="issyn_v" label="是否同步" width="120">
        </el-table-column>

        <el-table-column prop="lasttime_date" label="同步截至时间" width="280">
        </el-table-column>

        <el-table-column prop="status_v" label="系统状态" width="80">
        </el-table-column>

        <el-table-column fixed="right" width="120">
            <template #header>
                <el-button v-show="viewlist.add" @click="openadd()" size="small" :disabled="!operar.add"
                    icon="el-icon-plus">新建
                </el-button>
                <div v-show="!viewlist.add">操作</div>
            </template>
            <template #default="scope">
                <el-button @click="openview(scope.row)" type="text" size="small">查看</el-button>
                <el-button @click="openedit(scope.row)" type="text" size="small" :disabled="!operar.edit"
                    v-show="(scope.row.is_view == 1 || scope.row.upcode != '-1') && scope.row.status != 99 && viewlist.edit">
                    编辑
                </el-button>
                <el-button @click="deldata(scope.row)" type="text" size="small"
                    v-show="(scope.row.is_view == 1 || scope.row.upcode != '-1') && scope.row.status != 99 && viewlist.del"
                    :disabled="!operar.del">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pagenow" :page-sizes="[50, 100, 300, 500, 1000]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="totalrow">
    </el-pagination>
    <ExsysDialog :dialoginfo="dialoginfo" :dialogdata="dialogdata" :isvisiable="dialogvis" @changedata="flashdata"
        @dialogclose="editclose"></ExsysDialog>
</template>
<script >
import ExsysDialog from "./ExtsysDialog"

import axios from "axios"

export default {
    components: { ExsysDialog },
    emits: [],
    props: {
        searchdata: { required: true },
        selfmodul: { required: true },
        areaheight: { required: true },
    },
    data() {
        return {
            dialogvis: false,
            tableheight: 60,

            totalrow: 0,
            pagenow: 1,
            pagesize: 100,

            dialoginfo: { modlecode: this.selfmodul['code'] },
            dialogdata: {},
            tabledata: [],
            mysearch: {},
            operar: {
                conditionstr: "",
                add: false,
                edit: false,
                del: false,
                check: false,
                uncheck: false,
            },

            viewlist: {
                add: false,
                edit: false,
                del: false,
                check: false,
                uncheck: false,
            },
        }

    },
    mounted() {
        let operardata = JSON.parse(localStorage.getItem("authlist"));
        let controldata = operardata[this.selfmodul['code']].operation;
        console.log(controldata);
        this.operar.add = (controldata & 128) == 128;
        this.operar.del = (controldata & 64) == 64;
        this.operar.edit = (controldata & 32) == 32;
        this.operar.check = (controldata & 16) == 16;
        this.operar.uncheck = (controldata & 8) == 8;
        this.operar.conditionstr = operardata[this.selfmodul['code']].conditionstr;

        operardata = JSON.parse(localStorage.getItem("viewlist"));
        controldata = operardata[this.selfmodul['code']].operation;
        this.viewlist.add = (controldata & 128) == 128;
        this.viewlist.del = (controldata & 64) == 64;
        this.viewlist.edit = (controldata & 32) == 32;
        this.viewlist.check = (controldata & 16) == 16;
        this.viewlist.uncheck = (controldata & 8) == 8;

        console.log(this.operar);
        this.flashdata();

        console.log('areaheigh', this.areaheight);
    },
    methods: {

        handleSizeChange(val) {
            let pgs = Math.ceil(this.totalrow / val)
            this.pagesize = val
            if (pgs >= this.pagenow) {
                this.gettabledata()
            }
        },
        handleCurrentChange(val) {
            this.pagenow = val
            this.gettabledata()
        },
        editclose(data) {
            this.dialogvis = false
            console.log(data);
        },
        flashdata() {
            this.$bus.$emit("showloading");
            let mysearchdata = JSON.parse(JSON.stringify(this.searchdata));
            mysearchdata.pagenow = this.pagenow
            mysearchdata.pagesize = this.pagesize

            axios
                .post("/" + this.selfmodul['code'] + "/getlist", { data: mysearchdata })
                .then((response) => {

                    this.tabledata = response['rows'];
                    this.totalrow = response['total'] * 1;

                    this.$bus.$emit("hideloading");

                })
                .catch(() => {
                    this.$bus.$emit("hideloading");
                });
        },
        openview(data) {
            console.log('view', data)
            this.dialoginfo.name = '查看' + this.selfmodul['name'] + '';
            this.dialoginfo.modle = 'view';
            this.dialogdata = data;
            this.dialogvis = true;
        },

        openedit(data) {
            console.log('edit', data)
            this.dialoginfo.name = '编辑' + this.selfmodul['name'] + '';
            this.dialoginfo.modle = 'edit';
            this.dialogdata = data;
            this.dialogvis = true;
        },
        openadd() {
            this.dialogdata = { v_name: '', issyn: 99 }
            this.dialoginfo.name = '新建' + this.selfmodul['name'] + ''
            this.dialoginfo.modle = 'add'
            this.dialogvis = true
        },
        deldata(data) {

            console.log('del', data)
            axios
                .post("/" + this.selfmodul['code'] + "/delet", { data: data })
                .then(() => {
                    this.flashdata()
                    this.$bus.$emit("hideloading");

                })
                .catch(() => {
                    this.$bus.$emit("hideloading");
                });
        },

        tableheadClass() {
            return "text-align:center";
        },
    },
    watch: {
        searchdata: function () {
            if (JSON.stringify(this.mysearch) != JSON.stringify(this.searchdata)) {
                //查询条件变动，重新拉取数据
                this.mysearch = JSON.parse(JSON.stringify(this.searchdata));
                this.flashdata();
            }
        },
        areaheight: function () {
            this.$nextTick(() => { this.tableheight = (this.areaheight - 284); });
        },

    },
}
</script>
<style scoped>
</style>