<template>
    <el-tabs v-model="activeTab" tab-position="bottom" style="height: 100%">
        <el-tab-pane style="height: 100%; display: none;" key="search" name="SearchTab" label="查询条件">
            <SearchModul :areaheight="areaheight" :searchtypedatas="searchtypedatas" :searchdata="searchdata" @updsearch="updsearch"></SearchModul>
        </el-tab-pane>
        <el-tab-pane style="height: 100%" key="results" name="ResultsTab" label="查询结果">

            <SearchResult :areaheight="areaheight" :searchdata="searchdata" :selfmodul="selfmodul"></SearchResult>

        </el-tab-pane>

    </el-tabs>
</template>
<script >

import SearchModul from "../../../../controls/SearchModul.vue"
import SearchResult from "./comp/SearchResult.vue"

export default {
    components: { SearchModul, SearchResult },

    data() {
        return {
            selfmodul: { code: 'ExtSys', name: '系统配置' },
            activeTab: "ResultsTab",
            searchdata: { codename: '', status: '' },
            
            searchtypedatas: { status: [{value:1,label: '启用'},{value:99,label: '删除'}]},
            areaheight: 0,
        };
    },
    methods: {
        updsearch(data) {
            this.activeTab = 'ResultsTab'

            this.searchdata = JSON.parse(JSON.stringify(data))

        },
        resultsbutton(data) {
            console.log(data)
        },


        changehei(data) {
            this.areaheight = data.hei
            console.log('modelchei', this.areaheight)
        },


    },
    mounted() {
        this.$bus.$on('heichange', this.changehei)

        this.$bus.$emit('requehei')

    }


}
</script>
<style scoped>
</style>