<template>
  <el-dialog ref="moduledialog" @opened="dialogopened" :title="dialoginfo.name" v-model="visiable" width="600px"
    :show-close="false" @close="cleardata" center>
    <span>
      <el-form ref="moduledialogform" :model="thisdata" :rules="thisrules" label-width="100px">
        <el-form-item label="模块名称" prop="title">
          <el-input :disabled="dialoginfo.modle == 'view'" v-model="thisdata.title" style="width:360px"
            autocomplete="off" />
        </el-form-item>

        <el-form-item label="节点类型" prop="type">
          <el-select v-model="thisdata.type" @change="selectcg" :filterable=true style="width: 360px"
            :disabled="dialoginfo.modle == 'edit' || dialoginfo.modle == 'view'" class="localselect">
            <el-option v-for="item in moduletype" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="控制器名称" prop="controller">
          <el-input :disabled="dialoginfo.modle == 'view'" v-model="thisdata.controller" style="width:360px"
            autocomplete="off" />
        </el-form-item>

        <el-form-item label="上级模块名称" prop="upcode">
          <el-cascader ref="upcodeca" :disabled="dialoginfo.modle == 'view'" v-model="thisdata.upcode"
            :options="thisdatas" clearable filterable :props="scaprops" class="ModelDialogcascader" @change="caschange">
          </el-cascader>
        </el-form-item>


        <el-form-item label="显示顺序" prop="view_order">
          <el-input :disabled="dialoginfo.modle == 'view'" v-model="thisdata.view_order" style="width:360px"
            autocomplete="off" />
        </el-form-item>
        <div style="height:70px">
          <el-form-item v-show="thisdata.type != 0" label="可用权限" prop="">
            <el-checkbox v-model="checked1" :disabled="thisdata.type == 0 || dialoginfo.modle == 'view'" label="新增">
            </el-checkbox>
            <el-checkbox v-model="checked2" :disabled="thisdata.type == 0 || dialoginfo.modle == 'view'" label="删除">
            </el-checkbox>
            <el-checkbox v-model="checked3" :disabled="thisdata.type == 0 || dialoginfo.modle == 'view'" label="修改">
            </el-checkbox><br />
            <el-checkbox v-model="checked4" :disabled="thisdata.type == 0 || dialoginfo.modle == 'view'" label="确认">
            </el-checkbox>
            <el-checkbox v-model="checked5" :disabled="thisdata.type == 0 || dialoginfo.modle == 'view'" label="取消">
            </el-checkbox>


          </el-form-item>

        </div>
      </el-form>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button type="primary" v-show="dialoginfo.modle != 'view'" @click="savedata">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "axios"

export default {
  emits: ["changedata", "dialogclose"],
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {

    const validateNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('内容不可为空'))
      } else {
        if (value.toString().match('[^0-9]+')) {
          callback(new Error('只可输入数字'))
        } else {
          callback()
        }
      }
    }
    const validateword = (rule, value, callback) => {
      if (value.toString().match('^[^a-z A-Z]?[^a-z A-Z 0-9]+')) {
        callback(new Error('只可输入字母'))
      } else if (this.thisdata.type == 1 && value == "") {
        callback(new Error('明细节点控制器不可为空'))
      } else {
        callback()
      }
    }
    return {
      thisdata: { type: '1', title: '', controller: "", upcode: '', view_order: 0 },
      visiable: false,
      thisrules: {
        title: [{ required: true, message: '本项目不可为空！！', trigger: 'blur' }],
        controller: [{ required: false, validator: validateword, trigger: 'blur' }],
        view_order: [{ required: true, validator: validateNum, trigger: 'blur' }],
      },
      moduletype: [{ value: '0', label: '汇总节点' }, { value: '1', label: '明细节点' }],
      scaprops: { value: 'row_code', label: 'title', checkStrictly: true },
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      thisdatas: {},
      hasmounted: false,

    };
  },
  mounted() {
    console.log("mounted");
    console.log('sss', this.$refs)
    this.hasmounted = true;
    console.log()
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {

        this.thisdatas = this.catchdatas(this.dialoginfo.alldatas)
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata))
        this.checked1=((this.dialogdata.operation & 128) == 128)
        this.checked2=((this.dialogdata.operation & 64) == 64)
        this.checked3=((this.dialogdata.operation & 32) == 32)
        this.checked4=((this.dialogdata.operation & 16) == 16)
        this.checked5=((this.dialogdata.operation & 8) == 8)
        this.visiable = true;
        console.log('dialog get', this.dialogdata)
        console.log('accstatus',this.checked1)
      }
    },
  },
  methods: {
    caschange() {
      this.$refs.upcodeca.getCheckedNodes().map((val) => {
        console.log('caschange', val)
        this.thisdata.upcode_v = val.label
        this.thisdata.upcode = val.value
        
        this.thisdata.is_view = val.data.is_view
        this.thisdata.is_view_v = val.data.is_view_v


      })
    },
    selectcg(val) {

      console.log('selecg', val)
      this.$refs.moduledialogform.clearValidate(['controller'])
    },

    dialogopened() {

    },
    catchdatas(data) {
      let enddata = Array()
      data.map((val) => {
        if (val.children != undefined) {
          let child = this.catchdatas(val.children)
          if (child.length > 0) {
            enddata.push({ type: val.type,is_view:val.is_view,is_view_v:val.is_view_v, row_code: val.row_code, title: val.title, controller: val.controller, upcode: val.upcode,upcode_v: val.upcode_v, view_order: val.view_order, children: child })
          } else {
            enddata.push({ type: val.type,is_view:val.is_view,is_view_v:val.is_view_v, row_code: val.row_code, title: val.title, controller: val.controller, upcode: val.upcode,upcode_v: val.upcode_v, view_order: val.view_order })
          }

        } else {
          if (val.type == 0) {
            enddata.push({ type: val.type,is_view:val.is_view, row_code: val.row_code, title: val.title, controller: val.controller, upcode: val.upcode,upcode_v: val.upcode_v, view_order: val.view_order })
          }

        }
      })
      return enddata

    },
    cleardata() {
      this.thisdata = { type: '1', title: '', controller: "", upcode: '',upcode_v: '', view_order: 0 };
      this.$refs.moduledialogform.clearValidate(['controller','title','view_order'])
    

      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      this.$bus.$emit("showloading");
      console.log('endsss', this.$refs)

      let opt = 0;
      if (this.checked1) {
        opt += 128
      }
      if (this.checked2) {
        opt += 64
      }
      if (this.checked3) {
        opt += 32
      }
      if (this.checked4) {
        opt += 16
      }
      if (this.checked4) {
        opt += 8
      }
      this.thisdata.operation=opt

      this.$refs.moduledialogform.validate((val) => {
        if (val) {
          
          axios
            .post("/" + this.dialoginfo.modlecode + "/save", { data: this.thisdata })
            .then(() => {

              this.$emit("changedata", {});
              this.visiable = false;
              this.$bus.$emit("hideloading");

            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        }
      })
      this.$bus.$emit("hideloading");


    },
  },
};
</script>
<style  scoped>
:deep(.ModelDialogcascader) {
  width: 360px;
}

:deep(.el-select-dropdown__list) {
  text-align: left;
}
</style>