<template>
  <el-table
    :data="tabledata"
    style="width: 100%; margin-top: 5px"
    :height="tableheight"
    :header-cell-style="tableheadClass"
    stripe
    row-key="row_code"
    border
    default-expand-all
    :tree-props="{}"
    @selection-change="invoceSelectionChange"
  >
  <el-table-column type="selection" width="40" />
    <el-table-column width="90" align="left">
      <!--  -->
      <template #header>开票抬头 </template>
      <template #default="scope">
        {{ scope.row.platename }}
      </template>
    </el-table-column>
    <el-table-column width="420" label="购货客户信息" align="left">
      <template #default="scope">
        <span style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b>购货客户名称：</b>{{ scope.row.buyername }}</span
        ><br />
        <span style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b>购货客户税号：</b>{{ scope.row.buyertaxnum }}</span
        ><br />
        <span style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b>地 址、电 话：</b
          ><span style="font-size: 10px">{{
            scope.row.buyeraddress
          }}</span></span
        ><br />
        <span style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b>开户行、账号：</b
          ><span style="font-size: 10px">{{
            scope.row.buyeraccount
          }}</span></span
        >
      </template>
    </el-table-column>
    <el-table-column prop="inv_type" label="发票信息" width="195" align="left">
      <template #default="scope">
        <span style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b>发票日期：</b> {{ dateformart2(scope.row.inv_data) }}</span
        ><br />
        <span v-if="scope.row.inv_type != '020' && scope.row.inv_type != '021'">
          <span style="padding: 0px; margin: 0px; text-indent: -12em"
            ><b>发票代码：</b> {{ scope.row.tax_code }}</span
          ><br />
          <span style="padding: 0px; margin: 0px; text-indent: -12em"
            ><b>发票号码：</b> {{ scope.row.tax_num }}</span
          ><br />
        </span>
        <span
          v-else-if="(scope.row.tax_code + scope.row.tax_num).length == 20"
          style="padding: 0px; margin: 0px; text-indent: -12em"
          ><b
            >发票号码：{{
              scope.row.tax_code 
            }}<br /><span style="margin-left: 70px">{{
              scope.row.tax_num
            }}</span></b
          >
          <br
        /></span>
        <span v-else><b>发票号码：</b><br /><br /></span>
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-if="scope.row.inv_type == '000'"
          ><b>发票类型：</b>待定</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '007'"
          ><b>发票类型：</b>纸普</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '026'"
          ><b>发票类型：</b>电普</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '004'"
          ><b>发票类型：</b>纸专</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '028'"
          ><b>发票类型：</b>电专</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '021'"
          ><b>发票类型：</b>全电普</span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.inv_type == '020'"
          ><b>发票类型：</b>全电专</span
        >
        <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
          ><b>发票类型：</b>数据错误</span
        >
        <br />
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-if="scope.row.taxlv === ''"
          ><b>发票税率：</b></span
        >
        <span
          style="padding: 0px; margin: 0px; text-indent: -12em"
          v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
          ><b>发票税率：</b>免税</span
        >
        <span style="padding: 0px; margin: 0px; text-indent: -12em" v-else
          ><b>发票税率：</b
          >{{ (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%" }}</span
        >
      </template>
    </el-table-column>
    <el-table-column label="发票金额" width="100" align="right">
      <template #default="scope">
        {{ (scope.row.total_amount / 100).toFixed(2) }}
      </template>
    </el-table-column>

    <el-table-column  label="备注" width="190" >
      <template #default="scope">
        <span v-if="scope.row.old_codenum != ''">原蓝字发票号：{{ scope.row.old_codenum }}</span>
        <span v-if="scope.row.red_radcode != ''">红字申请单号：{{ scope.row.red_radcode }}</span>
        {{ scope.row.remark }}
      </template>
    </el-table-column>
    <el-table-column label="发票状态" width="95" align="right">
      <template #default="scope">
        <span v-if="scope.row.status == '0'">待确认</span>
        <span v-else-if="scope.row.status == '1'">待开票</span>
        <span v-else-if="scope.row.status == '4'">待作废</span>
        <span v-else-if="scope.row.status == '7'">待红冲</span>
        <span v-else-if="scope.row.status == '10'">出现错误</span>
        <span v-else-if="scope.row.status == '20'">发送中</span>
        <span v-else-if="scope.row.status == '23'">专红申请</span>
        <span v-else-if="scope.row.status == '27'">专红发送</span>
        <span v-else-if="scope.row.status == '30'">开票中</span>
        <span v-else-if="scope.row.status == '32'">专红审核中</span>
        <span v-else-if="scope.row.status == '34'">专红开票中</span>
        <span v-else-if="scope.row.status == '40'">开票已完成</span>
        <span v-else-if="scope.row.status == '90'">已作废</span>
        <span v-else-if="scope.row.status == '95'">已红冲</span>
        <span v-else-if="scope.row.status == '99'">未开删除</span>
        <span v-else>数据错误</span>
      </template>
    </el-table-column>
    <el-table-column width="80" align="center">
      <template #header
        ><el-button
          @click="opendltg()"
          size="small"
          >下载</el-button
        ></template
      >
      <template #default="scope">
        <span>
        <el-button type="text" @click="viewinvos(scope.row)" size="small">
          查&nbsp;&nbsp;看</el-button
        >
        </span>
        <span v-if="scope.row.status == 0">
          <el-button
            type="text"
            @click="checkboxop(scope.row, 99)"
            :disabled="!operar.del 
            "
            size="small"
            >删&nbsp;&nbsp;除</el-button
          >
        </span>

        
        <span v-if="scope.row.status == 40 && scope.row.inv_direction == 1">
          <el-button
            type="text"
            @click="checkboxop(scope.row, 7)"
            :disabled="scope.row.status != 40 ||!operar.del"
            size="small"
          >
            红&nbsp;&nbsp;冲</el-button
          >
        </span>
        <span v-if=" scope.row.inv_direction == 2">
          <el-button
            type="text"
            @click="rekp(scope.row)"
            :disabled="!operar.add"
            size="small"
          >
            重&nbsp;&nbsp;开</el-button
          >
        </span>

        <span v-if="scope.row.status == 40" >
          <el-button
            type="text"
            @click="downloadinv(scope.row, 1)"
            :disabled="scope.row.file_pdf == '' "
            size="small"
          >
            下载pdf</el-button
          >
        </span>
        <span v-if="scope.row.status == 40" >
          <el-button
            type="text"
            @click="downloadinv(scope.row, 2)"
            :disabled="scope.row.file_ofd == '' "
            size="small"
          >
            下载ofd</el-button
          >
        </span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    background
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pagenow"
    :page-sizes="[50, 100, 300, 500, 1000]"
    :page-size="pagesize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="totalrow"
  >
  </el-pagination>
  <MainDialog
    :dialoginfo="dialoginfo"
    :dialogdata="dialogdata"
    :isvisiable="dialogvis"
    @changedata="flashdata"
    @dialogclose="editclose"
  ></MainDialog>

  <el-dialog
    :title="'选择商户'"
    v-model="cdvisiable"
    width="700px"
    :show-close="false"
    @close="clearccdata"
    center
  >
    <span>
      商户信息：<el-select
        v-model="dataflite"
        :filterable="true"
        placeholder="请选择商户"
        width="500px"
        reserve-keyword
        clearable
      >
        <el-option
          v-for="item in dataflites"
          :key="item.row_code"
          :label="item.v_name"
          :value="item.row_code"
        >
        </el-option> </el-select
      ><br
    /></span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cdvisiable = false">取 消</el-button>
        <el-button type="primary" @click="chcat">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="发票查看"
    v-model="viewinvovisable"
    width="1200px"
    :show-close="false"
    center
  >
    <span>
      <span class="invoviewtool"><b>购货客户名称：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyername }}</span>

      <span class="invoviewtool"><b>开票抬头：</b></span>
      <span class="invoviewdata">{{ viewinvodata.platename }}</span>

      <span class="invoviewtool"><b>购货客户税号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyertaxnum }}</span>

      <span class="invoviewtool"><b>发票编码：</b></span>
      <span class="invoviewdata">{{ viewinvodata.tax_code }}</span>

      <span class="invoviewtool"><b>购货客户地址、电话：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraddress }}</span>

      <span class="invoviewtool"><b>发票号码：</b></span>
      <span class="invoviewdata">{{ viewinvodata.tax_num }}</span>

      <span class="invoviewtool"><b>购货客户开户行、账号：</b></span>
      <span class="invoviewdata">{{ viewinvodata.buyeraccount }}</span>

      <span class="invoviewtool"><b>开票日期：</b></span>
      <span class="invoviewdata">{{ dateformart2(viewinvodata.inv_data) }}</span>

      <span class="invoviewtool"><b>发票类型：</b></span>
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction == 1"
        >待定</span
      >
      <span
        class="invoviewdata"
        v-if="viewinvodata.inv_type == '000' && viewinvodata.inv_direction != 1"
        >红字待定</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction == 1
        "
        >纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '007' && viewinvodata.inv_direction != 1
        "
        >红字纸普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '021' && viewinvodata.inv_direction == 1
        "
        >全电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '021' && viewinvodata.inv_direction != 1
        "
        >红字全电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '020' && viewinvodata.inv_direction == 1
        "
        >全电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '020' && viewinvodata.inv_direction != 1
        "
        >红字全电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction == 1
        "
        >电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '026' && viewinvodata.inv_direction != 1
        "
        >红字电普</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction == 1
        "
        >纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '004' && viewinvodata.inv_direction != 1
        "
        >红字纸专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction == 1
        "
        >电专</span
      >
      <span
        class="invoviewdata"
        v-else-if="
          viewinvodata.inv_type == '028' && viewinvodata.inv_direction != 1
        "
        >红字电专</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>发票金额：</b></span>
      <span class="invoviewdata">{{
        finallnum(viewinvodata.total_amount / 100)
      }}</span>

      <span class="invoviewtool"><b>状态：</b></span>
      <span class="invoviewdata" v-if="viewinvodata.status == '0'">待确认</span>
      <span class="invoviewdata" v-else-if="viewinvodata.status == '1'"
        >待开票</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '4'"
        >待作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '7'"
        >待红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '10'"
        >出现错误</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '20'"
        >发送中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '23'"
        >专红申请</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '27'"
        >专红发送</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '30'"
        >开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '32'"
        >专红审核中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '34'"
        >专红开票中</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '40'"
        >开票已完成</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '90'"
        >已作废</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '95'"
        >已红冲</span
      >
      <span class="invoviewdata" v-else-if="viewinvodata.status == '99'"
        >未开删除</span
      >
      <span class="invoviewdata" v-else>数据错误</span>

      <span class="invoviewtool"><b>备注：</b></span>
      <span class="invoviewdata">{{ viewinvodata.remark }}</span>
      <el-table
        :data="viewinvodata.dtl"
        style="width: 100%; margin-top: 12px"
        height="450px"
        :header-cell-style="tableheadClass"
        stripe
        row-key="row_code"
        border
        default-expand-all
        :tree-props="{}"
        show-summary
        :summary-method="invoviewtablesum"
      >
        <el-table-column label="商品名称" width="320" align="left">
          <template #default="scope">
            {{ scope.row.goodsname }}
          </template>
        </el-table-column>
        <el-table-column label="商品规格" width="108" align="center">
          <template #default="scope">
            {{ scope.row.goodspec }}
          </template>
        </el-table-column>
        <el-table-column label="单位" width="80" align="center">
          <template #default="scope">
            {{ scope.row.unit }}
          </template>
        </el-table-column>
        <el-table-column label="数量" width="120" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null
              "
              >{{ (parseInt(scope.row.num) / 1000000).toFixed(2) }}</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税单价" width="80" align="center">
          <template #default="scope">
            <span
              v-if="
                scope.row.num != '' &&
                scope.row.num != 0 &&
                scope.row.num != null
              "
              >{{
                (
                  ((parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                    parseInt(scope.row.num)) *
                  10000
                ).toFixed(2)
              }}</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="含税金额" width="120" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxamount!=0 && scope.row.taxamount!=null">
              {{(parseInt(scope.row.taxamount)/100).toFixed(2)}}
            </span>
            <span v-else>{{
              (
                (parseInt(scope.row.notaxamount) + parseInt(scope.row.tax)) /
                100
              ).toFixed(2)
            }}</span>
            
          </template>
        </el-table-column>
        <el-table-column label="税率" width="80" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxlv === ''"></span>
            <span v-else-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'"
              >免税</span
            >
            <span v-else>{{
              (parseInt(scope.row.taxlv) / 10000).toFixed(2) + "%"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="税额" width="120" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxamount!=0 && scope.row.taxamount!=null"><span v-if="scope.row.taxlv == -1 || scope.row.taxlv == '-1'">0</span><span v-else>{{ (parseInt(scope.row.taxamount)*parseInt(scope.row.taxlv )/100000000).toFixed(2) }}</span></span>
            <span v-else>{{ (parseInt(scope.row.tax) / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="含税折扣额" width="120" align="center">
          <template #default="scope">
            <span v-if="scope.row.taxamount!=0 && scope.row.taxamount!=null">{{ (parseInt(scope.row.taxdiscont)/100).toFixed(2) }}</span>
            
          </template>
        </el-table-column>
      </el-table> </span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="viewinvovisable = false">关 闭</el-button>
        <el-button
          @click="checkboxop(this.viewinvodata, 1)"
          type="primary"
          :disabled="!operar.check"
          v-if="viewinvodata.status == 0"
          >确 认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    title="批量下载"
    v-model="dlsvsiable"
    width="200px"
    :show-close="false"
    center
  >
  <el-checkbox v-model="checked1" label="PDF文件" size="large" /><br/>
  <el-checkbox v-model="checked2" label="OFD文件" size="large" />
  <template #footer>
      <span class="dialog-footer">
        <el-button @click="dlsvsiable = false">关 闭</el-button>
        <el-button
          @click="downloadinvop()"
          type="primary"
          >下 载</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="checkmark" width="350px" :show-close="false" center
    ><span style="line-height: 200%; text-indent: 10px"
      >{{ this.checktaxst }}<br /><br />{{
        this.ceckcode
      }}&nbsp;&nbsp;&nbsp;<el-input
        v-model="ceckcodeinput"
        style="width: 80px"
        autocomplete="off" /></span
    ><template #footer>
      <span class="dialog-footer">
        <el-button @click="checkmark = false">关 闭</el-button>
        <el-button @click="ceckmarkcech">确 认</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import MainDialog from "./MainDialog.vue";
//import ContactsCascader from "../../../../../../controls/ContactsCascader.vue";

import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import { postback } from "@/publiccomm";

export default {
  components: { MainDialog },
  emits: [],
  props: {
    searchdata: { required: true },
    selfmodul: { required: true },
    areaheight: { required: true },
  },
  data() {
    return {
      dlsvsiable:false,
      checkmark: false,
      checkrout: 0,
      checktaxst: "",
      ceckcode: "",
      ceckcodeinput: "",
      ceckrowdata: [],
      viewinvovisable: false,
      viewinvodata: [],
      dialogvis: false,
      cdvisiable: false,
      tableheight: 60,
      checked1:false,
      checked2:false,
      contactinfo: "",
      invselectrows:[],
      totalrow: 0,
      pagenow: 1,
      pagesize: 100,
      dataflites: [],
      dataflite: "",
      dialoginfo: { modlecode: this.selfmodul["code"] },
      dialogdata: {},
      tabledata: [],
      mysearch: {},
      operar: {
        conditionstr: "",
        add: false,
        edit: false,
        del: false,
        check: false,
        uncheck: false,
      },

      viewlist: {
        add: false,
        edit: false,
        del: false,
        check: false,
        uncheck: false,
      },
    };
  },
  mounted() {
    let operardata = JSON.parse(localStorage.getItem("authlist"));
    let controldata = operardata[this.selfmodul["code"]].operation;
    console.log(controldata);
    this.operar.add = (controldata & 128) == 128;
    this.operar.del = (controldata & 64) == 64;
    this.operar.edit = (controldata & 32) == 32;
    this.operar.check = (controldata & 16) == 16;
    this.operar.uncheck = (controldata & 8) == 8;
    this.operar.conditionstr = operardata[this.selfmodul["code"]].conditionstr;

    operardata = JSON.parse(localStorage.getItem("viewlist"));
    controldata = operardata[this.selfmodul["code"]].operation;
    this.viewlist.add = (controldata & 128) == 128;
    this.viewlist.del = (controldata & 64) == 64;
    this.viewlist.edit = (controldata & 32) == 32;
    this.viewlist.check = (controldata & 16) == 16;
    this.viewlist.uncheck = (controldata & 8) == 8;

    console.log(this.operar);
    this.flashdata();
    this.dialoginfo.operar = this.operar;
    this.dialoginfo.viewlist = this.viewlist;

    console.log("areaheigh", this.areaheight);
  },
  methods: {
    downloadinvopcallback(request){
      if (request != null) {
        request = JSON.parse(request);
        if(request.rescode==0){
          window.open("/api_v3/interface/getfile?file=" + request.data, "_self");
          this.dlsvsiable=false
        }else{
          ElMessage.error({
          message: request.resmsg,
          duration: 2000,
        });
        }
      }
      this.$bus.$emit("hideloading");
      this.$bus.$emit("hideloading");
    },
    downloadinvop(){
      if(!this.checked1 && !this.checked2){
        ElMessage.error({
          message: "请至少选择一种文件类型下载！！！",
          duration: 2000,
        });
        return;
      }
      var filelist=[];
      this.invselectrows.map((val)=>{
        if(val.file_pdf!='' && this.checked1){
          filelist.push('/upload/'+val.file_pdf)
        }
        if(val.file_ofd!=''  && this.checked2){
          filelist.push('/upload/'+val.file_ofd)
        }
      })
      if(filelist.length==0){
        ElMessage.error({
          message: "选择的文件类型没有需要下载的数据，请检查后重试！！！",
          duration: 2000,
        });
        return;
      }
      postback(
        "/interface/getfilemakezip",
        {filelist:filelist},
        this.downloadinvopcallback
      );



    },
    opendltg(){
      var num=0;
      this,this.invselectrows.map((val)=>{
        if(val.file_pdf!=''){
          num++;
        }
        if(val.file_ofd!=''){
          num++;
        }
      })
      if(num==0){
        ElMessage.error({
          message: "未选择明细或选择的明细无可下载文件，请检查后再试",
          duration: 2000,
        });
        return;
      }
      this.dlsvsiable=true;
    },
    invoceSelectionChange(val){
      this.invselectrows=val;
    },
    downloadinv(row,type){
      if(type==1){
        window.open("/api_v3/interface/getfile?file=/upload/" + row.file_pdf, "_self");
      }
      if(type==2){
        window.open("/api_v3/interface/getfile?file=/upload/" + row.file_ofd, "_self");
      }
    },
    getmounth(Value) {
      var data = new Date();
      if (Value != "") {
        data = new Date(parseInt(Value));
      }
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      console.log("getmounth", dateNumFun(data.getMonth() + 1));
      return dateNumFun(data.getMonth() + 1);
    },
    checkboxop(val, way) {
      this.ceckrowdata = val;
      this.checkrout = way;
      var ceckcode = "100" + Math.round(Math.random() * 10000000);
      this.ceckcode = ceckcode.substring(5, 9);
      this.ceckcodeinput = "";

      switch (way) {
        case 1:
          this.checktaxst =
            "发票确认后无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
          break;
        case 4:
          this.checktaxst =
            "发票作废无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
          break;
        case 7:
          this.checktaxst =
            "发票红冲无法撤销，数据将通过接口提交至税控系统处理。请检查无误后输入验证码进行确认！！";
          break;
        case 99:
          this.checktaxst =
            "待确认发票删除后无法恢复，发票生成时占用额度也不可恢复。如确需删除待确认发票，请输入验证码进行确认！！";
          break;
      }
      this.checkmark = true;
    },
    ceckmarkcech() {
      if (this.ceckcodeinput == this.ceckcode) {
        this.$bus.$emit("showloading");
        axios
          .post("/" + this.dialoginfo.modlecode + "/checkinvo", {
            data: { rowdata: this.ceckrowdata, way: this.checkrout },
          })
          .then(() => {
            //this.billssearchresults.rows = response.rows;
            this.flashdata();
            this.checkmark=false;
            this.viewinvovisable = false;
            
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      } else {
        var ceckcode = "100" + Math.round(Math.random() * 10000000);
        this.ceckcode = ceckcode.substring(5, 9);
        this.ceckcodeinput = "";
      }
    },
    checkinvo() {
      ElMessageBox.confirm(
        "确认后数据将提交至税控接口开具发票，本操作不可撤销！！！\r确认开具本张发票吗？",
        "警告"
      )
        .then(() => {
          this.$bus.$emit("showloading");
          axios
            .post("/" + this.dialoginfo.modlecode + "/checkinvo", {
              data: this.viewinvodata,
            })
            .then(() => {
              //this.billssearchresults.rows = response.rows;
              this.viewinvovisable = false;
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        })
        .catch(() => {});
    },
    invoviewtablesum(data) {
      const sums = [];
      data.columns.forEach((element, index) => {
        var value = "";
        if (element.label == "商品名称") {
          sums[index] = "合计";
          return;
        }
        if (element.label == "数量") {
          value = Number(0);
          data.data.forEach((row) => {
            value += Number(row.num);
          });
          value = (value / 1000000).toFixed(2);
        }
        if (element.label == "含税金额") {
          value = Number(0);
          data.data.forEach((row) => {
            if(row.taxamount!=0 && row.taxamount!=null){
              value += Number(row.taxamount);
            }else{
              value += Number(row.notaxamount) + Number(row.tax);
            }
            
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "含税折扣额") {
          value = Number(0);
          data.data.forEach((row) => {
            if(row.taxamount!=0 && row.taxamount!=null){
              value += Number(row.taxdiscont);
            }
            
          });
          value = (value / 100).toFixed(2);
        }
        if (element.label == "税额") {
          value = Number(0);
          data.data.forEach((row) => {
            if(row.taxamount!=0 && row.taxamount!=null){
              if(row.taxlv!=-1 &&row.taxlv!='-1'){
                value += Number(row.taxamount)*Number(row.taxlv)/1000000;
              }
            }else{
              value += Number(row.tax);
            }
            
          });
          value = (value / 100).toFixed(2);
        }

        sums[index] = value;
      });

      return sums;
    },
    finallnum(val) {
      if (val == "" || val == null || val == 0) {
        return "0.00";
      }
      var num = val;
      num = num.toFixed(2);
      var fh = "";
      if (num.substring(0, 1) == "-") {
        fh = "-";
        num = num.substring(1);
      }
      var cutnum = 0;
      while (num.length > 6) {
        if (num.length % 3 != 0) {
          cutnum = num.length % 3;
          fh += num.substring(0, cutnum);
          fh += ",";
          num = num.substring(cutnum);
        } else {
          fh += num.substring(0, 3);
          fh += ",";
          num = num.substring(3);
        }
      }
      fh += num;
      return fh;
    },
    viewinvos(val) {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getbilldtl", { data: val })
        .then((response) => {
          //this.billssearchresults.rows = response.rows;
          val.dtl = response;
          this.viewinvodata = val;
          this.viewinvovisable = true;
          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    dateformart2(Value) {
      //console.log("datetimeformart", Value);
      let time = Value;
      if (time == 0 || time == "") {
        return "";
      }
      const date = new Date(parseInt(time));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
      ];
      var rtdate = `${Y}-${M}-${D}`;
      return rtdate;
    },
    chcat() {
      this.$bus.$emit("showloading");

      if (this.dataflite == "") {
        ElMessage.error({
          message: "请先选择商户和往来信息！！！",
          duration: 2000,
        });
        this.$bus.$emit("hideloading");
      } else {
        this.dialogdata = this.dataflite;
        this.cdvisiable = false;
        this.dialogvis = true;
        this.$bus.$emit("hideloading");
      }
    },
    clearccdata() {
      this.contactinfo = "";
    },
    contactscg(val) {
      this.contactinfo = val[0].value;
    },
    statusformart(row, column, cellValue) {
      if (cellValue == 0) {
        return "待确认";
      }
      if (cellValue == 50) {
        return "已确认";
      }
      if (cellValue == 99) {
        return "作废";
      }
      return "数据错误";
    },
    dateformart(row, column, cellValue) {
      if (cellValue == 0) {
        return "";
      }
      const date = new Date(parseInt(cellValue));
      const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
      const [Y, M, D, h, m, s] = [
        date.getFullYear(),
        dateNumFun(date.getMonth() + 1),
        dateNumFun(date.getDate()),
        dateNumFun(date.getHours()),
        dateNumFun(date.getMinutes()),
        dateNumFun(date.getSeconds()),
      ];
      var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
      rtdate = `${Y}-${M}-${D}`;
      return rtdate;
    },

    handleSizeChange(val) {
      let pgs = Math.ceil(this.totalrow / val);
      this.pagesize = val;
      if (pgs >= this.pagenow) {
        this.flashdata();
      }
    },
    handleCurrentChange(val) {
      this.pagenow = val;
      this.flashdata();
    },
    editclose(data) {
      this.dialogvis = false;
      console.log(data);
    },
    flashdata() {
      this.$bus.$emit("showloading");
      let mysearchdata = JSON.parse(JSON.stringify(this.searchdata));
      mysearchdata.pagenow = this.pagenow;
      mysearchdata.pagesize = this.pagesize;

      axios
        .post("/" + this.selfmodul["code"] + "/getlist", { data: mysearchdata })
        .then((response) => {
          this.dataflites = response["datafield"];
          this.tabledata = response["rows"];
          this.totalrow = response["total"] * 1;

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    openview(data) {
      console.log("view", data);
      this.dialoginfo.name = "查看" + this.selfmodul["name"] + "";
      this.dialoginfo.modle = "view";
      this.dialogdata = data;
      this.dialogvis = true;
    },

    openedit(data) {
      console.log("edit", data);
      this.dialoginfo.name = "编辑" + this.selfmodul["name"] + "";
      this.dialoginfo.modle = "edit";
      this.dialogdata = data;
      this.dialogvis = true;
    },
    openadd() {
      this.cdvisiable = true;
      // this.dialogdata = { v_name: '', sid: 0, row_code: '' }
      // this.dialoginfo.name = '新建' + this.selfmodul['name'] + ''
      // this.dialoginfo.modle = 'add'
      // this.dialogvis = true
    },

    deldata(data) {
      console.log("del", data);
      axios
        .post("/" + this.selfmodul["code"] + "/delet", { data: data })
        .then(() => {
          this.flashdata();
          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },

    tableheadClass() {
      return "text-align:center";
    },
  },
  watch: {
    searchdata: function () {
      if (JSON.stringify(this.mysearch) != JSON.stringify(this.searchdata)) {
        //查询条件变动，重新拉取数据
        this.mysearch = JSON.parse(JSON.stringify(this.searchdata));
        this.flashdata();
      }
    },
    areaheight: function () {
      this.$nextTick(() => {
        this.tableheight = this.areaheight - 284;
      });
    },
  },
};
</script>
<style scoped>
:deep(.invoviewdata) {
  width: 340px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
}

:deep(.myadditem .el-form-item__label) {
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

:deep(.invoviewtool) {
  width: 180px;
  display: inline-block;
  padding: 0px;
  margin-bottom: 8px;
  text-align: right;
}
</style>