<template>
  <el-cascader
    :ref="localuuid"
    :class="localuuid"
    v-model="localdata"
    :show-all-levels="showalllevels"
    :style="'width:' + width + ';'"
    :collapse-tags="true"
    :filterable="true"
    :options="localdatas"
    :props="localprops"
    @change="thisdatachange"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
  ></el-cascader>
</template>
  <script >
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export default {
  emits: ["datachange"],
  props: {
    thisdata: { required: true },
    disabled: { default: false },
    clearable: { default: false },
    multiple: { default: false },
    showalllevels: { default: false },
    checkStrictly: { default: false },
    width: { default: "380px" },
    getstatus: { default: "" },
    debounce: { default: 500 },
    placeholder: { default: "选择所属部门" },
    isview: { default: true },
  },
  data() {
    return {
      localuuid: "",
      localdata: "",
      localalldatas: [],
      localdatas: [],
      localprops: {
        multiple: this.multiple,
        checkStrictly: this.checkStrictly,
        value: "row_code",
        label: "v_name",
      },
    };
  },
  methods: {
    thisdatachange() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.$emit(
          "datachange",
          this.$refs[this.localuuid].getCheckedNodes(!this.checkStrictly)
        );
      }, this.debounce);
    },
    getccdata(datalist) {
      this.$bus.$emit("showloading");
      axios
        .post("/getdicc/departmentlist", { data: datalist })
        .then((response) => {
          this.$bus.$emit("hideloading");
          this.localdatas = response;
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
  },
  watch: {
    thisdata: function () {
      console.log("contactscasca", this.thisdata);
      this.localdata = this.thisdata;
    },
    isview: function () {
      if (this.isview) {
        this.getccdata({
          type: "getlist",
          tpnum: this.typenumber,
          upcode: -1,
          statu: this.getstatus,
        });
      }
    },
  },
  mounted() {
    if (this.isview) {
      this.getccdata({
        type: "getlist",
        tpnum: this.typenumber,
        upcode: -1,
        statu: this.getstatus,
      });
    }
    this.localuuid = uuidv4();
  },
};
</script>
  <style scoped>
:deep(.el-cascader .el-input .el-input__inner:focus, .el-cascader
    .el-input.is-focus
    .el-input__inner) {
  height: 40px;
}

:deep(.el-cascader__tags) {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>